import { Item } from 'src/components/Form/Select/interfaces';

export const dashboardPeriodOptions: Item[] = [
  { key: 0, label: 'Últimos 30 dias', value: 'days-30' },
  { key: 1, label: 'Últimos 3 meses', value: 'months-3' },
  { key: 2, label: 'Últimos 6 meses', value: 'months-6' },
  { key: 3, label: 'Últimos 12 meses', value: 'months-12' },
  {
    key: 4,
    label: 'Período Customizado',
    value: 'custom',
  },
];
