import yup from 'src/libs/yup';

export const LeadStoreSchema = yup.object().shape({
  name: yup.string().label('Nome'),
  email: yup.string().email().required().label('E-mail'),
  phone: yup.string().required().label('Telefone'),
});

export const LeadUpdateSchema = yup.object().shape({
  name: yup.string().label('Nome'),
  email: yup.string().email().required().label('E-mail'),
  phone: yup.string().required().label('Telefone'),
});
