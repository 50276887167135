import { FC, useEffect, useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import { IBenefit } from 'src/interfaces/models';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import { objectToQuery } from 'src/utils/helpers';

const periodsItems: Item[] = [
  { key: 1, label: 'Anual', value: 'annual' },
  { key: 2, label: 'Semestral', value: 'semiannual' },
];

const statusItems: Item[] = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const Filters: FC = () => {
  const [benefitsItems, setBenefitsItems] = useState<Item[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const benefitsParams = objectToQuery({
          order_by: [{ column: 'name', direction: 'asc' }],
        });
        const benefitResponse = await api.get(
          `admin/benefits${benefitsParams}`,
        );
        const benefits = benefitResponse.data as IBenefit[];
        setBenefitsItems(
          benefits.map((benefit) => ({
            key: benefit.id,
            label: benefit.name,
            value: benefit.id,
          })),
        );
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar benefícios.');
      }
    };

    loadData();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Select
          name="benefit_id"
          label="Benefício"
          items={benefitsItems}
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField name="name" label="Nome" size="small" />
      </Grid>

      <Grid item xs={12}>
        <TextField name="description" label="Descrição" size="small" />
      </Grid>

      <Grid item xs={12}>
        <TextField name="slug" label="URL" size="small" />
      </Grid>

      <Grid item xs={6}>
        <Select
          name="period"
          label="Período"
          items={periodsItems}
          size="small"
        />
      </Grid>

      <Grid item xs={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
