import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import CheckBox from 'src/components/Form/Checkbox';
import InputFile from 'src/components/Form/InputFile';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IBeneficiary } from 'src/interfaces/models';
import INotice from 'src/interfaces/models/INotice';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import { objectToFormData, objectToQuery } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import {
  NoticeStoreSchema,
  NoticeUpdateSchema,
} from 'src/validators/Notice/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState<IBeneficiary[]>([]);
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id ? NoticeUpdateSchema : NoticeStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const queryParams = objectToQuery({
          has_subsite: true,
          with_subsite: true,
          order_by: [{ column: 'display_name', direction: 'asc' }],
        });
        const { data } = await api.get<IBeneficiary[]>(
          `admin/beneficiaries${queryParams}`,
        );
        setBeneficiaries(data);

        if (params.id) {
          const { data } = await api.get<INotice>(`admin/notices/${params.id}`);
          formRef.current?.setData({
            ...data,
            subsites_ids: data.subsites?.map((subsite) => subsite.id),
          });
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (unformData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        yupSchema,
        unformData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const formData = objectToFormData(data);

      if (params.id) {
        await api.put(`/admin/notices/${params.id}`, formData);
      } else {
        await api.post('/admin/notices', formData);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      await api.delete(`/admin/notices/${params.id}`);
      toast.success('Notícia excluida!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao excluir notícia.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Nova'} Notícia
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <InputFile
                      name="image"
                      label="Imagem"
                      required
                      previewAlt="Imagem"
                    />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          name="description"
                          label="Descrição"
                          multiline
                          rows={3}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="link"
                          label="Link"
                          required
                          placeholder="https://site.com.br"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <CheckBox name="show_ifbolsas" label="IFBOLSAS" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CheckBox name="show_ifepaf" label="IFEPAF" />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      name="subsites_ids"
                      label="Subsites"
                      multiple
                      options={beneficiaries.map((beneficiary) => ({
                        key: beneficiary.id,
                        label: `${beneficiary.display_name} (${beneficiary.subsite?.slug})`,
                        value: beneficiary.subsite?.id,
                      }))}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Button
                type="button"
                loading={loading}
                startIcon={<CloseIcon />}
                variant="contained"
                color="secondary"
                disabled={!params.id}
                onClick={() => confirmDialogRef.current?.show()}
              >
                Excluir
              </Button>

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title="Excluir Notícia"
        description="Confirma esta ação?"
        confirmColor="secondary"
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
