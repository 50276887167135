import { FC, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';

import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { GridColDef, GridRowData } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import EmailIcon from '@material-ui/icons/Email';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import CustomMenuItem from 'src/components/Table/CustomMenuItem';
import StatusIcon from 'src/components/Table/StatusIcon';
import { columnDateTime } from 'src/components/Table/types';
import usePagination from 'src/hooks/usePagination';
import { IPersonSubscription } from 'src/interfaces/models/IPerson';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import masks from 'src/utils/masks';
import PersonSubscriptionFilterSchema from 'src/validators/PersonSubscription/filter.schema';

import Filters from './Filters';
import { Container } from './styles';

const paymentFormNames: Record<any, string> = {
  bank_slip: 'Boleto',
  credit_card: 'Cartão de Crédito',
};

const paymentInNames: Record<any, string> = {
  cash: 'À Vista',
  installments: 'Parcelado',
};

const List: FC = () => {
  const filterRef = useRef<FilterDrawerRef>(null);
  const newMailDialogRef = useRef<ConfirmDialogRef>(null);
  const renewMailDialogRef = useRef<ConfirmDialogRef>(null);
  const approveDialogRef = useRef<ConfirmDialogRef>(null);
  const [
    currentPersonSubscription,
    setCurrentPersonSubscription,
  ] = useState<IPersonSubscription | null>(null);
  const [dialogLoading, setDialogLoading] = useState(false);
  const { url } = useRouteMatch();
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading,
    info,
    loadData,
  } = usePagination<IPersonSubscription>('admin/persons-subscriptions');

  const handleOpenNewMailDialog = (gridRow: GridRowData) => {
    setCurrentPersonSubscription(
      data.find((personSub) => personSub.id == gridRow.id) || null,
    );
    newMailDialogRef.current?.show();
  };

  const handleNewMailConfirm = async () => {
    try {
      setDialogLoading(true);

      await api.post(
        `/admin/persons-subscriptions/${currentPersonSubscription?.id}/send-new`,
      );
      toast.success(`E-mail enviado!`);
      newMailDialogRef.current?.hide();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao enviar e-mail.');
    } finally {
      setDialogLoading(false);
    }
  };

  const handleOpenRenewMailDialog = (gridRow: GridRowData) => {
    setCurrentPersonSubscription(
      data.find((personSub) => personSub.id == gridRow.id) || null,
    );
    renewMailDialogRef.current?.show();
  };

  const handleRenewMailConfirm = async () => {
    try {
      setDialogLoading(true);

      await api.post(
        `/admin/persons-subscriptions/${currentPersonSubscription?.id}/send-renew`,
      );
      toast.success(`E-mail enviado!`);
      renewMailDialogRef.current?.hide();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao enviar e-mail.');
    } finally {
      setDialogLoading(false);
    }
  };

  const handleApproveDialog = (gridRow: GridRowData) => {
    setCurrentPersonSubscription(
      data.find((personSub) => personSub.id == gridRow.id) || null,
    );
    approveDialogRef.current?.show();
  };

  const handleApproveConfirm = async () => {
    try {
      setDialogLoading(true);

      await api.post(
        `/admin/persons-subscriptions/${currentPersonSubscription?.id}/approve`,
      );
      toast.success(`Assinatura aprovada!`);
      approveDialogRef.current?.hide();
      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao aprovar assinatura.');
    } finally {
      setDialogLoading(false);
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'vindi_bill_id', headerName: 'ID Fatura', width: 120 },
    {
      field: 'person.name',
      headerName: 'Pessoa',
      flex: 1,
      valueGetter({ row }) {
        return row.person?.name;
      },
    },
    {
      field: 'subscription.name',
      headerName: 'Plano',
      valueGetter({ row }) {
        return row.subscription?.name;
      },
    },
    {
      field: 'payment_form',
      headerName: 'Pagamento',
      width: 150,
      renderCell({ row }) {
        return (
          <div>
            {row.isFree || row.payment_form === 'free' ? (
              <>
                <Typography variant="body2">Gratuito</Typography>
              </>
            ) : (
              <>
                <Typography variant="body2">
                  R$ {masks.currency(row.price)}
                  {' - '}
                  {row.payment_in && paymentInNames[row.payment_in]}
                </Typography>
                {row.payment_form && (
                  <Typography variant="body2">
                    {paymentFormNames[row.payment_form]}
                  </Typography>
                )}
              </>
            )}
          </div>
        );
      },
    },
    {
      field: 'created_at',
      headerName: 'Criado Em',
      ...columnDateTime,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      renderCell({ id, row }) {
        return (
          <TableActions
            resource="Assinatura"
            resourceStatus={row.status}
            deleteApiUrl={`/admin/persons-subscriptions/${id}`}
            descriptionPrefix={`A Assinatura de ${row.person?.name}`}
            onFinish={loadData}
            startItems={
              <>
                {row.status === 'pending' && (
                  <>
                    <CustomMenuItem
                      Icon={CheckIcon}
                      iconProps={{ color: 'primary' }}
                      text="Aprovar Assinatura"
                      onClick={() => handleApproveDialog(row)}
                    />
                    <CustomMenuItem
                      Icon={EmailIcon}
                      iconProps={{ color: 'primary' }}
                      text="Enviar e-mail de pagamento pendente"
                      onClick={() => handleOpenNewMailDialog(row)}
                    />
                  </>
                )}

                {row.status === 'active' && (
                  <CustomMenuItem
                    Icon={EmailIcon}
                    iconProps={{ color: 'primary' }}
                    text="Enviar Renovação"
                    onClick={() => handleOpenRenewMailDialog(row)}
                  />
                )}
              </>
            }
          />
        );
      },
    },
  ];

  return (
    <Container>
      <FilterDrawer
        ref={filterRef}
        columns={columns.filter((column) => !column.hide && column.field)}
        yupSchema={PersonSubscriptionFilterSchema}
      >
        <Filters formRef={filterRef.current?.getFormRef()} />
      </FilterDrawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">Assinaturas</Typography>

            <LinkButton
              to={`${url}/novo`}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Nova
            </LinkButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                page={info.current_page - 1}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={loading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onFilterClick={filterRef.current?.open}
                canSearch
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <ConfirmDialog
        ref={newMailDialogRef}
        title="Enviar email de aguardando pagamento?"
        description="Será enviado um email à pessoa informando que o pagamento está pendente, caso for boleto o link estará presente."
        confirmColor="primary"
        onConfirm={handleNewMailConfirm}
        loading={dialogLoading}
      />

      <ConfirmDialog
        ref={renewMailDialogRef}
        title="Enviar email de renovação?"
        description="Será enviado um email à pessoa para renovação da assinatura."
        confirmColor="primary"
        onConfirm={handleRenewMailConfirm}
        loading={dialogLoading}
      />

      <ConfirmDialog
        ref={approveDialogRef}
        title="Aprovar Assinatura?"
        description="Será enviado um email à pessoa informando a aprovação da assinatura e, caso exista, com o voucher solicitado em anexo."
        confirmColor="primary"
        onConfirm={handleApproveConfirm}
        loading={dialogLoading}
      />
    </Container>
  );
};

export default List;
