import { FC, useEffect, useRef, useState } from 'react';

import { Card, CardContent, Grid } from '@material-ui/core';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import { subDays, subMonths } from 'date-fns';
import Button from 'src/components/Button';
import DatePicker from 'src/components/Form/DatePicker';
import Select from 'src/components/Form/Select';
import PersonPartnersSolicitationsChart from 'src/pages/Private/Dashboard/PersonPartnersSolicitationsChart';
import api from 'src/services/api';
import { objectToQuery } from 'src/utils/helpers';

import BeneficiariesNotUsedChart from './BeneficiariesNotUsedChart';
import BeneficiariesSolicitationsChart from './BeneficiariesSolicitationsChart';
import BeneficiaryPersonsChart from './BeneficiaryPersonsChart';
import BenefitSolicitationsChart from './BenefitSolicitationsChart';
import CategorySolicitationsChart from './CategorySolicitationsChart';
import CollaboratorBenefitSolicitationsChart from './CollaboratorBenefitSolicitationsChart';
import CollaboratorCategorySolicitationsChart from './CollaboratorCategorySolicitationsChart';
import CollaboratorPartnersSolicitationsChart from './CollaboratorPartnersSolicitationsChart';
import CollaboratorServiceSolicitationsChart from './CollaboratorServiceSolicitationsChart';
import DashboardContext, { IDashboardContext } from './context';
import LastBeneficiaries from './LastBeneficiaries';
import LastPartners from './LastPartners';
import PartnersSolicitationsChart from './PartnersSolicitationsChart';
import PersonBenefitSolicitationsChart from './PersonBenefitSolicitationsChart';
import PersonCategorySolicitationsChart from './PersonCategorySolicitationsChart';
import PersonPlanSubscriptionsChart from './PersonPlanSubscriptionsChart';
import PersonServiceSolicitationsChart from './PersonServiceSolicitationsChart';
import PersonSubscriptionsChart from './PersonSubscriptionsChart';
import RegisteredPersonsChart from './RegisteredPersonsChart';
import ServiceSolicitationsChart from './ServiceSolicitationsChart';
// import PersonSubscriptionsTicketsChart from './PersonSubscriptionsTicketsChart';
import { Container } from './styles';
import { dashboardPeriodOptions } from './utils';

type DashboardFilters = {
  period: string;
  periodCustom?: {
    value: Date;
    value_end: Date;
  };
};

const Dashboard: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [chartsInfo, setChartsInfo] = useState<IDashboardContext['chartsInfo']>(
    {},
  );
  const [selectedPeriod, setSelectedPeriod] = useState('days-30');

  async function loadData(formData?: DashboardFilters) {
    let period = {};
    const [type, value] = selectedPeriod.split('-');
    if (type === 'days') {
      period = {
        value: subDays(new Date(), Number(value)).toISOString(),
        value_end: new Date().toISOString(),
      };
    } else if (type === 'months') {
      period = {
        value: subMonths(new Date(), Number(value)).toISOString(),
        value_end: new Date().toISOString(),
      };
    } else if (type === 'custom' && formData?.periodCustom) {
      period = {
        value: formData?.periodCustom?.value.toISOString(),
        value_end: formData.periodCustom.value_end?.toISOString(),
      };
    }

    const query = objectToQuery({ period });

    const { data } = await api.get(`admin/dashboard${query}`);
    setChartsInfo(data);
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardContext.Provider value={{ chartsInfo }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card style={{ height: '100%' }}>
              <CardContent>
                <Form
                  ref={formRef}
                  onSubmit={loadData}
                  initialData={{ period: selectedPeriod }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md>
                      <Select
                        name="period"
                        label="Período"
                        items={dashboardPeriodOptions}
                        onChange={setSelectedPeriod}
                      />
                    </Grid>

                    {selectedPeriod === 'custom' && (
                      <Scope path="periodCustom">
                        <Grid item xs={12} sm>
                          <DatePicker
                            name="value"
                            label="Início"
                            valueAsDate
                            disableToolbar={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm>
                          <DatePicker
                            name="value_end"
                            label="Fim"
                            valueAsDate
                            disableToolbar={false}
                          />
                        </Grid>
                      </Scope>
                    )}

                    <Grid
                      item
                      xs={12}
                      md
                      style={{
                        alignSelf: 'center',
                        textAlign: 'right',
                      }}
                    >
                      <Button variant="contained" color="primary" type="submit">
                        Atualizar Período
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </CardContent>
            </Card>
          </Grid>

          {/* ---------- IFEPAF + IFBOLSAS  ---------- */}
          <Grid item xs={12} lg={6}>
            <BenefitSolicitationsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <CategorySolicitationsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <ServiceSolicitationsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <PartnersSolicitationsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <RegisteredPersonsChart />
          </Grid>

          {/* ---------- PAINEL ADMIN ---------- */}
          <Grid item xs={12} lg={6}>
            <LastPartners />
          </Grid>

          {/* ---------- IFEPAF ---------- */}
          <Grid item xs={12} lg={6}>
            <CollaboratorBenefitSolicitationsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <CollaboratorCategorySolicitationsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <CollaboratorServiceSolicitationsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <CollaboratorPartnersSolicitationsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <BeneficiariesSolicitationsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <LastBeneficiaries />
          </Grid>

          <Grid item xs={12} lg={6}>
            <BeneficiariesNotUsedChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <BeneficiaryPersonsChart />
          </Grid>

          {/* ---------- IFBOLSAS ---------- */}
          <Grid item xs={12} lg={6}>
            <PersonPlanSubscriptionsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <PersonSubscriptionsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <PersonBenefitSolicitationsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <PersonCategorySolicitationsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <PersonServiceSolicitationsChart />
          </Grid>

          <Grid item xs={12} lg={6}>
            <PersonPartnersSolicitationsChart />
          </Grid>

          {/* <Grid item xs={12} lg={6}>
            <PersonSubscriptionsTicketsChart />
          </Grid> */}
        </Grid>
      </Container>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
