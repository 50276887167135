import yup from 'src/libs/yup';

export const TeachFormStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  slug: yup.string().required().label('URL'),
  description: yup.string().label('Descrição'),
});

export const TeachFormUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  slug: yup.string().required().label('URL'),
  description: yup.string().label('Descrição'),
});
