import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RestoreIcon from '@material-ui/icons/Restore';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import CheckBox from 'src/components/Form/Checkbox';
import InputFile from 'src/components/Form/InputFile';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IBenefit, ICategory } from 'src/interfaces/models';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import { objectToFormData, objectToQuery } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import {
  CategoryStoreSchema,
  CategoryUpdateSchema,
} from 'src/validators/Category/save.schema';

import { Container } from './styles';

const periodsItems: Item[] = [
  { key: 1, label: 'Anual', value: 'annual' },
  { key: 2, label: 'Semestral', value: 'semiannual' },
];

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [benefitsItems, setBenefitsItems] = useState<Item[]>([]);
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id ? CategoryUpdateSchema : CategoryStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const benefitsParams = objectToQuery({
          order_by: [{ column: 'name', direction: 'asc' }],
        });
        const benefitResponse = await api.get(
          `admin/benefits${benefitsParams}`,
        );
        const benefits = benefitResponse.data as IBenefit[];
        setBenefitsItems(
          benefits.map((benefit) => ({
            key: benefit.id,
            label: benefit.name,
            value: benefit.id,
          })),
        );

        if (params.id) {
          const userResponse = await api.get(`admin/categories/${params.id}`);
          const category = userResponse.data as ICategory;

          setStatus(category.status);

          formRef.current?.setData(category);
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (unformData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        yupSchema,
        unformData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const formData = objectToFormData(data);

      if (params.id) {
        await api.put(`/admin/categories/${params.id}`, formData);
      } else {
        await api.post('/admin/categories', formData);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (status === 'active') {
        await api.delete(`/admin/categories/${params.id}`);
        toast.success('Categoria desativado!');
      } else {
        await api.post(`/admin/categories/${params.id}/restore`);
        toast.success('Categoria restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar categoria.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Nova'} Categoria
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <InputFile
                      name="image"
                      label="Imagem"
                      required
                      previewAlt="Ilustração"
                    />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Select
                          name="benefit_id"
                          label="Benefício"
                          items={benefitsItems}
                          disabled={!!params.id}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="name" label="Nome" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid
                          container
                          style={{ height: '100%' }}
                          alignItems="center"
                        >
                          <CheckBox
                            name="fill_course"
                            label="Preencher curso com o nome"
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField name="slug" label="URL" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Select
                          name="period"
                          label="Período"
                          items={periodsItems}
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <CheckBox
                          name="show_home"
                          label="Mostrar na Home do site"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="order_position"
                          label="Ordem"
                          type="number"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Descrição"
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              {status === 'active' ? (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="secondary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${status === 'active' ? 'Desativar' : 'Restaurar'} benefício`}
        description="Confirma esta ação?"
        confirmColor={status === 'active' ? 'secondary' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
