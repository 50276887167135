import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PhoneIcon from '@material-ui/icons/Phone';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import RestoreIcon from '@material-ui/icons/Restore';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import CheckBox from 'src/components/Form/Checkbox';
import InputColor from 'src/components/Form/InputColor';
import InputFile from 'src/components/Form/InputFile';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IBeneficiarySubsite } from 'src/interfaces/models/IBeneficiary';
import { IBeneficiarySubsitePreviewResponse } from 'src/interfaces/responses/IBeneficiary';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import { objectToFormData } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import { BeneficiarySubsiteUpdateSchema } from 'src/validators/BeneficiarySubsite/save.schema';

const HeadquarterTab: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const deleteDialogRef = useRef<ConfirmDialogRef>(null);
  const restoreDialogRef = useRef<ConfirmDialogRef>(null);
  const [subsite, setSubsite] = useState<IBeneficiarySubsite>();
  const [
    showBeneficiaryContacts,
    setShowBeneficiaryContacts,
  ] = useState<boolean>();
  const history = useHistory();
  const params = useParams<IEditParams>();

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      const { data } = await api.get(
        `admin/beneficiaries/${params.id}/subsite`,
      );
      setSubsite(data);
      setShowBeneficiaryContacts(data.show_beneficiary_contacts);
      formRef.current?.setData(data);
    } catch (error) {
      handleApiResponseErrors(error.response, 'Dados não encontrados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleOnSubmit: SubmitHandler = async (unformData) => {
    try {
      startLayoutLoading();
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        BeneficiarySubsiteUpdateSchema,
        unformData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const formData = objectToFormData(data);

      const response = await api.put<IBeneficiarySubsite>(
        `/admin/beneficiaries/${params.id}/subsite`,
        formData,
      );

      setSubsite(response.data);

      toast.success('Dados salvos com sucesso!');
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro',
        BeneficiarySubsiteUpdateSchema,
        formRef,
      );
    } finally {
      stopLayoutLoading();
    }
  };

  const handleConfirmDelete = async () => {
    try {
      startLayoutLoading();

      const isDelete = deleteDialogRef.current?.isDelete();
      let deleteConfig = {};
      if (isDelete) {
        deleteConfig = { data: { delete: true } };
      }

      await api.delete(
        `/admin/beneficiaries/${params.id}/subsite`,
        deleteConfig,
      );

      toast.success(`Subsite ${isDelete ? 'excluido' : 'desativado'}!`);

      if (isDelete) return history.goBack();

      await loadData();

      deleteDialogRef.current?.hide();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao inativar subsite.');
    } finally {
      stopLayoutLoading();
    }
  };

  const handleConfirmRestore = async () => {
    try {
      startLayoutLoading();

      await api.post(`/admin/beneficiaries/${params.id}/subsite/restore`);
      toast.success('Subsite ativado com sucesso!');

      await loadData();

      restoreDialogRef.current?.hide();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao ativar subsite.');
    } finally {
      stopLayoutLoading();
    }
  };

  const handlePreview = async () => {
    try {
      startLayoutLoading();

      const { data } = await api.get<IBeneficiarySubsitePreviewResponse>(
        `/admin/beneficiaries/${params.id}/subsite/preview`,
      );

      window.open(data.preview_url, '_blank')?.focus();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao abrir preview do subsite.',
      );
    } finally {
      stopLayoutLoading();
    }
  };

  const changeVisibilityOfContactsForm = () => {
    if (showBeneficiaryContacts === false) {
      setShowBeneficiaryContacts(true);
    } else {
      setShowBeneficiaryContacts(false);
    }
  };

  const selectIcons: Item[] = [
    { key: 1, label: <WhatsappIcon />, value: 'whatsapp-icon' },
    { key: 1, label: <PhoneIcon />, value: 'phone-icon' },
  ];

  return (
    <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                  <InputFile
                    name="logo"
                    accept="image/*"
                    label="Logo"
                    previewAlt="Logo"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <InputColor name="color1" label="Cor Primária" required />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputColor
                        name="color2"
                        label="Cor Secundária"
                        required
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <InputColor
                        name="contrast_color1"
                        label="Cor de Contraste Primária"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputColor
                        name="contrast_color2"
                        label="Cor de Contraste Secundária"
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField name="slug" label="URL" required />
                    </Grid>

                    <Grid item xs={12}>
                      <CheckBox
                        name="show_website_link"
                        label="Exibir o link do Site da Beneficiária"
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        name="website_link"
                        label="URL Site da Beneficiária"
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <InputFile
                    name="cover"
                    accept="image/*"
                    label="Capa"
                    previewAlt="Capa"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField name="title" label="Título" required />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="subtitle" label="Subtítulo" required />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader subheader="Como Funciona" />

            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CheckBox
                    name="howitworks1_enabled"
                    label="1º Como Funciona Ativo"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="howitworks1_title" label="1º Título" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="howitworks1_description"
                    label="1ª Descrição"
                  />
                </Grid>

                <Grid item xs={12}>
                  <CheckBox
                    name="howitworks2_enabled"
                    label="2º Como Funciona Ativo"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="howitworks2_title" label="2º Título" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="howitworks2_description"
                    label="2ª Descrição"
                  />
                </Grid>

                <Grid item xs={12}>
                  <CheckBox
                    name="howitworks3_enabled"
                    label="3º Como Funciona Ativo"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="howitworks3_title" label="3º Título" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="howitworks3_description"
                    label="3ª Descrição"
                  />
                </Grid>

                <Grid item xs={12}>
                  <CheckBox
                    name="howitworks4_enabled"
                    label="4º Como Funciona Ativo"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="howitworks4_title" label="4º Título" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="howitworks4_description"
                    label="4ª Descrição"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader subheader="Vantagens" />

            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CheckBox
                    name="advantage1_enabled"
                    label="1ª Vantagem Ativa"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="advantage1_title" label="1º Título" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="advantage1_description"
                    label="1ª Descrição"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="advantage1_button_text"
                    label="1ª Texto Botão"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="advantage1_button_link"
                    label="1ª Link Botão"
                  />
                </Grid>

                <Grid item xs={12}>
                  <CheckBox
                    name="advantage2_enabled"
                    label="2ª Vantagem Ativa"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="advantage2_title" label="2º Título" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="advantage2_description"
                    label="2ª Descrição"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="advantage2_button_text"
                    label="2ª Texto Botão"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="advantage2_button_link"
                    label="2ª Link Botão"
                  />
                </Grid>

                <Grid item xs={12}>
                  <CheckBox
                    name="advantage3_enabled"
                    label="3ª Vantagem Ativa"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="advantage3_title" label="3º Título" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="advantage3_description"
                    label="3ª Descrição"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="advantage3_button_text"
                    label="3ª Texto Botão"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="advantage3_button_link"
                    label="3ª Link Botão"
                  />
                </Grid>

                <Grid item xs={12}>
                  <CheckBox
                    name="advantage4_enabled"
                    label="4ª Vantagem Ativa"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="advantage4_title" label="4º Título" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="advantage4_description"
                    label="4ª Descrição"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="advantage4_button_text"
                    label="4ª Texto Botão"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="advantage4_button_link"
                    label="4ª Link Botão"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <InputFile
                    name="discount_image"
                    accept="image/*"
                    label="Imagem Descontos"
                    previewAlt="Imagem Descontos"
                  />
                </Grid>

                <Grid item xs={12} sm={8}>
                  <TextField
                    name="discount_description"
                    label="Descrição"
                    multiline
                    minRows={5}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader subheader="Contatos do rodapé" />

            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <CheckBox
                    name="show_beneficiary_contacts"
                    label="Exibir contatos da beneficiária"
                    onChange={changeVisibilityOfContactsForm}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <CheckBox
                    name="hide_ifepaf_contacts"
                    label="Esconder contatos do Ifepaf"
                    required
                  />
                </Grid>
              </Grid>

              {showBeneficiaryContacts && (
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <TextField
                      name="contacts_phone1"
                      label="1º Telefone/Celular"
                      mask="phoneOrCellphone"
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <Select
                      name="contacts_icon_phone1"
                      label="Ícone"
                      items={selectIcons}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField name="contacts_email1" label="1º E-mail" />
                  </Grid>

                  <Grid item xs={5}>
                    <TextField
                      name="contacts_phone2"
                      label="2º Telefone/Celular"
                      mask="phoneOrCellphone"
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <Select
                      name="contacts_icon_phone2"
                      label="Ícone"
                      items={selectIcons}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField name="contacts_email2" label="2º E-mail" />
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="space-between">
            <div>
              {subsite?.status === 'active' && (
                <Button
                  type="button"
                  loading={layoutLoading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="secondary"
                  disabled={!params.id}
                  onClick={() => deleteDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              )}
              {subsite?.status === 'inactive' && (
                <>
                  <Button
                    type="button"
                    loading={layoutLoading}
                    startIcon={<RestoreIcon />}
                    color="primary"
                    disabled={!params.id}
                    onClick={() => restoreDialogRef.current?.show()}
                  >
                    Restaurar
                  </Button>
                  <Button
                    type="button"
                    loading={layoutLoading}
                    startIcon={<RemoveRedEyeIcon />}
                    color="default"
                    disabled={!params.id}
                    onClick={handlePreview}
                  >
                    Preview
                  </Button>
                </>
              )}
            </div>

            <Button
              type="submit"
              loading={layoutLoading}
              startIcon={<CheckIcon />}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        ref={deleteDialogRef}
        title="Desativar subsite"
        description="Confirma esta ação?"
        confirmColor="secondary"
        onConfirm={handleConfirmDelete}
        canDelete
      />

      <ConfirmDialog
        ref={restoreDialogRef}
        title="Restaurar subsite"
        description="Confirma esta ação?"
        confirmColor="primary"
        onConfirm={handleConfirmRestore}
      />
    </Form>
  );
};

export default HeadquarterTab;
