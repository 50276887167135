import { FC, useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Scope } from '@unform/core';
import Select from 'src/components/Form/Select';
import TextField from 'src/components/Form/TextField';
import { statesOptions } from 'src/utils/constants';
import { operators } from 'src/utils/helpers';

const statusItems = [
  { key: 1, label: 'Pendente', value: 'pending' },
  { key: 2, label: 'Ativo', value: 'active' },
  { key: 3, label: 'Inativo', value: 'inactive' },
];

const yesNoItems = [
  { key: 1, label: 'Sim', value: true },
  { key: 2, label: 'Não', value: false },
];

const Filters: FC = () => {
  const [qtyEmployees, setQtyEmployeesBetween] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField name="display_name" label="Nome" size="small" />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="document"
          label="CNPJ"
          size="small"
          mask="cnpj"
          returnUnmasked
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField name="city" label="Cidade" size="small" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select name="state" label="UF" items={statesOptions} size="small" />
      </Grid>

      <Grid item xs={6}>
        <Select
          name="has_unities"
          label="Possui Unidade"
          items={yesNoItems}
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          name="has_subsite"
          label="Possui Subsite"
          items={yesNoItems}
          size="small"
        />
      </Grid>

      <Scope path="qty_employees">
        <Grid item xs={12}>
          <Typography variant="body1">Qtde Beneficiários</Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            name="operator"
            label="Operador"
            items={operators}
            onChange={(value: any) =>
              setQtyEmployeesBetween(value === 'between')
            }
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={qtyEmployees ? 4 : 8}>
          <TextField
            label={qtyEmployees ? 'De' : 'Valor'}
            name="value"
            type="number"
            size="small"
          />
        </Grid>

        {qtyEmployees && (
          <Grid item xs={4}>
            <TextField
              label="Até"
              name="value_end"
              type="number"
              size="small"
            />
          </Grid>
        )}
      </Scope>

      <Grid item xs={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
