import yup from 'src/libs/yup';

export const SubscriptionStoreSchema = yup.object().shape({
  vindi_product_id: yup.number().required().label('ID do Produto Vindi'),
  name: yup.string().required().label('Nome'),
  slug: yup.string().required().label('URL'),
  subtitle: yup.string().label('Subtítulo'),
  message: yup.string().label('Mensagem'),
  description: yup.string().label('Descrição'),
  price: yup.number().nullable().label('Preço à Vista'),
  qty_scholarships: yup.number().nullable().label('Qtde de Boslas'),
  qty_months: yup.number().nullable().label('Qtde de Meses'),
  allow_bonus: yup.boolean().label('Permitir bonus'),
  is_free: yup.boolean().label('Gratuito'),
  limit_one_scholarship: yup.boolean().label('Limite'),
  teach_forms: yup.array().of(yup.number()).label('Formas de Ensino'),
  services: yup.array().of(yup.number()).label('Serviços'),
  installments: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        qty: yup.string().required().label('Nº'),
        price: yup.string().required().label('Preço'),
      }),
    )
    .label('Parcelas'),
  order_position: yup.string().required().label('Ordem'),
  person_visibility_since: yup
    .date()
    .nullable()
    .label('Pessoas cadastradas desde'),
  person_visibility_until: yup
    .date()
    .nullable()
    .label('Pessoas cadastradas até'),
});

export const SubscriptionUpdateSchema = yup.object().shape({
  vindi_product_id: yup.number().required().label('ID do Produto Vindi'),
  name: yup.string().required().label('Nome'),
  slug: yup.string().required().label('URL'),
  subtitle: yup.string().label('Subtítulo'),
  message: yup.string().label('Mensagem'),
  description: yup.string().label('Descrição'),
  price: yup.number().nullable().label('Preço à Vista'),
  qty_scholarships: yup.number().nullable().label('Qtde de Boslas'),
  qty_months: yup.number().nullable().label('Qtde de Meses'),
  allow_bonus: yup.boolean().label('Permitir bonus'),
  is_free: yup.boolean().label('Gratuito'),
  limit_one_scholarship: yup.boolean().label('Limite'),
  teach_forms: yup.array().of(yup.number()).label('Formas de Ensino'),
  services: yup.array().of(yup.number()).label('Serviços'),
  installments: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        qty: yup.string().required().label('Nº'),
        price: yup.string().required().label('Preço'),
      }),
    )
    .label('Parcelas'),
  order_position: yup.string().required().label('Ordem'),
  person_visibility_since: yup
    .date()
    .nullable()
    .label('Pessoas cadastradas desde'),
  person_visibility_until: yup
    .date()
    .nullable()
    .label('Pessoas cadastradas até'),
});
