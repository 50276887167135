import React, { useContext } from 'react';
import Chart from 'react-google-charts';

import { Card, CardHeader } from '@material-ui/core';
import DashboardContext from 'src/pages/Private/Dashboard/context';
import { defaultChartOptions } from 'src/utils/charts';

const PersonPartnersSolicitationsChart: React.FC = () => {
  const {
    chartsInfo: { partnersSolicitationsIfbolsas },
  } = useContext(DashboardContext);

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        style={{ color: '#2D7EFB' }}
        title="IFBOLSAS"
        subheader="4 PARCEIROS MAIS UTILIZADOS"
      />

      <Chart
        chartType="ColumnChart"
        data={partnersSolicitationsIfbolsas}
        options={defaultChartOptions}
      />
    </Card>
  );
};

export default PersonPartnersSolicitationsChart;
