import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RestoreIcon from '@material-ui/icons/Restore';
import { FormHandles, Scope, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import { IOption } from 'src/components/Form/Autocomplete/interfaces';
import CheckBox from 'src/components/Form/Checkbox';
import InputFile from 'src/components/Form/InputFile';
import RichText from 'src/components/Form/RichText';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import IBeneficiary from 'src/interfaces/models/IBeneficiary';
import IPartner from 'src/interfaces/models/IPartner';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { statesOptions } from 'src/utils/constants';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import { objectToFormData, objectToQuery } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import {
  PartnerStoreSchema,
  PartnerUpdateSchema,
} from 'src/validators/Partner/save.schema';

const solicitationItems: Item[] = [
  { key: 1, label: 'Voucher', value: 'voucher' },
  { key: 2, label: 'Notificar Parceiro', value: 'budget' },
  {
    key: 2,
    label: 'Voucher / Notificar Parceiro',
    value: 'voucher_and_budget',
  },
];

const showBeneficiairesItems: Item[] = [
  { key: 1, label: 'Todas (mostrar no IFEPAF)', value: -1 },
  { key: 2, label: 'Nenhuma', value: 0 },
  { key: 3, label: 'Selecionadas (mostrar em subsites)', value: 1 },
];

const DataTab: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const deleteDialogRef = useRef<ConfirmDialogRef>(null);
  const restoreDialogRef = useRef<ConfirmDialogRef>(null);
  const approveDialogRef = useRef<ConfirmDialogRef>(null);
  const [status, setStatus] = useState('');
  const [beneficiaryItems, setBeneficiaryItems] = useState<IOption[]>([]);
  const [selectBeneficiaries, setSelectBeneficiaries] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id ? PartnerUpdateSchema : PartnerStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const query = objectToQuery({
          order_by: [{ column: 'display_name', direction: 'asc' }],
        });
        const beneficiariesResponse = await api.get(
          `/admin/beneficiaries${query}`,
        );
        const beneficiaries = beneficiariesResponse.data as IBeneficiary[];
        setBeneficiaryItems(
          beneficiaries.map((beneficiary) => ({
            key: beneficiary.id,
            label: `${beneficiary.id} - ${beneficiary.display_name}`,
            value: beneficiary.id,
          })),
        );

        if (params.id) {
          const response = await api.get(`admin/partners/${params.id}`);
          const { beneficiaries, ...partner } = response.data as IPartner;

          setStatus(partner.status);

          formRef.current?.setData(partner);
          setSelectedIds(
            beneficiaries?.map((beneficiary) => beneficiary.id) || [],
          );
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    if (selectBeneficiaries) {
      formRef.current?.setFieldValue('beneficiaries', selectedIds);
    }
  }, [selectBeneficiaries, selectedIds]);

  const handleOnSubmit: SubmitHandler = async (unformData) => {
    try {
      startLayoutLoading();
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        yupSchema,
        unformData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const formData = objectToFormData(data);

      if (params.id) {
        await api.put(`/admin/partners/${params.id}`, formData);
      } else {
        const createResponse = await api.post('/admin/partners', formData);
        history.replace(`/parceiras/${createResponse.data.id}`);
      }

      toast.success('Dados salvos com sucesso!');
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      stopLayoutLoading();
    }
  };

  const handleConfirmDelete = async () => {
    try {
      startLayoutLoading();

      const isDelete = deleteDialogRef.current?.isDelete();
      let deleteConfig = {};
      if (isDelete) {
        deleteConfig = { data: { delete: true } };
      }

      await api.delete(`/admin/partners/${params.id}`, deleteConfig);

      toast.success(`Parceira ${isDelete ? 'excluida' : 'desativada'}!`);
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao inativar parceira.');
    } finally {
      stopLayoutLoading();
    }
  };

  const handleConfirmApproveOrRestore = async () => {
    try {
      startLayoutLoading();

      await api.post(`/admin/partners/${params.id}/restore`);
      toast.success('Parceira ativada!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao ativar parceira.');
    } finally {
      stopLayoutLoading();
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                  <InputFile
                    name="logo"
                    accept="image/*"
                    label="Logo"
                    previewAlt="Logo"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        name="display_name"
                        label="Nome de Exibição"
                        required
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField name="slug" label="URL" required />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField name="group" label="Grupo" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Select
                        name="solicitation"
                        label="Solicitação do Usuário"
                        items={solicitationItems}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="solicitation_email"
                        label="E-mail para notificação ao parceiro"
                        type="email"
                      />
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                      <TextField name="cupon" label="Cupom de Desconto" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField name="link" label="Link de Desconto" />
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader subheader="Matriz" />

            <CardContent>
              <Grid container spacing={1}>
                <Scope path="headquarter">
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="document"
                      label="CPF / CNPJ"
                      mask="cpfOrCnpj"
                      returnUnmasked
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      name="company_name"
                      label="Nome / Razão Social"
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField name="fancy_name" label="Nome Fantasia" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name="phone"
                      label="Telefone"
                      type="tel"
                      mask="phoneOrCellphone"
                      returnUnmasked
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    <TextField name="website" label="Site" />
                  </Grid>

                  <Grid item xs={12} sm={3} md={2}>
                    <TextField
                      name="zip_code"
                      label="CEP"
                      mask="zip_code"
                      returnUnmasked
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={2}>
                    <Select name="state" label="UF" items={statesOptions} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    <TextField name="city" label="Cidade" />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField name="neighborhood" label="Bairro" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="street" label="Rua / Av." />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField name="number" label="Número" />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <TextField name="complement" label="Complemento" />
                  </Grid>
                </Scope>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader subheader="Descrição" />

            <CardContent>
              <Grid item xs={12}>
                <RichText name="description" />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card color="primary">
            <CardHeader subheader="Como obter o desconto?" />

            <CardContent>
              <Grid item xs={12}>
                <RichText name="attention_text" />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader subheader="Visibilidade" />

            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CheckBox
                    name="allow_subscribers"
                    label="Permitir que assinantes vejam essa parceira (mostrar no site IFBOLSAS)"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Select
                    name="show_in_beneficiaries"
                    label="Mostrar nas Beneficiárias (IFEPAF)"
                    items={showBeneficiairesItems}
                    onChange={(value) => setSelectBeneficiaries(value === 1)}
                    required
                  />
                </Grid>

                {selectBeneficiaries && (
                  <Grid item xs={12}>
                    <Autocomplete
                      name="beneficiaries"
                      label="Beneficiárias Selecionadas"
                      options={beneficiaryItems}
                      multiple
                      textFieldProps={{ required: true }}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="space-between">
            <div>
              {status !== 'inactive' && (
                <Button
                  type="button"
                  loading={layoutLoading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="secondary"
                  disabled={!params.id}
                  onClick={() => deleteDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              )}
              {status === 'inactive' && (
                <Button
                  type="button"
                  loading={layoutLoading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.id}
                  onClick={() => restoreDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}
              {status === 'pending' && (
                <Button
                  type="button"
                  loading={layoutLoading}
                  startIcon={<CheckIcon />}
                  color="primary"
                  disabled={!params.id}
                  onClick={() => approveDialogRef.current?.show()}
                >
                  Aprovar
                </Button>
              )}
            </div>

            <Button
              type="submit"
              loading={layoutLoading}
              startIcon={<CheckIcon />}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        ref={deleteDialogRef}
        title="Desativar parceira"
        description="Confirma esta ação?"
        confirmColor="secondary"
        onConfirm={handleConfirmDelete}
        canDelete
      />

      <ConfirmDialog
        ref={restoreDialogRef}
        title="Restaurar parceira"
        description="Confirma esta ação?"
        confirmColor="primary"
        onConfirm={handleConfirmApproveOrRestore}
      />

      <ConfirmDialog
        ref={approveDialogRef}
        title="Aprovar parceira"
        description="Confirma esta ação?"
        confirmColor="primary"
        onConfirm={handleConfirmApproveOrRestore}
      />
    </Form>
  );
};

export default DataTab;
