import yup from 'src/libs/yup';

const CategoryFilterSchema = yup.object().shape({
  benefit_id: yup.string().nullable().label('Benefício'),
  name: yup.string().label('Nome'),
  description: yup.string().label('Descrição'),
  slug: yup.string().label('URL'),
  period: yup.string().nullable().label('Período'),
  status: yup.string().nullable().label('Status'),
});

export default CategoryFilterSchema;
