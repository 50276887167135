import React, { useContext } from 'react';
import Chart from 'react-google-charts';

import { Card, CardHeader } from '@material-ui/core';
import DashboardContext from 'src/pages/Private/Dashboard/context';
import { defaultChartOptions } from 'src/utils/charts';

const CollaboratorCategorySolicitationsChart: React.FC = () => {
  const {
    chartsInfo: { categoriesSolicitationsIfepaf },
  } = useContext(DashboardContext);

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        style={{ color: '#01CB98' }}
        title="IFEPAF"
        subheader="4 CATEGORIAS MAIS UTILIZADAS"
      />

      <Chart
        chartType="ColumnChart"
        data={categoriesSolicitationsIfepaf}
        options={defaultChartOptions}
      />
    </Card>
  );
};

export default CollaboratorCategorySolicitationsChart;
