const columnsNames: Record<string, string> = {
  id: 'ID',
  display_name: 'Nome Exibição',
  slug: 'URL',
  logo: 'Logo',
  group: 'Grupo',
  description: 'Descrição',
  attention_text: 'Texto Atenção',
  solicitation: 'Solicitação',
  solicitation_email: 'E-mail Notificação',
  allow_subscribers: 'Visível IFBOLSAS',
  show_in_beneficiaries: 'Visível IFEPAF',
  cupon: 'Cupom',
  status: 'Status',
  created_by: 'Criado Por',
  updated_by: 'Atualizado Por',
  created_at: 'Criado Em',
  updated_at: 'Atualizado Em',
  link: 'Link Desconto',
  headquarter_id: 'Matriz ID',
  headquarter_partner_id: 'Matriz Parceira ID',
  headquarter_document: 'Matriz Documento',
  headquarter_company_name: 'Matriz Razão',
  headquarter_fancy_name: 'Matriz Fantasia',
  headquarter_phone: 'Matriz Telefone',
  headquarter_website: 'Matriz Site',
  headquarter_zip_code: 'Matriz CEP',
  headquarter_state: 'Matriz UF',
  headquarter_city: 'Matriz Cidade',
  headquarter_neighborhood: 'Matriz Bairro',
  headquarter_street: 'Matriz Rua',
  headquarter_number: 'Matriz Número',
  headquarter_complement: 'Matriz Complemento',
  headquarter_created_by: 'Matriz Criado Por',
  headquarter_updated_by: 'Matriz Alterado Por',
  headquarter_created_at: 'Matriz Criado Em',
  headquarter_updated_at: 'Matriz Alterado Em',
  headquarter_short_address: 'Matriz Endereço Curto',
  headquarter_full_address: 'Matriz Endereço Completo',
  partner_id: 'Parceira ID',
  partner_name: 'Parceira Nome',
  zip_code: 'CEP',
  state: 'UF',
  city: 'Cidade',
  neighborhood: 'Bairro',
  street: 'Rua',
  number: 'Número',
  complement: 'Complemento',
  service_id: 'Serviço ID',
  service_name: 'Serviço Nome',
  teach_form_id: 'Forma Ensino ID',
  teach_form_name: 'Forma Ensino Nome',
  category_id: 'Categoria ID',
  category_name: 'Categoria Nome',
  benefit_id: 'Benefício ID',
  benefit_name: 'Benefício Nome',
  discount: 'Desconto',
  file: 'Arquivo',
  message: 'Mensagem',
  name: 'Nome',
  document: 'Documento',
  email: 'Email',
  phone: 'Telefone',
  department: 'Departamento',
  legal: 'Legal',
  partnership: 'Parceria',
  contacts: 'Contatos',
  signed_at: 'Assinado Em',
  expire_at: 'Expira Em',
  notify_at: 'Notificar Em',
  vindi_customer_id: 'Vindi Cliente ID',
  birth_date: 'Data Nascimento',
  remember_me_token: 'Token auto-login',
  origin: 'Origem',
  beneficiary_id: 'Beneficiária ID',
  beneficiary_name: 'Benficiária Nome',
  beneficiary_unity_id: 'Unidade ID',
  beneficiary_unity_name: 'Unidade Nome',
  person_id: 'Pessoa ID',
  person_name: 'Pessoa Nome',
  person_document: 'Pessoa Documento',
  subscription_id: 'Plano ID',
  subscription_name: 'Plano Nome',
  subscriber_name: 'Nome Assinante',
  subscriber_document: 'Documento Assinante',
  price: 'Preço',
  allow_bonus: 'Permite Bônus',
  qty_scholarships: 'Qtde Bolsas',
  payment_form: 'Metodo Pagamento',
  payment_in: 'Forma Pagamento',
  vindi_bill_id: 'Vindi Fatura ID',
  expires_at: 'Expira Em',
  qty_installments: 'Qtde Parcelas',
  is_free: 'Gratuito',
  limit_one_scholarship: 'Limita 1 Bolsa',
  school_year: 'Ano Letivo',
  person_subscription_id: 'Assinatura ID',
  voucher_1_id: '1º Voucher ID',
  voucher_1_person_scholarship_id: '1º Voucher Assinatura ID',
  voucher_1_course: '1º Voucher Curso',
  voucher_1_campus: '1º Voucher Campus',
  voucher_1_year: '1º Voucher Ano',
  voucher_1_semester: '1º Voucher Semestre',
  voucher_1_status: '1º Voucher Status',
  voucher_1_created_by: '1º Voucher Criado Por',
  voucher_1_updated_by: '1º Voucher Alterado Por',
  voucher_1_created_at: '1º Voucher Criado Em',
  voucher_1_updated_at: '1º Voucher Alterado Em',
  voucher_1_voucher_url: '1º Voucher Link',
  voucher_1_verify_url: '1º Voucher Link Verificação',
  voucher_2_id: '2º Voucher ID',
  voucher_2_person_scholarship_id: '2º Voucher Assinatura ID',
  voucher_2_course: '2º Voucher Curso',
  voucher_2_campus: '2º Voucher Campus',
  voucher_2_year: '2º Voucher Ano',
  voucher_2_semester: '2º Voucher Semestre',
  voucher_2_status: '2º Voucher Status',
  voucher_2_created_by: '2º Voucher Criado Por',
  voucher_2_updated_by: '2º Voucher Alterado Por',
  voucher_2_created_at: '2º Voucher Criado Em',
  voucher_2_updated_at: '2º Voucher Alterado Em',
  voucher_2_voucher_url: '2º Voucher Link',
  voucher_2_verify_url: '2º Voucher Link Verificação',
  national_identity: 'RG',
  course: 'Curso',
  campus: 'Campus',
  year: 'Ano',
  semester: 'Semestre',
  classification: 'Classificação',
  voucher_url: 'Link Voucher',
  verify_url: 'Link Verificação',
  short_name: 'Nome Curto',
  qty_employees: 'Qtde Beneficiários',
  confirm_scholarship: 'Confirmar Bolsas',
  confirm_bonus: 'Confirmar Bônus',
  headquarter_beneficiary_id: 'Matriz Benficiária ID',
  classifications: 'Classificações',
  finance: 'Financeiro',
  agreement: 'Convênio',
  disclosure: 'Divulgação',
  confirmation: 'Confirmação Bolsa/Bônus',
  unities: 'Unidades',
  color1: 'Cor Primária',
  color2: 'Cor Secundária',
  cover: 'Capa',
  title: 'Título',
  subtitle: 'Subtítulo',
  discount_image: 'Desconto Imagem',
  discount_description: 'Desconto Descrição',
  howitworks1_enabled: '1º Como Funciona Habilitado',
  howitworks1_title: '1º Como Funciona Título',
  howitworks1_description: '1º Como Funciona Descrição',
  howitworks2_enabled: '2º Como Funciona Habilitado',
  howitworks2_title: '2º Como Funciona Título',
  howitworks2_description: '2º Como Funciona Descrição',
  howitworks3_enabled: '3º Como Funciona Habilitado',
  howitworks3_title: '3º Como Funciona Título',
  howitworks3_description: '3º Como Funciona Descrição',
  howitworks4_enabled: '4º Como Funciona Habilitado',
  howitworks4_title: '4º Como Funciona Título',
  howitworks4_description: '4º Como Funciona Descrição',
  advantage1_enabled: '1ª Vantagem Habilitada',
  advantage1_title: '1ª Vantagem Título',
  advantage1_description: '1ª Vantagem Descrição',
  advantage1_button_text: '1ª Vantagem Botão Texto',
  advantage1_button_link: '1ª Vantagem Botão Link',
  advantage2_enabled: '2ª Vantagem Habilitada',
  advantage2_title: '2ª Vantagem Título',
  advantage2_description: '2ª Vantagem Descrição',
  advantage2_button_text: '2ª Vantagem Botão Texto',
  advantage2_button_link: '2ª Vantagem Botão Link',
  advantage3_enabled: '3ª Vantagem Habilitada',
  advantage3_title: '3ª Vantagem Título',
  advantage3_description: '3ª Vantagem Descrição',
  advantage3_button_text: '3ª Vantagem Botão Texto',
  advantage3_button_link: '3ª Vantagem Botão Link',
  advantage4_enabled: '4ª Vantagem Habilitada',
  advantage4_title: '4ª Vantagem Título',
  advantage4_description: '4ª Vantagem Descrição',
  advantage4_button_text: '4ª Vantagem Botão Texto',
  advantage4_button_link: '4ª Vantagem Botão Link',
  contrast_color1: 'Cor Contraste Primária',
  contrast_color2: 'Cor Contraste Secundária',
};

export default columnsNames;
