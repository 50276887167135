import React, { useContext } from 'react';

import { Card, CardContent, CardHeader } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { columnDateTime } from 'src/components/Table/types';
import DashboardContext from 'src/pages/Private/Dashboard/context';

const LastPartners: React.FC = () => {
  const {
    chartsInfo: { lastPartners },
  } = useContext(DashboardContext);

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        title="IFEPAF + IFBOLSAS"
        subheader="ÚLTIMAS PARCEIRAS CADASTRADAS (lista com as 10 últimas no período)"
      />

      <CardContent>
        <DataGrid
          autoHeight
          rows={lastPartners || []}
          disableColumnMenu
          columns={[
            { field: 'id', headerName: 'ID', sortable: false },
            {
              field: 'display_name',
              headerName: 'Nome',
              flex: 1,
              sortable: false,
            },
            {
              field: 'max_discount',
              headerName: 'Desc. Máx.',
              width: 120,
              align: 'center',
              headerAlign: 'center',
              valueFormatter({ value }) {
                return value ? `${Number(value) / 100}%` : '0%';
              },
              sortable: false,
            },
            {
              field: 'created_at',
              headerName: 'Criado Em',
              width: 120,
              sortable: false,
              ...columnDateTime,
            },
          ]}
          hideFooter
        />
      </CardContent>
    </Card>
  );
};

export default LastPartners;
