import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import Container from 'src/components/Container';
import DatePickerOperator from 'src/components/Form/DatePickerOperator';
import { IPerson } from 'src/interfaces/models';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import { objectToQuery } from 'src/utils/helpers';
import { gerenateReport, ReportSheetInfo } from 'src/utils/reports';

const Persons: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);

      const { data: persons } = await api.get<IPerson[]>(
        `admin/reports/persons${objectToQuery(formData)}`,
      );

      if (!persons.length) {
        return toast.warning('Nenhum dado a ser exportado.');
      }

      // Persons
      const personSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'Pessoas',
      };
      const subscriptionsSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'PF Assinaturas',
      };
      const personScholarshipsSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'PF Bolsas',
      };
      const personBonusesSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'PF Bônus',
      };
      const collaboratorScholarshipsSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'PJ Bolsas',
      };
      const collaboratorBonusesSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'PJ Bônus',
      };

      for (const {
        beneficiary,
        beneficiary_unity,
        subscriptions,
        scholarships,
        bonuses,
        collaborator_scholarships,
        collaborator_bonuses,
        ...person
      } of persons) {
        if (!personSheet.columns.length) {
          const columns = Object.keys(person);
          columns.splice(
            columns.indexOf('beneficiary_id') + 1,
            0,
            'beneficiary_name',
          );
          columns.splice(
            columns.indexOf('beneficiary_unity_id') + 1,
            0,
            'beneficiary_unity_name',
          );
          personSheet.columns = columns;
        }

        const personData = Object.values(person);
        personData.splice(
          personSheet.columns.indexOf('beneficiary_id') + 1,
          0,
          beneficiary?.display_name,
        );
        personData.splice(
          personSheet.columns.indexOf('beneficiary_unity_id') + 1,
          0,
          beneficiary_unity?.display_name,
        );
        personSheet.data.push(personData);

        // Subscriptions
        if (subscriptions?.length) {
          for (const { subscription, ...personSubscription } of subscriptions) {
            if (!subscriptionsSheet.columns.length) {
              const columns = Object.keys(personSubscription);
              columns.splice(2, 0, 'person_name');
              columns.splice(4, 0, 'subscription_name');
              subscriptionsSheet.columns = columns;
            }
            const data = Object.values(personSubscription);
            data.splice(2, 0, person.name);
            data.splice(4, 0, subscription?.name);
            subscriptionsSheet.data.push(data);
          }
        }

        // Person Scholarhsips
        if (scholarships?.length) {
          for (const {
            benefit,
            category,
            service,
            teachForm,
            partner,
            vouchers,
            ...scholarship
          } of scholarships) {
            if (!personScholarshipsSheet.columns.length) {
              const columns = Object.keys(scholarship);
              columns.splice(6, 0, 'benefit_name');
              columns.splice(8, 0, 'category_name');
              columns.splice(10, 0, 'partner_name');
              columns.splice(12, 0, 'service_name');
              columns.splice(14, 0, 'teach_form_name');
              vouchers?.forEach((voucher, vIndex) => {
                const keys = Object.keys(voucher);
                keys.forEach((key, index, arr) => {
                  arr[index] = `voucher_${vIndex + 1}_${key}`;
                });

                columns.push(...keys);
              });
              personScholarshipsSheet.columns = columns;
            }
            const data = Object.values(scholarship);
            data.splice(6, 0, benefit?.name);
            data.splice(8, 0, category?.name);
            data.splice(10, 0, partner?.display_name);
            data.splice(12, 0, service?.name);
            data.splice(14, 0, teachForm?.name);
            vouchers?.forEach((voucher) => {
              const values = Object.values(voucher);
              data.push(...values);
            });
            personScholarshipsSheet.data.push(data);
          }
        }

        // Person Bonuses
        if (bonuses?.length) {
          for (const {
            benefit,
            category,
            service,
            partner,
            ...bonus
          } of bonuses) {
            if (!personBonusesSheet.columns.length) {
              const columns = Object.keys(bonus);
              columns.splice(6, 0, 'benefit_name');
              columns.splice(8, 0, 'category_name');
              columns.splice(10, 0, 'partner_name');
              columns.splice(12, 0, 'service_name');
              personBonusesSheet.columns = columns;
            }
            const data = Object.values(bonus);
            data.splice(6, 0, benefit?.name);
            data.splice(8, 0, category?.name);
            data.splice(10, 0, partner?.display_name);
            data.splice(12, 0, service?.name);
            personBonusesSheet.data.push(data);
          }
        }

        // Collaborator Scholarships
        if (collaborator_scholarships?.length) {
          for (const {
            beneficiary,
            beneficiary_unity_id,
            unity,
            benefit,
            category,
            service,
            partner,
            teachForm,
            ...scholarship
          } of collaborator_scholarships) {
            if (!collaboratorScholarshipsSheet.columns.length) {
              const columns = Object.keys(scholarship);
              columns.splice(2, 0, 'person_name');
              columns.splice(4, 0, 'beneficiary_name');
              columns.splice(5, 0, 'beneficiary_unity_id');
              columns.splice(6, 0, 'beneficiary_unity_name');
              columns.splice(8, 0, 'partner_name');
              columns.splice(10, 0, 'benefit_name');
              columns.splice(12, 0, 'category_name');
              columns.splice(14, 0, 'service_name');
              columns.splice(16, 0, 'teach_form_name');
              collaboratorScholarshipsSheet.columns = columns;
            }
            const data = Object.values(scholarship);
            data.splice(2, 0, person?.name);
            data.splice(4, 0, beneficiary?.display_name);
            data.splice(5, 0, beneficiary_unity_id);
            data.splice(6, 0, unity?.display_name);
            data.splice(8, 0, partner?.display_name);
            data.splice(10, 0, benefit?.name);
            data.splice(12, 0, category?.name);
            data.splice(14, 0, service?.name);
            data.splice(16, 0, teachForm?.name);
            collaboratorScholarshipsSheet.data.push(data);
          }
        }

        // Collaborator Bonuses
        if (collaborator_bonuses?.length) {
          for (const {
            beneficiary,
            beneficiary_unity_id,
            unity,
            benefit,
            category,
            service,
            partner,
            ...scholarship
          } of collaborator_bonuses) {
            if (!collaboratorBonusesSheet.columns.length) {
              const columns = Object.keys(scholarship);
              columns.splice(2, 0, 'person_name');
              columns.splice(4, 0, 'beneficiary_name');
              columns.splice(5, 0, 'beneficiary_unity_id');
              columns.splice(6, 0, 'beneficiary_unity_name');
              columns.splice(8, 0, 'partner_name');
              columns.splice(10, 0, 'benefit_name');
              columns.splice(12, 0, 'category_name');
              columns.splice(14, 0, 'service_name');
              collaboratorBonusesSheet.columns = columns;
            }
            const data = Object.values(scholarship);
            data.splice(2, 0, person?.name);
            data.splice(4, 0, beneficiary?.display_name);
            data.splice(5, 0, beneficiary_unity_id);
            data.splice(6, 0, unity?.display_name);
            data.splice(8, 0, partner?.display_name);
            data.splice(10, 0, benefit?.name);
            data.splice(12, 0, category?.name);
            data.splice(14, 0, service?.name);
            collaboratorBonusesSheet.data.push(data);
          }
        }
      }

      gerenateReport({
        sheets: [
          personSheet,
          subscriptionsSheet,
          personScholarshipsSheet,
          personBonusesSheet,
          collaboratorScholarshipsSheet,
          collaboratorBonusesSheet,
        ],
        fileName: 'relatorio-pessoas.xlsx',
      });
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao gerar relatório.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Relatório de Pessoas</Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Form ref={formRef} onSubmit={handleSubmit} noValidate>
              <CardHeader subheader="Filtros" />

              <CardContent>
                <DatePickerOperator
                  name="created_at"
                  label="Data de Cadastro"
                />
              </CardContent>

              <CardActions style={{ justifyContent: 'flex-end' }}>
                <Button
                  startIcon={<CheckIcon />}
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                >
                  Gerar Relatório
                </Button>
              </CardActions>
            </Form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Persons;
