import { FC, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';

import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { GridColDef } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/Email';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import CustomMenuItem from 'src/components/Table/CustomMenuItem';
import StatusIcon from 'src/components/Table/StatusIcon';
import { columnDateTime } from 'src/components/Table/types';
import usePagination from 'src/hooks/usePagination';
import { IPersonBonusVoucher } from 'src/interfaces/models/IPerson';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import masks from 'src/utils/masks';
import PersonBonusFilterSchema from 'src/validators/PersonBonus/filter.schema';

import Filters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const filterRef = useRef<FilterDrawerRef>(null);
  const mailDialogRef = useRef<ConfirmDialogRef>(null);
  const [selectedVoucher, setSelectedVoucher] = useState<IPersonBonusVoucher>();
  const [loading, setLoading] = useState(false);
  const { url } = useRouteMatch();
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading: paginationLoading,
    info,
    loadData,
  } = usePagination<IPersonBonusVoucher>('admin/persons-bonuses');

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'personSubscription.person.name',
      headerName: 'Pessoa',
      flex: 1,
      valueGetter({ row }) {
        return row.personSubscription?.person?.name;
      },
    },
    {
      field: 'name',
      headerName: 'Beneficiário',
      flex: 1,
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2">{row.name}</Typography>
            <Typography variant="body2">{masks.cpf(row.document)}</Typography>
          </div>
        );
      },
    },
    {
      field: 'service.name',
      headerName: 'Serviço',
      flex: 1,
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2">
              {`${row.service?.name} (${row.teachForm?.name}) - ${
                Number(row.discount) / 100 || 0
              }%`}
            </Typography>
            <Typography variant="body2">{row.partner?.display_name}</Typography>
          </div>
        );
      },
    },
    {
      field: 'created_at',
      headerName: 'Criado Em',
      ...columnDateTime,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      renderCell({ id, row }) {
        return (
          <TableActions
            resource="Bônus"
            resourceStatus={row.status}
            editLink={row.status !== 'inactive' ? `${url}/${id}` : undefined}
            deleteApiUrl={`/admin/persons-bonuses/${id}`}
            descriptionPrefix={`O bônus do(a) ${row.name}`}
            onFinish={loadData}
            startItems={
              row.status === 'active' && (
                <>
                  <CustomMenuItem
                    Icon={EyeIcon}
                    iconProps={{ color: 'primary' }}
                    text="Ver Voucher"
                    onClick={() => {
                      window.open(row.voucher_url, '_blank');
                    }}
                  />
                  <CustomMenuItem
                    Icon={EmailIcon}
                    iconProps={{ color: 'primary' }}
                    text="Enviar Voucher"
                    onClick={() => {
                      setSelectedVoucher(row as IPersonBonusVoucher);
                      mailDialogRef.current?.show();
                    }}
                  />
                </>
              )
            }
          />
        );
      },
    },
  ];

  const handleMailConfirm = async () => {
    try {
      setLoading(true);

      await api.post(`/admin/persons-bonuses/${selectedVoucher?.id}/send-mail`);
      toast.success(`E-mail enviado!`);
      mailDialogRef.current?.hide();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao enviar e-mail.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <FilterDrawer
        ref={filterRef}
        columns={columns.filter((column) => !column.hide && column.field)}
        yupSchema={PersonBonusFilterSchema}
      >
        <Filters formRef={filterRef.current?.getFormRef()} />
      </FilterDrawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">Bônus de Assinantes</Typography>

            <LinkButton
              to={`${url}/novo`}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Novo
            </LinkButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                page={info.current_page - 1}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={paginationLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onFilterClick={filterRef.current?.open}
                canSearch
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <ConfirmDialog
        ref={mailDialogRef}
        title="Enviar voucher?"
        description="Será enviado um email à pessoa com o voucher do bonus em anexo ou um email para a parceira para orçamento, caso a parceira esteja configurada como orçamento."
        confirmColor="primary"
        onConfirm={handleMailConfirm}
        loading={loading}
      />
    </Container>
  );
};

export default List;
