import { FC } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { IRouteInterface } from 'src/interfaces/IRoutes';

import AuthLayout from './Layout';

const Auth: FC<IRouteInterface> = ({
  component: Component,
  title,
  ...rest
}) => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) =>
        !user ? (
          <>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            <AuthLayout>
              <Component {...props} />
            </AuthLayout>
          </>
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
};

export default Auth;
