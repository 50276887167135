import { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { SubmitHandler, FormHandles } from '@unform/core';
import TextField from 'src/components/Form/TextField';
import Link from 'src/components/Link';
import { ILoginResponse } from 'src/interfaces/responses/ILogin';
import api from 'src/services/api';
import { AuthActions } from 'src/store/ducks/auth';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import loginSchema from 'src/validators/User/login.schema';

import {
  Container,
  GridImage,
  GridForm,
  Paper,
  Avatar,
  Form,
  SubmitButton,
} from './styles';

const Login: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        loginSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const response = await api.post<ILoginResponse>('admin/login', data);

      const { authentication, user } = response.data;

      dispatch(AuthActions.authSuccess(authentication.token, user));
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', loginSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container container>
      <GridImage item xs={false} sm={4} md={7} />
      <GridForm item xs={12} sm={8} md={5}>
        <Paper elevation={6} square>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>

          <Box mt={3} width="100%">
            <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    label="E-mail"
                    type="email"
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="password"
                    label="Senha"
                    required
                    type="password"
                    autoComplete="current-password"
                  />
                </Grid>
              </Grid>

              <SubmitButton
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Entrar
              </SubmitButton>
            </Form>
          </Box>

          <Grid container justify="flex-end">
            <Link to="/esqueci-minha-senha" variant="body2">
              Esqueceu sua Senha?
            </Link>
          </Grid>

          <Box mt={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              <Link
                color="inherit"
                to={{ pathname: 'https://wisolutions.com.br' }}
                target="_blank"
              >
                Wi Solutions
              </Link>{' '}
              {new Date().getFullYear()}
              {'.'}
            </Typography>
          </Box>
        </Paper>
      </GridForm>
    </Container>
  );
};

export default Login;
