import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import DatePicker from 'src/components/Form/DatePicker';
import TextField from 'src/components/Form/TextField';
import { IEditPersonScholarshipVoucherParams } from 'src/interfaces/forms/IPersonScholarship';
import { IPersonScholarshipVoucher } from 'src/interfaces/models/IPerson';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  PersonScholarshipVoucherStoreSchema,
  PersonScholarshipVoucherUpdateSchema,
} from 'src/validators/PersonScholarshipVoucher/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = useParams<IEditPersonScholarshipVoucherParams>();

  const yupSchema = params.voucherId
    ? PersonScholarshipVoucherUpdateSchema
    : PersonScholarshipVoucherStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        if (params.voucherId) {
          const response = await api.get<IPersonScholarshipVoucher>(
            `admin/persons-scholarships/${params.id}/vouchers/${params.voucherId}`,
          );
          const voucher = response.data;

          setStatus(voucher.status);

          formRef.current?.setData(voucher);
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, params.voucherId, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.voucherId) {
        await api.put(
          `/admin/persons-scholarships/${params.id}/vouchers/${params.voucherId}`,
          data,
        );
      } else {
        await api.post(
          `/admin/persons-scholarships/${params.id}/vouchers`,
          data,
        );
      }

      toast.success('Dados salvos com sucesso!');
      history.replace(`/assinantes/bolsas/${params.id}/vouchers`);
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (status === 'active') {
        await api.delete(
          `/admin/persons-scholarships/${params.id}/vouchers/${params.voucherId}`,
        );
        toast.success('Voucher inativado!');
      } else {
        await api.post(
          `/admin/persons-scholarships/${params.id}/vouchers/${params.voucherId}/restore`,
        );
        toast.success('Voucher restaurado!');
      }
      history.replace(`/assinantes/bolsas/${params.id}/vouchers`);
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar voucher.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {params.voucherId ? 'Editar' : 'Novo'} Voucher
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8}>
                    <TextField name="course" label="Curso" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name="campus" label="Polo/Campus" />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField name="year" label="Ano" type="number" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name="semester" label="Semestre" type="number" />
                  </Grid>
                  {params.voucherId && (
                    <Grid item xs={12} sm={4}>
                      <DatePicker
                        name="created_at"
                        label="Data Emissão"
                        maxDate={new Date()}
                        valueAsDate
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              {status === 'active' ? (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="secondary"
                  disabled={!params.voucherId}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.voucherId}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${status === 'active' ? 'Desativar' : 'Restaurar'} voucher`}
        description="Confirma esta ação?"
        confirmColor={status === 'active' ? 'secondary' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
