import { FC, useCallback, useEffect, useRef, useState } from 'react';

import {
  InputAdornment,
  TextField,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useField } from '@unform/core';
import { ColorPicker, ColorValue } from 'material-ui-color';

import { InputColorType } from './interfaces';

const InputColor: FC<InputColorType> = ({
  name,
  label,
  onChange,
  required,
}) => {
  const valueRef = useRef<{ value: string }>({ value: '' });
  const [value, setValue] = useState<ColorValue>();
  const [hex, setHex] = useState('');

  const {
    fieldName,
    registerField,
    error,
    defaultValue = '',
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: valueRef,
      getValue: (ref) => {
        return ref.current?.value || '';
      },
      setValue: (ref, value = '') => {
        ref.current.value = value;
        setValue(value);
        setHex(value);
        clearError();
      },
      clearValue: (ref, newValue = '') => {
        ref.current.value = newValue;
        setValue(newValue);
        setHex(newValue);
        clearError();
      },
    });
  }, [fieldName, registerField, clearError]);

  useEffect(() => {
    if (valueRef.current && defaultValue) {
      valueRef.current.value = defaultValue;
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = useCallback<NonNullable<InputColorType['onChange']>>(
    (color) => {
      const newHex = `#${color.hex}`;

      if (valueRef.current) {
        valueRef.current.value = newHex;
        setValue(color);
        setHex(newHex);
      }

      clearError();

      if (onChange) onChange(color);
    },
    [onChange, clearError],
  );

  function handleRemove() {
    setValue(undefined);
    setHex('');
    valueRef.current.value = '';
  }

  return (
    <TextField
      label={label}
      disabled
      value={hex}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <ColorPicker onChange={handleChange} hideTextfield value={value} />
          </InputAdornment>
        ),
        endAdornment: !required && !!value && (
          <InputAdornment position="end">
            <Tooltip title="Remover Cor">
              <IconButton size="small" color="secondary" onClick={handleRemove}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      error={!!error}
      helperText={error}
      aria-describedby={fieldName}
      variant="outlined"
      fullWidth
      required={required}
    />
  );
};

export default InputColor;
