import React, { useContext, useState } from 'react';

import { Card, CardContent, CardHeader } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { columnDateTime } from 'src/components/Table/types';
import { IClassification } from 'src/interfaces/models';
import DashboardContext from 'src/pages/Private/Dashboard/context';

const BeneficiariesNotUsedChart: React.FC = () => {
  const {
    chartsInfo: { beneficiariesNotUsed },
  } = useContext(DashboardContext);
  const [page, setPage] = useState(0);

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        style={{ color: '#01CB98' }}
        title="IFEPAF"
        subheader="BENEFICIÁRIAS QUE NÃO UTILIZAM (no período)"
      />

      <CardContent>
        <DataGrid
          autoHeight
          rows={beneficiariesNotUsed || []}
          disableColumnMenu
          columns={[
            { field: 'id', headerName: 'ID', sortable: false },
            {
              field: 'display_name',
              headerName: 'Nome',
              flex: 1,
              sortable: false,
            },
            {
              field: 'classification',
              headerName: 'Classificação',
              flex: 1,
              sortable: false,
              valueGetter({ row }) {
                return (
                  row.classifications
                    ?.map(
                      (classification: IClassification) => classification.name,
                    )
                    .join(' - ') || ''
                );
              },
            },
            {
              field: 'created_at',
              headerName: 'Criado Em',
              width: 120,
              sortable: false,
              ...columnDateTime,
            },
          ]}
          pagination
          paginationMode="client"
          page={page}
          pageSize={10}
          onPageChange={setPage}
        />
      </CardContent>
    </Card>
  );
};

export default BeneficiariesNotUsedChart;
