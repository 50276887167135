import yup from 'src/libs/yup';
import { operators } from 'src/utils/helpers';

const oneOfOpearators = operators
  .filter((op) => !!op.value)
  .map((op) => op.value);

const BeneficiaryFilterSchema = yup.object().shape({
  display_name: yup.string().label('Nome'),
  document: yup.string().label('CNPJ'),
  city: yup.string().label('Cidade'),
  state: yup.string().label('UF'),
  status: yup.string().nullable().label('Status'),
  has_unities: yup.string().nullable().label('Possui Unidade'),
  has_subsite: yup.string().nullable().label('Possui Subsite'),
  qty_employees: yup
    .object()
    .optional()
    .shape({
      operator: yup
        .string()
        .nullable()
        .oneOf(['', ...oneOfOpearators])
        .requiredWhen(yup.ref('value'), 'Valor')
        .label('Operador'),
      value: yup
        .string()
        .requiredWhen(yup.ref('operator'), 'Operador')
        .when('operator', {
          is: 'between',
          then: yup.string().label('De'),
          otherwise: yup.string().label('Valor'),
        }),
      value_end: yup
        .string()
        .when('operator', {
          is: 'between',
          then: yup.string().required(),
          otherwise: yup.string().optional(),
        })
        .label('Até'),
    })
    .label('Qtde Beneficiarios'),
});

export default BeneficiaryFilterSchema;
