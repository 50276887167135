import React, { useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { Scope } from '@unform/core';
import DatePicker from 'src/components/Form/DatePicker';
import { DatePickerType } from 'src/components/Form/DatePicker/interfaces';
import Select from 'src/components/Form/Select';
import { SelectProps } from 'src/components/Form/Select/interfaces';
import { operators } from 'src/utils/helpers';

type DatePickerOperatorProps = {
  name: string;
  label?: string;
  selectOperatorProps?: SelectProps;
  datePickerProps?: DatePickerType;
};

const DatePickerOperator: React.FC<DatePickerOperatorProps> = ({
  name,
  label,
  selectOperatorProps,
  datePickerProps,
}) => {
  const [between, setBetween] = useState(false);

  return (
    <Grid container spacing={1}>
      <Scope path={name}>
        {label && (
          <Grid item xs={12}>
            <Typography variant="body1">{label}</Typography>
          </Grid>
        )}
        <Grid item xs={4}>
          <Select
            name="operator"
            label="Operador"
            items={operators}
            onChange={(value: any) => setBetween(value === 'between')}
            variant="outlined"
            fullWidth
            {...selectOperatorProps}
          />
        </Grid>

        <Grid item xs={between ? 4 : 8}>
          <DatePicker
            label={between ? 'De' : 'Data'}
            name="value"
            fullWidth
            defaultValue={null}
            inputVariant="outlined"
            {...datePickerProps}
          />
        </Grid>

        {between && (
          <Grid item xs={4}>
            <DatePicker
              label="Até"
              name="value_end"
              fullWidth
              defaultValue={null}
              inputVariant="outlined"
              {...datePickerProps}
            />
          </Grid>
        )}
      </Scope>
    </Grid>
  );
};

export default DatePickerOperator;
