import { FC, useRef } from 'react';
import { useRouteMatch } from 'react-router';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { GridColDef } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import EditButton from 'src/components/Table/EditButton';
import StatusIcon from 'src/components/Table/StatusIcon';
import usePagination from 'src/hooks/usePagination';
import { ICommonQuestion } from 'src/interfaces/models';
import CommonQuestionFilterSchema from 'src/validators/CommonQuestion/filter.schema';

import Filters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const filterRef = useRef<FilterDrawerRef>(null);
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading,
    info,
  } = usePagination<ICommonQuestion>('admin/common-questions', {
    website: 'ifepaf',
  });
  const { url } = useRouteMatch();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'title', headerName: 'Título', flex: 1 },
    { field: 'position', headerName: 'Prosição', align: 'center' },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      renderCell(params) {
        return <EditButton to={`${url}/${params.id}`} />;
      },
    },
  ];

  return (
    <Container>
      <FilterDrawer
        ref={filterRef}
        columns={columns.filter((column) => !column.hide && column.field)}
        yupSchema={CommonQuestionFilterSchema}
      >
        <Filters />
      </FilterDrawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="flex-end" alignItems="center">
            <LinkButton
              to={`${url}/novo`}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Nova
            </LinkButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                page={info.current_page - 1}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={loading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onFilterClick={filterRef.current?.open}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
