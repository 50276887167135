import yup from 'src/libs/yup';

export const CollaboratorScholarshipAdminStoreSchema = yup.object().shape({
  person_id: yup.number().required().label('Pessoa'),
  beneficiary_id: yup.number().required().label('Beneficiária'),
  beneficiary_unity_id: yup.number().required().label('Unidade'),
  partner_service_id: yup.number().required().label('Serviço da Parceira'),
  classification: yup.string().required().label('Classificação'),
  name: yup.string().required().label('Nome'),
  document: yup.string().cpf().required().label('CPF'),
  national_identity: yup.string().label('RG'),
  birth_date: yup.string().dateFormat('dd/MM/yyyy').label('Nascimento'),
  course: yup.string().required().label('Curso'),
  campus: yup.string().required().label('Campus'),
  year: yup.string().required().label('Ano'),
  semester: yup.string().label('Semester'),
});

export const CollaboratorScholarshipAdminUpdateSchema = yup.object().shape({
  person_id: yup.number().required().label('Pessoa'),
  beneficiary_id: yup.number().required().label('Beneficiária'),
  beneficiary_unity_id: yup.number().required().label('Unidade'),
  partner_service_id: yup.number().required().label('Serviço da Parceira'),
  classification: yup.string().required().label('Classificação'),
  name: yup.string().required().label('Nome'),
  document: yup.string().cpf().required().label('CPF'),
  national_identity: yup.string().label('RG'),
  birth_date: yup.string().dateFormat('dd/MM/yyyy').label('Nascimento'),
  course: yup.string().required().label('Curso'),
  campus: yup.string().required().label('Campus'),
  year: yup.string().required().label('Ano'),
  semester: yup.string().label('Semester'),
  created_at: yup.date().label('Data Emissão'),
});
