import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RestoreIcon from '@material-ui/icons/Restore';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import DatePicker from 'src/components/Form/DatePicker';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import {
  IBeneficiary,
  IBenefit,
  ICategory,
  IPartner,
  IPerson,
} from 'src/interfaces/models';
import { IBeneficiaryUnity } from 'src/interfaces/models/IBeneficiary';
import { IPartnerService } from 'src/interfaces/models/IPartner';
import { ICollaboratorScholarship } from 'src/interfaces/models/IPerson';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import masks from 'src/utils/masks';
import yupValidate from 'src/utils/yupValidate';
import {
  CollaboratorScholarshipAdminStoreSchema,
  CollaboratorScholarshipAdminUpdateSchema,
} from 'src/validators/CollaboratorScholarship/save.schema';

import { Container } from './styles';

const ConfirmDialogTitle: Record<string, string> = {
  pending: 'Aprovar',
  active: 'Desativar',
  inactive: 'Restaurar',
};

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [
    activeScholarship,
    setActiveScholarship,
  ] = useState<ICollaboratorScholarship | null>(null);
  const [persons, setPersons] = useState<IPerson[]>([]);
  const [beneficiaries, setBeneficiaries] = useState<IBeneficiary[]>([]);
  const [unities, setUnities] = useState<IBeneficiaryUnity[]>([]);
  const [benefits, setBenefits] = useState<IBenefit[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [partnerServices, setPartnerServices] = useState<IPartnerService[]>([]);
  const [loading, setLoading] = useState(false);
  const [disableSemester, setDisableSemester] = useState(false);
  const [status, setStatus] = useState('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id
    ? CollaboratorScholarshipAdminUpdateSchema
    : CollaboratorScholarshipAdminStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const getPersons = api.get<IPerson[]>('/admin/persons');
        const getBeneficiaries = api.get<IBeneficiary[]>(
          '/admin/beneficiaries',
        );
        const getBenefits = api.get<IBenefit[]>('/admin/benefits', {
          params: {
            type: 'scholarship',
          },
        });

        const [
          personsResp,
          beneficiariesResp,
          benefitsResp,
        ] = await Promise.all([getPersons, getBeneficiaries, getBenefits]);

        setPersons(personsResp.data);
        setBeneficiaries(beneficiariesResp.data);
        setBenefits(benefitsResp.data);

        if (params.id) {
          const response = await api.get<ICollaboratorScholarship>(
            `admin/collaborator-scholarships/${params.id}`,
          );
          const scholarship = response.data;
          setActiveScholarship(scholarship);

          setStatus(scholarship.status);
          formRef.current?.setData(scholarship);
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handlePersonChange = useCallback(async () => {
    const personId = formRef.current?.getFieldValue('person_id');

    if (!activeScholarship) {
      if (personId) {
        const selectedPerson = persons.find((person) => person.id == personId);
        formRef.current?.setFieldValue('classification', 'collaborator');
        formRef.current?.setFieldValue('name', selectedPerson?.name);
        formRef.current?.setFieldValue('document', selectedPerson?.document);
        formRef.current?.setFieldValue(
          'birth_date',
          selectedPerson?.birth_date,
        );
        formRef.current?.setFieldValue(
          'beneficiary_id',
          selectedPerson?.beneficiary_id,
        );
      } else {
        formRef.current?.setFieldValue('classification', null);
        formRef.current?.setFieldValue('name', '');
        formRef.current?.setFieldValue('document', '');
        formRef.current?.setFieldValue('birth_date', '');
        formRef.current?.setFieldValue('beneficiary_id', null);
      }
    }
  }, [persons, activeScholarship]);

  const handleBeneficiaryChange = useCallback(async () => {
    const beneficiaryId = formRef.current?.getFieldValue('beneficiary_id');

    if (beneficiaryId) {
      const unitiesResp = await api.get<IBeneficiaryUnity[]>(
        `admin/beneficiaries/${beneficiaryId}/unities`,
        { params: { status: 'active' } },
      );
      setUnities(unitiesResp.data);

      setTimeout(() =>
        formRef.current?.setFieldValue(
          'beneficiary_unity_id',
          activeScholarship?.beneficiary_unity_id,
        ),
      );
    } else {
      setUnities([]);
      formRef.current?.setFieldValue('beneficiary_unity_id', null);
    }
  }, [activeScholarship]);

  useEffect(() => {
    const personId = formRef.current?.getFieldValue('person_id');

    if (personId) {
      const selectedPerson = persons.find((person) => person.id == personId);
      formRef.current?.setFieldValue(
        'beneficiary_unity_id',
        selectedPerson?.beneficiary_unity_id,
      );
    } else {
      formRef.current?.setFieldValue('beneficiary_unity_id', null);
    }
  }, [unities, persons]);

  const handleBenefitChange = useCallback(async () => {
    try {
      setLoading(true);

      const benefitId = formRef.current?.getFieldValue('benefit_id');

      if (!benefitId) {
        setCategories([]);
        formRef.current?.setFieldValue('category_id', null);
        return;
      }

      const response = await api.get<ICategory[]>(`/admin/categories`, {
        params: { benefit_id: benefitId },
      });

      setCategories(response.data);

      setTimeout(() =>
        formRef.current?.setFieldValue(
          'category_id',
          activeScholarship?.category_id,
        ),
      );
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar categorias.');
    } finally {
      setLoading(false);
    }
  }, [activeScholarship]);

  const handleCategoryChange = useCallback(async () => {
    try {
      setLoading(true);

      const categoryId = formRef.current?.getFieldValue('category_id');

      if (!categoryId) {
        setPartners([]);
        formRef.current?.setFieldValue('partner_id', null);
        return;
      }

      const selectedCategory = categories.find(
        (category) => category.id === categoryId,
      );
      if (selectedCategory?.period === 'annual') {
        setDisableSemester(true);
        formRef.current?.setFieldValue('semester', '');
      } else {
        setDisableSemester(false);
      }

      const response = await api.get<IPartner[]>(`/admin/partners`, {
        params: { category_id: categoryId },
      });
      setPartners(response.data);

      setTimeout(() =>
        formRef.current?.setFieldValue(
          'partner_id',
          activeScholarship?.partner_id,
        ),
      );
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar parceiras.');
    } finally {
      setLoading(false);
    }
  }, [activeScholarship, categories]);

  const handlePartnerChange = useCallback(async () => {
    try {
      setLoading(true);

      const categoryId = formRef.current?.getFieldValue('category_id');
      const partnerId = formRef.current?.getFieldValue('partner_id');

      if (!partnerId || !categoryId) {
        setPartnerServices([]);
        formRef.current?.setFieldValue('partner_service_id', null);
        return;
      }

      const response = await api.get<IPartnerService[]>(
        `/admin/partners/${partnerId}/services`,
        {
          params: { category_id: categoryId },
        },
      );
      setPartnerServices(response.data);

      const selected = response.data.find(
        (partnerService) =>
          partnerService.service_id === activeScholarship?.service_id &&
          partnerService.teach_form_id === activeScholarship.teach_form_id,
      );

      setTimeout(() =>
        formRef.current?.setFieldValue('partner_service_id', selected?.id),
      );
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao buscar serviços da parceira.',
      );
    } finally {
      setLoading(false);
    }
  }, [activeScholarship]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.id) {
        await api.put(`/admin/collaborator-scholarships/${params.id}`, data);
      } else {
        await api.post('/admin/collaborator-scholarships', data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (status === 'active') {
        await api.delete(`/admin/collaborator-scholarships/${params.id}`);
        toast.success('Bolsa desativada!');
      } else {
        await api.post(`/admin/collaborator-scholarships/${params.id}/restore`);
        toast.success('Bolsa restaurada!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar bolsa.');
    } finally {
      setLoading(false);
    }
  };

  const renderStatusButton = () => {
    switch (status) {
      case 'pending':
      // return (
      //   <Button
      //     type="button"
      //     loading={layoutLoading}
      //     startIcon={<CheckIcon />}
      //     color="primary"
      //     disabled={!params.id}
      //     onClick={() => confirmDialogRef.current?.show()}
      //   >
      //     Aprovar
      //   </Button>
      // );
      case 'inactive':
      case 'rejected':
        return (
          <Button
            type="button"
            loading={layoutLoading}
            startIcon={<RestoreIcon />}
            color="primary"
            disabled={!params.id || status === 'pending'}
            onClick={() => confirmDialogRef.current?.show()}
          >
            Restaurar
          </Button>
        );
      default:
        return (
          <Button
            type="button"
            loading={layoutLoading}
            startIcon={<CloseIcon />}
            variant="contained"
            color="secondary"
            disabled={!params.id}
            onClick={() => confirmDialogRef.current?.show()}
          >
            Desativar
          </Button>
        );
    }
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Nova'} Bolsa para Colaborador
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Autocomplete
                      name="person_id"
                      label="Pessoa"
                      options={persons.map((person) => ({
                        key: person.id,
                        label: person.name,
                        value: person.id,
                      }))}
                      loading={layoutLoading}
                      textFieldProps={{ required: true }}
                      onChange={handlePersonChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="beneficiary_id"
                      label="Beneficiária"
                      options={beneficiaries.map((beneficiary) => ({
                        key: beneficiary.id,
                        label: beneficiary.display_name,
                        value: beneficiary.id,
                      }))}
                      onChange={handleBeneficiaryChange}
                      loading={layoutLoading}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="beneficiary_unity_id"
                      label="Unidade"
                      options={unities.map((unity) => ({
                        key: unity.id,
                        label: unity.display_name,
                        value: unity.id,
                      }))}
                      noOptionsText="Selecione uma Beneficiária..."
                      loading={layoutLoading}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="benefit_id"
                      label="Benefício"
                      options={benefits.map((benefit) => ({
                        key: benefit.id,
                        label: benefit.name,
                        value: benefit.id,
                      }))}
                      loading={layoutLoading}
                      onChange={handleBenefitChange}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="category_id"
                      label="Categoria"
                      noOptionsText="Selecione um Benefício"
                      options={categories.map((category) => ({
                        key: category.id,
                        label: category.name,
                        value: category.id,
                      }))}
                      loading={loading}
                      onChange={handleCategoryChange}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      name="partner_id"
                      label="Parceira"
                      noOptionsText="Selecione uma Categoria"
                      options={partners.map((partner) => ({
                        key: partner.id,
                        label: partner.display_name,
                        value: partner.id,
                      }))}
                      loading={loading}
                      onChange={handlePartnerChange}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      name="partner_service_id"
                      label="Serviço da Parceira"
                      noOptionsText="Selecione uma Parceira"
                      options={partnerServices.map((partnerService) => ({
                        key: partnerService.id,
                        label: `${partnerService.service?.name} (${
                          partnerService.teach_form?.name
                        }) - ${masks.percent(
                          partnerService.discount.toString(),
                        )} %`,
                        value: partnerService.id,
                      }))}
                      loading={loading}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      name="classification"
                      label="Classificação"
                      options={[
                        { key: 1, label: 'Colaborador', value: 'collaborator' },
                        { key: 2, label: 'Dependente', value: 'dependent' },
                      ]}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField name="name" label="Nome" required />
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <TextField
                      name="document"
                      label="CPF"
                      required
                      mask="cpf"
                      returnUnmasked
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <TextField name="national_identity" label="RG" />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <DatePicker
                      name="birth_date"
                      label="Nascimento"
                      clearable={false}
                      disableToolbar={false}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField name="course" label="Curso" required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="campus" label="Polo/Campus" required />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField name="year" label="Ano" type="number" required />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="semester"
                      label="Semestre"
                      type="number"
                      inputProps={{ min: 1, max: 2 }}
                      disabled={disableSemester}
                    />
                  </Grid>

                  {params.id && (
                    <Grid item xs={12} sm={4}>
                      <DatePicker
                        name="created_at"
                        label="Data Emissão"
                        maxDate={new Date()}
                        valueAsDate
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              {renderStatusButton()}

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${ConfirmDialogTitle[status]} bolsa`}
        description="Confirma esta ação?"
        confirmColor={status === 'active' ? 'secondary' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
