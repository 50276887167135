import { FC, RefObject, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import Autocomplete from 'src/components/Form/Autocomplete';
import { IOption } from 'src/components/Form/Autocomplete/interfaces';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import {
  IBeneficiary,
  IPartner,
  IPerson,
  IService,
  ITeachForm,
} from 'src/interfaces/models';
import api from 'src/services/api';

const statusItems: Item[] = [
  { key: 0, label: 'Pendente', value: 'pending' },
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
  { key: 3, label: 'Negado', value: 'rejected' },
];

interface FilterProps {
  formRef?: RefObject<FormHandles>;
}

const Filters: FC<FilterProps> = ({ formRef }) => {
  const [loading, setLoading] = useState(false);
  const [personOptions, setPersonOptions] = useState<IOption[]>([]);
  const [beneficiaryOptions, setBeneficiaryOptions] = useState<IOption[]>([]);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [services, setServices] = useState<IService[]>([]);
  const [teachForms, setTeachForms] = useState<ITeachForm[]>([]);
  const location = useLocation();

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const getPersons = api.get<IPerson[]>('/admin/persons');
        const getBeneficiaries = api.get<IBeneficiary[]>(
          '/admin/beneficiaries',
        );
        const getPartners = api.get<IPartner[]>('/admin/partners', {
          params: { benefit_type: 'scholarship' },
        });
        const getServices = api.get<IService[]>('/admin/services', {
          params: { benefit_type: 'scholarship', load_category: true },
        });
        const getTeachForms = api.get<ITeachForm[]>('/admin/teach-forms');

        const [
          personsResp,
          beneficiariesResp,
          partnersResp,
          servicesResp,
          teachFormsResp,
        ] = await Promise.all([
          getPersons,
          getBeneficiaries,
          getPartners,
          getServices,
          getTeachForms,
        ]);

        const persons = personsResp.data.map((person) => ({
          key: person.id,
          label: person.name,
          value: person.id,
        }));
        setPersonOptions(persons);

        const beneficiaries = beneficiariesResp.data.map((beneficiary) => ({
          key: beneficiary.id,
          label: beneficiary.display_name,
          value: beneficiary.id,
        }));
        setBeneficiaryOptions(beneficiaries);

        setPartners(partnersResp.data);
        setServices(servicesResp.data);
        setTeachForms(teachFormsResp.data);
      } catch (error) {
        setPersonOptions([]);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const personId = new URLSearchParams(location.search).get('person_id');
    if (personId && formRef) {
      formRef.current?.setFieldValue(
        'person_id',
        personOptions.find((person) => person.value == personId),
      );
    }
  }, [formRef, personOptions, location.search]);

  useEffect(() => {
    const beneficiaryId = new URLSearchParams(location.search).get(
      'beneficiary_id',
    );
    if (beneficiaryId && formRef) {
      formRef.current?.setFieldValue(
        'beneficiary_id',
        beneficiaryOptions.find(
          (beneficiary) => beneficiary.value == beneficiaryId,
        ),
      );
    }
  }, [formRef, beneficiaryOptions, location.search]);

  useEffect(() => {
    const partnerId = new URLSearchParams(location.search).get('partner_id');
    formRef?.current?.setFieldValue('partner_id', partnerId);
  }, [formRef, partners, location.search]);

  useEffect(() => {
    const serviceId = new URLSearchParams(location.search).get('service_id');
    formRef?.current?.setFieldValue('service_id', serviceId);
  }, [formRef, services, location.search]);

  useEffect(() => {
    const teachFormId = new URLSearchParams(location.search).get(
      'teach_form_id',
    );
    formRef?.current?.setFieldValue('teach_form_id', teachFormId);
  }, [formRef, teachForms, location.search]);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="person_id"
          label="Pessoa"
          size="small"
          options={personOptions}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="beneficiary_id"
          label="Beneficiária"
          size="small"
          options={beneficiaryOptions}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="partner_id"
          label="Parceira"
          size="small"
          options={partners.map((partner) => ({
            key: partner.id,
            label: partner.display_name,
            value: partner.id,
          }))}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="service_id"
          label="Serviço"
          size="small"
          options={services.map((service) => ({
            key: service.id,
            label: `${service.name} (${service.category?.name})`,
            value: service.id,
          }))}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="teach_form_id"
          label="Forma de Ensino"
          size="small"
          options={teachForms.map((teachForm) => ({
            key: teachForm.id,
            label: teachForm.name,
            value: teachForm.id,
          }))}
          loading={loading}
        />
      </Grid>

      <Grid item xs={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
