import { FC, useRef, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { GridColDef } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/Email';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import CustomIconButton from 'src/components/Table/CustomIconButton';
import EditButton from 'src/components/Table/EditButton';
import StatusIcon from 'src/components/Table/StatusIcon';
import usePagination from 'src/hooks/usePagination';
import { IEditPersonScholarshipParams } from 'src/interfaces/forms/IPersonScholarship';
import { IPersonScholarshipVoucher } from 'src/interfaces/models/IPerson';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import PersonScholarshipVoucherFilterSchema from 'src/validators/PersonScholarshipVoucher/filter.schema';

import Filters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const filterRef = useRef<FilterDrawerRef>(null);
  const mailDialogRef = useRef<ConfirmDialogRef>(null);
  const params = useParams<IEditPersonScholarshipParams>();
  const [
    selectedVoucher,
    setSelectedVoucher,
  ] = useState<IPersonScholarshipVoucher | null>(null);
  const [loading, setLoading] = useState(false);
  const { url } = useRouteMatch();
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading: paginationLoading,
    info,
  } = usePagination<IPersonScholarshipVoucher>(
    `admin/persons-scholarships/${params.id}/vouchers`,
  );

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'course', headerName: 'Curso', flex: 1 },
    { field: 'campus', headerName: 'Polo/Campus', flex: 0.5 },
    { field: 'year', headerName: 'Ano' },
    { field: 'semester', headerName: 'Semestre' },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      renderCell({ id, row }) {
        return (
          <>
            {row.status === 'active' && (
              <>
                <CustomIconButton
                  Icon={EyeIcon}
                  title="Ver Voucher"
                  onClick={() => {
                    window.open(row.voucher_url, '_blank');
                  }}
                />
                <CustomIconButton
                  Icon={EmailIcon}
                  title="Enviar Voucher"
                  onClick={() => {
                    setSelectedVoucher(row as IPersonScholarshipVoucher);
                    mailDialogRef.current?.show();
                  }}
                />
                <EditButton to={`${url}/${id}`} />
              </>
            )}
          </>
        );
      },
    },
  ];

  const handleMailConfirm = async () => {
    try {
      setLoading(true);

      await api.post(
        `/admin/persons-scholarships/${selectedVoucher?.person_scholarship_id}/vouchers/${selectedVoucher?.id}/send-mail`,
      );
      toast.success(`E-mail enviado!`);
      mailDialogRef.current?.hide();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao enviar e-mail.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <FilterDrawer
        ref={filterRef}
        columns={columns.filter((column) => !column.hide && column.field)}
        yupSchema={PersonScholarshipVoucherFilterSchema}
      >
        <Filters />
      </FilterDrawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="flex-end" alignItems="center">
            <LinkButton
              to={`${url}/novo`}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
              replace
            >
              Novo
            </LinkButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                page={info.current_page - 1}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={paginationLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onFilterClick={filterRef.current?.open}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <ConfirmDialog
        ref={mailDialogRef}
        title="Enviar voucher?"
        description="Será enviado um email à pessoa com o voucher da bolsa em anexo ou um email para a parceira para orçamento, caso a parceira esteja configurada como orçamento."
        confirmColor="primary"
        onConfirm={handleMailConfirm}
        loading={loading}
      />
    </Container>
  );
};

export default List;
