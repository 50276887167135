import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import ListIcon from '@material-ui/icons/List';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PersonIcon from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsIcon from '@material-ui/icons/Settings';
import WorkIcon from '@material-ui/icons/Work';
import { ISideMenuItem } from 'src/interfaces/ISideMenu';

const MenuItems: ISideMenuItem[] = [
  {
    primaryText: 'Dashboard',
    route: '/dashboard',
    icon: DashboardIcon,
  },
  {
    primaryText: 'Leads',
    route: '/leads',
    icon: PermContactCalendarIcon,
  },
  {
    primaryText: 'Pessoas',
    route: '/pessoas',
    search: 'order_by[0][column]=created_at&order_by[0][direction]=desc',
    icon: PersonIcon,
  },
  {
    primaryText: 'Pessoa Física',
    icon: ReceiptIcon,
    subItems: [
      {
        primaryText: 'Assinaturas',
        route: '/assinaturas',
        search: 'order_by[0][column]=created_at&order_by[0][direction]=desc',
        icon: LabelOutlinedIcon,
      },
      {
        primaryText: 'Bolsas',
        route: '/assinantes/bolsas',
        search: 'order_by[0][column]=created_at&order_by[0][direction]=desc',
        icon: LabelOutlinedIcon,
      },
      {
        primaryText: 'Bonus',
        route: '/assinantes/bonus',
        search: 'order_by[0][column]=created_at&order_by[0][direction]=desc',
        icon: LabelOutlinedIcon,
      },
    ],
  },
  {
    primaryText: 'Pessoa Jurídica',
    icon: WorkIcon,
    subItems: [
      {
        primaryText: 'Bolsas',
        route: '/colaboradores/bolsas',
        search: 'order_by[0][column]=created_at&order_by[0][direction]=desc',
        icon: LabelOutlinedIcon,
      },
      {
        primaryText: 'Bonus',
        route: '/colaboradores/bonus',
        search: 'order_by[0][column]=created_at&order_by[0][direction]=desc',
        icon: LabelOutlinedIcon,
      },
    ],
  },
  {
    primaryText: 'Cadastros',
    icon: ListAltIcon,
    subItems: [
      {
        primaryText: 'Benefícios',
        route: '/beneficios',
        icon: LabelOutlinedIcon,
      },
      {
        primaryText: 'Categorias',
        route: '/categorias',
        icon: LabelOutlinedIcon,
      },
      {
        primaryText: 'Serviços',
        route: '/servicos',
        icon: LabelOutlinedIcon,
      },
      {
        primaryText: 'Parceiras',
        route: '/parceiras',
        icon: LabelOutlinedIcon,
      },
      {
        primaryText: 'Beneficiárias',
        route: '/beneficiarias',
        icon: LabelOutlinedIcon,
      },
      {
        primaryText: 'Popups',
        route: '/popups',
        icon: LabelOutlinedIcon,
      },
    ],
  },
  {
    primaryText: 'Configurações',
    icon: SettingsIcon,
    subItems: [
      {
        primaryText: 'IFBolsas',
        route: '/configuracoes/ifbolsas',
        icon: LanguageIcon,
      },
      {
        primaryText: 'IFEPAF',
        route: '/configuracoes/ifepaf',
        icon: LanguageIcon,
      },
      {
        primaryText: 'Usuários',
        route: '/usuarios',
        icon: GroupIcon,
      },
      {
        primaryText: 'Usuários das Beneficiárias',
        route: '/usuarios-beneficiarias',
        icon: GroupIcon,
      },
      {
        primaryText: 'Formas de Ensino',
        route: '/formas-de-ensino',
        icon: ListIcon,
      },
      {
        primaryText: 'Planos',
        route: '/planos',
        icon: ListIcon,
      },
      {
        primaryText: 'Classificações',
        route: '/classificacoes',
        icon: LabelOutlinedIcon,
      },
      {
        primaryText: 'Notícias',
        route: '/noticias',
        icon: LabelOutlinedIcon,
      },
    ],
  },
  {
    primaryText: 'Relatórios',
    icon: InsertChartIcon,
    subItems: [
      {
        primaryText: 'Parceiras',
        icon: LabelOutlinedIcon,
        route: '/relatorios/parceiras',
      },
      {
        primaryText: 'Pessoas',
        icon: LabelOutlinedIcon,
        route: '/relatorios/pessoas',
      },
      {
        primaryText: 'Beneficiárias',
        icon: LabelOutlinedIcon,
        route: '/relatorios/beneficiarias',
      },
      {
        primaryText: 'Bolsas & Bônus',
        icon: LabelOutlinedIcon,
        route: '/relatorios/bolsas-bonus',
      },
    ],
  },
];

export default MenuItems;
