import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import Container from 'src/components/Container';
import DatePickerOperator from 'src/components/Form/DatePickerOperator';
import { IPartner } from 'src/interfaces/models';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import { objectToQuery } from 'src/utils/helpers';
import { gerenateReport, ReportSheetInfo } from 'src/utils/reports';

const Partners: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);

      const { data: partners } = await api.get<IPartner[]>(
        `admin/reports/partners${objectToQuery(formData)}`,
      );

      if (!partners.length) {
        return toast.warning('Nenhum dado a ser exportado.');
      }

      // Partners (headquarter)
      const partnerSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'Parceiras',
      };
      const subsidiariesSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'Filiais',
      };
      const servicesSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'Serviços',
      };
      const responsiblesSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'Responsáveis',
      };
      const contractsSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'Contratos',
      };

      for (const {
        headquarter,
        services,
        subsidiaries,
        responsibles,
        contracts,
        ...partner
      } of partners) {
        const headquarterKeys = headquarter ? Object.keys(headquarter) : [];
        headquarterKeys.forEach((key, index, arr) => {
          arr[index] = `headquarter_${key}`;
        });
        if (!partnerSheet.columns.length) {
          partnerSheet.columns.push(
            ...Object.keys(partner),
            ...headquarterKeys,
          );
        }

        const headquarterValues = headquarter ? Object.values(headquarter) : [];
        partnerSheet.data.push([
          ...Object.values(partner),
          ...headquarterValues,
        ]);

        // Subsidiaries
        for (const subsidiary of subsidiaries || []) {
          if (!subsidiariesSheet.columns.length) {
            const columns = Object.keys(subsidiary);
            columns.splice(2, 0, 'partner_name');
            subsidiariesSheet.columns = columns;
          }
          const data = Object.values(subsidiary);
          data.splice(2, 0, partner.display_name);
          subsidiariesSheet.data.push(data);
        }

        // Services
        if (services?.length) {
          for (const { service, teach_form, ...partnerService } of services) {
            if (!servicesSheet.columns.length) {
              const columns = Object.keys(partnerService);
              columns.splice(2, 0, 'partner_name');
              columns.splice(4, 0, 'service_name');
              columns.splice(6, 0, 'teach_form_name');
              columns.splice(
                7,
                0,
                'category_id',
                'category_name',
                'benefit_id',
                'benefit_name',
              );
              servicesSheet.columns = columns;
            }
            const data = Object.values(partnerService);
            data.splice(2, 0, partner.display_name);
            data.splice(4, 0, service?.name);
            data.splice(6, 0, teach_form?.name);
            data.splice(
              7,
              0,
              service?.category?.id,
              service?.category?.name,
              service?.category?.benefit?.id,
              service?.category?.benefit?.name,
            );
            servicesSheet.data.push(data);
          }
        }

        // Responsibles
        for (const { contacts, ...responsible } of responsibles || []) {
          if (!responsiblesSheet.columns.length) {
            const columns = Object.keys(responsible);
            columns.splice(2, 0, 'partner_name');
            columns.push('contacts');
            responsiblesSheet.columns = columns;
          }

          const data = Object.values(responsible);
          data.splice(2, 0, partner.display_name);
          let contactsData = '';
          for (const contact of contacts || []) {
            if (contactsData.length > 0) {
              contactsData = contactsData.concat('\n');
            }
            contactsData = contactsData.concat(
              `${contact.contact_type}: ${contact.contact_value}`,
            );
          }
          data.push(contactsData);
          responsiblesSheet.data.push(data);
        }

        // Contracts
        for (const contract of contracts || []) {
          if (!contractsSheet.columns.length) {
            const columns = Object.keys(contract);
            columns.splice(2, 0, 'partner_name');
            contractsSheet.columns = columns;
          }
          const data = Object.values(contract);
          data.splice(2, 0, partner.display_name);
          contractsSheet.data.push(data);
        }
      }

      gerenateReport({
        sheets: [
          partnerSheet,
          subsidiariesSheet,
          servicesSheet,
          responsiblesSheet,
          contractsSheet,
        ],
        fileName: 'relatorio-parceiras.xlsx',
      });
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao gerar relatório.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Relatório de Parceiras</Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Form ref={formRef} onSubmit={handleSubmit} noValidate>
              <CardHeader subheader="Filtros" />

              <CardContent>
                <DatePickerOperator
                  name="created_at"
                  label="Data de Cadastro"
                />
              </CardContent>

              <CardActions style={{ justifyContent: 'flex-end' }}>
                <Button
                  startIcon={<CheckIcon />}
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                >
                  Gerar Relatório
                </Button>
              </CardActions>
            </Form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Partners;
