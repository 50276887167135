import { GoogleChartOptions } from 'react-google-charts';

export const defaultChartOptions: GoogleChartOptions = {
  backgroundColor: 'transparent',
  legend: 'top',
  animation: { startup: true, duration: 300 },
  height: 400,
  chartArea: {
    width: '80%',
  },
  colors: ['darkblue', 'tomato', 'darkgreen', 'brown'],
  vAxis: {
    gridlines: {
      multiple: 1,
    },
  },
};
