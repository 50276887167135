import yup from 'src/libs/yup';

export const PersonScholarshipVoucherStoreSchema = yup.object().shape({
  course: yup.string().required().label('Curso'),
  campus: yup.string().required().label('Polo/Campus'),
  year: yup.string().required().label('Ano'),
  semester: yup.string().label('Semestre'),
});

export const PersonScholarshipVoucherUpdateSchema = yup.object().shape({
  course: yup.string().required().label('Curso'),
  campus: yup.string().required().label('Polo/Campus'),
  year: yup.string().required().label('Ano'),
  semester: yup.string().label('Semestre'),
  created_at: yup.date().label('Data Emissão'),
});
