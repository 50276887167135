import { FC } from 'react';

import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { GridColDef } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import StatusIcon from 'src/components/Table/StatusIcon';
import { columnDateTime } from 'src/components/Table/types';
import usePagination from 'src/hooks/usePagination';
import IPopup from 'src/interfaces/models/IPopup';

import { Container } from './styles';

const List: FC = () => {
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    info,
    loading: paginationLoading,
    loadData,
  } = usePagination<IPopup>('admin/popups');

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'description', headerName: 'Descrição', flex: 4 },
    {
      field: 'show_collaborators',
      headerName: 'IFEPAF',
      flex: 1,
      valueFormatter({ value }) {
        return value ? 'Sim' : 'Não';
      },
    },
    {
      field: 'show_subscribers',
      headerName: 'IFBOLSAS',
      flex: 1,
      valueFormatter({ value }) {
        return value ? 'Sim' : 'Não';
      },
    },
    {
      field: 'created_at',
      headerName: 'Criado em',
      flex: 1,
      ...columnDateTime,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      renderCell({ id, row }) {
        return (
          <TableActions
            resource="Popup"
            resourceStatus={row.status}
            editLink={`/popups/${id}`}
            deleteApiUrl={`/admin/popups/${id}`}
            restoreApiUrl={`/admin/popups/${id}/restore`}
            descriptionPrefix={`O popup ${row.id}`}
            onFinish={loadData}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">Popups</Typography>

            <LinkButton
              to="/popups/novo"
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Novo
            </LinkButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                rows={data}
                page={info.current_page - 1}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                pagination
                autoHeight
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                loading={paginationLoading}
                paginationMode="server"
                canSearch
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
