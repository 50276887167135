import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RestoreIcon from '@material-ui/icons/Restore';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import DatePicker from 'src/components/Form/DatePicker';
import Select from 'src/components/Form/Select';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IBeneficiary, IPerson } from 'src/interfaces/models';
import { IBeneficiaryUnity } from 'src/interfaces/models/IBeneficiary';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  PersonStoreSchema,
  PersonUpdateSchema,
} from 'src/validators/Person/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [person, setPerson] = useState<IPerson>();
  // const [status, setStatus] = useState('');
  const [beneficiaries, setBeneficiaries] = useState<IBeneficiary[]>([]);
  const [unities, setUnities] = useState<IBeneficiaryUnity[]>([]);
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id ? PersonUpdateSchema : PersonStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const beneficiariesResp = await api.get<IBeneficiary[]>(
          '/admin/beneficiaries',
        );
        setBeneficiaries(beneficiariesResp.data);

        if (params.id) {
          const response = await api.get(`admin/persons/${params.id}`);
          const person = response.data as IPerson;

          setPerson(person);

          // setStatus(person.status);

          formRef.current?.setData(person);
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleBeneficiaryChange = useCallback(async () => {
    const beneficiaryId = formRef.current?.getFieldValue('beneficiary_id');

    if (beneficiaryId) {
      const unitiesResp = await api.get<IBeneficiaryUnity[]>(
        `admin/beneficiaries/${beneficiaryId}/unities`,
      );
      setUnities(unitiesResp.data);
    } else {
      setUnities([]);
    }
  }, []);

  useEffect(() => {
    if (person?.beneficiary_unity_id) {
      formRef.current?.setFieldValue(
        'beneficiary_unity_id',
        person.beneficiary_unity_id,
      );
    }
  }, [unities, person]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.id) {
        await api.put(`/admin/persons/${params.id}`, data);
      } else {
        await api.post('/admin/persons', data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (person?.status === 'active') {
        const isDelete = confirmDialogRef.current?.isDelete();
        let deleteConfig = {};
        if (isDelete) {
          deleteConfig = { data: { delete: true } };
        }

        await api.delete(`/admin/persons/${params.id}`, deleteConfig);

        toast.success(`Pessoa ${isDelete ? 'excluida' : 'desativada'}!`);
      } else {
        await api.post(`/admin/persons/${params.id}/restore`);
        toast.success('Pessoa restaurada!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao desativar pessoa.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Nova'} Pessoa
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="beneficiary_id"
                      label="Beneficiária"
                      options={beneficiaries.map((beneficiary) => ({
                        key: beneficiary.id,
                        label: beneficiary.display_name,
                        value: beneficiary.id,
                      }))}
                      onChange={handleBeneficiaryChange}
                      loading={layoutLoading}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="beneficiary_unity_id"
                      label="Unidade"
                      options={unities.map((unity) => ({
                        key: unity.id,
                        label: unity.display_name,
                        value: unity.id,
                      }))}
                      noOptionsText="Selecione uma Beneficiária..."
                      loading={layoutLoading}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      name="origin"
                      label="Origem"
                      items={[
                        { key: 0, label: 'IFBOLSAS', value: 'ifbolsas' },
                        { key: 1, label: 'IFEPAF', value: 'ifepaf' },
                      ]}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="document"
                      label="CPF"
                      required
                      mask="cpf"
                      returnUnmasked
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      label="Data de Nascimento"
                      name="birth_date"
                      disableToolbar={false}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="phone"
                      label="Telefone"
                      type="tel"
                      required
                      mask="phoneOrCellphone"
                      returnUnmasked
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField name="name" label="Nome" required />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name="email"
                      label="E-mail"
                      type="email"
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      name="vindi_customer_id"
                      label="ID Cliente Vindi"
                      type="number"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              {person?.status === 'active' ? (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="secondary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${
          person?.status === 'active' ? 'Desativar' : 'Restaurar'
        } pessoa`}
        description="Confirma esta ação?"
        confirmColor={person?.status === 'active' ? 'secondary' : 'primary'}
        onConfirm={handleConfirm}
        canDelete={person?.status === 'active'}
      />
    </Container>
  );
};

export default Save;
