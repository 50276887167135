import yup from 'src/libs/yup';
import { operators } from 'src/utils/helpers';

const oneOfOpearators = operators
  .filter((op) => !!op.value)
  .map((op) => op.value);

const PersonFilterSchema = yup.object().shape({
  birth_date: yup
    .object()
    .optional()
    .shape({
      operator: yup
        .string()
        .transform((value) => (value !== '' ? value : null))
        .nullable()
        .oneOf([null, ...oneOfOpearators])
        .requiredWhen(yup.ref('value'), 'Data')
        .label('Operador'),
      value: yup
        .string()
        .requiredWhen(yup.ref('operator'), 'Operador')
        .dateFormat('dd/MM/yyyy')
        .when('operator', {
          is: 'between',
          then: yup.string().label('De'),
          otherwise: yup.string().label('Data'),
        }),
      value_end: yup
        .string()
        .when('operator', {
          is: 'between',
          then: yup.string().required(),
          otherwise: yup.string().optional(),
        })
        .dateFormat('dd/MM/yyyy')
        .label('Até'),
    })
    .label('Data de Nascimento'),
  name: yup.string().label('Nome'),
  document: yup.string().label('CPF'),
  email: yup.string().label('E-mail'),
  phone: yup.string().label('Telefone'),
  status: yup.string().nullable().label('Status'),
  origin: yup.string().nullable().label('Origem'),
  without_subscription: yup.boolean().label('Sem Assinatura'),
  without_collaborator_voucher: yup
    .boolean()
    .label('Sem Voucher como Colaborador'),
});

export default PersonFilterSchema;
