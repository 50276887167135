import yup from 'src/libs/yup';

export const PersonSubscriptionAdminStoreSchema = yup.object().shape({
  person_id: yup.number().required().label('Pessoa'),
  subscription_id: yup.number().required().label('Plano'),
  school_year: yup.string().required().label('Ano Letivo'),
  payment_form: yup.string().required().label('Forma de Pagamento'),
  payment_in: yup.string().required().label('Pagamento'),
  subscription_installment_id: yup.string().label('Parcelamento'),
});

export const PersonSubscriptionAdminUpdateSchema = yup.object().shape({
  // person_id: yup.number().required().label('Pessoa'),
  // subscription_id: yup.number().required().label('Plano'),
  // payment_form: yup.string().required().label('Nome'),
  // payment_in: yup.string().required().label('Nome'),
});
