import { FC, RefObject, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import Autocomplete from 'src/components/Form/Autocomplete';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import { IPartner, IPerson, IService } from 'src/interfaces/models';
import api from 'src/services/api';

const statusItems: Item[] = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

interface FilterProps {
  formRef?: RefObject<FormHandles>;
}

const Filters: FC<FilterProps> = ({ formRef }) => {
  const [loading, setLoading] = useState(false);
  const [persons, setPersons] = useState<IPerson[]>([]);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [services, setServices] = useState<IService[]>([]);
  const location = useLocation();

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const getPersons = api.get<IPerson[]>('/admin/persons');
        const getPartners = api.get<IPartner[]>('/admin/partners', {
          params: { benefit_type: 'bonus' },
        });
        const getServices = api.get<IService[]>('/admin/services', {
          params: { benefit_type: 'bonus', load_category: true },
        });

        const [personsResp, partnersResp, servicesResp] = await Promise.all([
          getPersons,
          getPartners,
          getServices,
        ]);
        setPersons(personsResp.data);
        setPartners(partnersResp.data);
        setServices(servicesResp.data);
      } catch (error) {
        setPersons([]);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const personId = new URLSearchParams(location.search).get('person_id');
    formRef?.current?.setFieldValue('person_id', personId);
  }, [formRef, persons, location.search]);

  useEffect(() => {
    const partnerId = new URLSearchParams(location.search).get('partner_id');
    formRef?.current?.setFieldValue('partner_id', partnerId);
  }, [formRef, partners, location.search]);

  useEffect(() => {
    const subscriptionId = new URLSearchParams(location.search).get(
      'service_id',
    );
    formRef?.current?.setFieldValue('service_id', subscriptionId);
  }, [formRef, services, location.search]);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="person_id"
          label="Pessoa"
          size="small"
          options={persons.map((person) => ({
            key: person.id,
            label: person.name,
            value: person.id,
          }))}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField name="name" label="Nome do Beneficiário" size="small" />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="partner_id"
          label="Parceira"
          size="small"
          options={partners.map((partner) => ({
            key: partner.id,
            label: partner.display_name,
            value: partner.id,
          }))}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="service_id"
          label="Serviço"
          size="small"
          options={services.map((service) => ({
            key: service.id,
            label: `${service.name} (${service.category?.name})`,
            value: service.id,
          }))}
          loading={loading}
        />
      </Grid>

      <Grid item xs={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
