import yup from 'src/libs/yup';

export const PopupStoreSchema = yup.object().shape({
  url: yup.string().label('URL'),
  image: yup.mixed().isFile().label('Imagem'),
  open_when: yup.string().required().label('Exibir Quando'),
  type: yup.string().required().label('Tipo'),
  show_subscribers: yup.boolean().required().label('Exibir para Assinantes'),
  show_collaborators: yup
    .boolean()
    .required()
    .label('Exibir para Colaboradores'),
  subsites_ids: yup.array().of(yup.number()).label('Subsites'),
  description: yup.string().required().label('Descrição'),
});

export const PopupUpdateSchema = yup.object().shape({
  url: yup.string().label('URL'),
  image: yup.mixed().isFile().label('Imagem'),
  open_when: yup.string().required().label('Exibir Quando'),
  type: yup.string().required().label('Tipo'),
  show_subscribers: yup.boolean().required().label('Exibir para Assinantes'),
  show_collaborators: yup
    .boolean()
    .required()
    .label('Exibir para Colaboradores'),
  subsites_ids: yup.array().of(yup.number()).label('Subsites'),
  description: yup.string().required().label('Descrição'),
});
