import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RestoreIcon from '@material-ui/icons/Restore';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import RichText from 'src/components/Form/RichText';
import TextField from 'src/components/Form/TextField';
import { IEditIfbolsasQuestionParams } from 'src/interfaces/forms/ISettingsIfbolsas';
import { ICommonQuestion } from 'src/interfaces/models';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  CommonQuestionStoreSchema,
  CommonQuestionUpdateSchema,
} from 'src/validators/CommonQuestion/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const history = useHistory();
  const params = useParams<IEditIfbolsasQuestionParams>();

  const yupSchema = params.questionId
    ? CommonQuestionUpdateSchema
    : CommonQuestionStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        if (params.questionId) {
          const response = await api.get(
            `admin/common-questions/${params.questionId}`,
          );
          const benefit = response.data as ICommonQuestion;

          setStatus(benefit.status);

          formRef.current?.setData(benefit);
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.questionId, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      // data.website = 'ifbolsas';

      if (params.questionId) {
        await api.put(`/admin/common-questions/${params.questionId}`, data);
      } else {
        await api.post('/admin/common-questions', data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (status === 'active') {
        await api.delete(`/admin/common-questions/${params.questionId}`);
        toast.success('Pergunta desativada!');
      } else {
        await api.post(`/admin/common-questions/${params.questionId}/restore`);
        toast.success('Pergunta restaurada!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar pergunta.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h6">
                {params.questionId ? 'Editar' : 'Nova'} Pergunta
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8} md={10}>
                    <TextField name="title" label="Título" required />
                    <TextField name="website" value="ifbolsas" hidden />
                  </Grid>

                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="position"
                      label="Posição"
                      type="number"
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RichText name="answer" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              {status === 'active' ? (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="secondary"
                  disabled={!params.questionId}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.questionId}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${status === 'active' ? 'Desativar' : 'Restaurar'} pergunta`}
        description="Confirma esta ação?"
        confirmColor={status === 'active' ? 'secondary' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
