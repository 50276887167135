import yup from 'src/libs/yup';

export const CommonQuestionStoreSchema = yup.object().shape({
  title: yup.string().required().label('Título'),
  answer: yup.string().required().label('Resposta'),
  position: yup.number().required().label('Posição'),
  website: yup.string().required(),
});

export const CommonQuestionUpdateSchema = yup.object().shape({
  title: yup.string().required().label('Título'),
  answer: yup.string().required().label('Resposta'),
  position: yup.number().required().label('Posição'),
  website: yup.string().required(),
});
