import yup from 'src/libs/yup';

export const PartnerStoreSchema = yup.object().shape({
  display_name: yup.string().required().label('Nome de Exibição'),
  slug: yup.string().required().label('URL'),
  group: yup.string().label('Grupo'),
  logo: yup.mixed().required().isFile().label('Logo'),
  description: yup.string().label('Descrição'),
  attention_text: yup.string().label('Atenção'),
  solicitation: yup.string().required().label('Solicitação do Usuário'),
  solicitation_email: yup
    .string()
    .when('solicitation', {
      is: 'budget',
      then: yup.string().required(),
    })
    .label('E-mail'),
  // cupon: yup.string().label('Cupom'),
  // link: yup.string().label('Link'),
  allow_subscribers: yup.boolean().required(),
  show_in_beneficiaries: yup
    .mixed()
    .transform((value) => (value !== '' ? value : null))
    .nullable()
    .required(),
  beneficiaries: yup
    .array()
    .when('show_in_beneficiaries', {
      is: 1,
      then: yup.array().required().min(1),
    })
    .label('Beneficiárias'),
  headquarter: yup
    .object()
    .required()
    .shape({
      document: yup.string().required().cpfCnpj().label('CPF / CNPJ'),
      company_name: yup.string().required().label('Nome / Razão Social'),
      fancy_name: yup.string().label('Fantasia'),
      phone: yup.string().label('Telefone'),
      website: yup.string().label('Site'),
      zip_code: yup.string().label('CEP'),
      state: yup.string().max(2).label('UF'),
      city: yup.string().label('Cidade'),
      neighborhood: yup.string().label('Bairro'),
      street: yup.string().label('Rua/Av'),
      number: yup.string().label('Número'),
      complement: yup.string().label('Complemento'),
    })
    .label('Matriz'),
});

export const PartnerUpdateSchema = yup.object().shape({
  display_name: yup.string().required().label('Nome de Exibição'),
  slug: yup.string().required().label('URL'),
  group: yup.string().label('Grupo'),
  logo: yup.mixed().isFile().label('Logo'),
  description: yup.string().label('Descrição'),
  attention_text: yup.string().label('Atenção'),
  solicitation: yup.string().required().label('Solicitação do Usuário'),
  solicitation_email: yup
    .string()
    .when('solicitation', {
      is: 'budget',
      then: yup.string().required(),
    })
    .label('E-mail'),
  cupon: yup.string().label('Cupom'),
  link: yup.string().label('Link'),
  allow_subscribers: yup.boolean().required(),
  show_in_beneficiaries: yup
    .mixed()
    .transform((value) => (value !== '' ? value : null))
    .nullable()
    .required(),
  beneficiaries: yup
    .array()
    .when('show_in_beneficiaries', {
      is: 1,
      then: yup.array().required().min(1),
    })
    .label('Beneficiárias'),
  headquarter: yup
    .object()
    .required()
    .shape({
      document: yup.string().required().cpfCnpj().label('CPF / CNPJ'),
      company_name: yup.string().required().label('Nome / Razão Social'),
      fancy_name: yup.string().label('Fantasia'),
      phone: yup.string().label('Telefone'),
      website: yup.string().label('Site'),
      zip_code: yup.string().label('CEP'),
      state: yup.string().max(2).label('UF'),
      city: yup.string().label('Cidade'),
      neighborhood: yup.string().label('Bairro'),
      street: yup.string().label('Rua/Av'),
      number: yup.string().label('Número'),
      complement: yup.string().label('Complemento'),
    })
    .label('Matriz'),
});
