import React, { useContext } from 'react';
import Chart from 'react-google-charts';

import { Card, CardHeader } from '@material-ui/core';
import DashboardContext from 'src/pages/Private/Dashboard/context';
import { defaultChartOptions } from 'src/utils/charts';

const PersonSubscriptionsChart: React.FC = () => {
  const {
    chartsInfo: { personSubscriptionsMonthIfbolsas },
  } = useContext(DashboardContext);

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        style={{ color: '#2D7EFB' }}
        title="IFBOLSAS"
        subheader="QTDE DE ASSINATURAS"
      />

      <Chart
        chartType="LineChart"
        data={personSubscriptionsMonthIfbolsas}
        options={{
          ...defaultChartOptions,
          curveType: 'function',
        }}
      />
    </Card>
  );
};

export default PersonSubscriptionsChart;
