import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import Container from 'src/components/Container';
import DatePickerOperator from 'src/components/Form/DatePickerOperator';
import { IBeneficiary } from 'src/interfaces/models';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import { objectToQuery } from 'src/utils/helpers';
import { gerenateReport, ReportSheetInfo } from 'src/utils/reports';

const Beneficiaries: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);

      const { data: beneficiaries } = await api.get<IBeneficiary[]>(
        `admin/reports/beneficiaries${objectToQuery(formData)}`,
      );

      if (!beneficiaries.length) {
        return toast.warning('Nenhum dado a ser exportado.');
      }

      // Beneficiaries (headquarter)
      const beneficiarySheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'Beneficiárias',
      };
      const unitiesSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'Unidades',
      };
      const partnersSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'Parceiras',
      };
      const responsiblesSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'Responsáveis',
      };
      const contractsSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'Contratos',
      };
      const subsitesSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'Subsites',
      };

      for (const {
        headquarter,
        classifications,
        unities,
        partners,
        responsibles,
        contracts,
        subsite,
        ...beneficiary
      } of beneficiaries) {
        const headquarterKeys = headquarter ? Object.keys(headquarter) : [];
        headquarterKeys.forEach((key, index, arr) => {
          arr[index] = `headquarter_${key}`;
        });
        if (!beneficiarySheet.columns.length) {
          beneficiarySheet.columns.push(
            ...Object.keys(beneficiary),
            ...headquarterKeys,
            'classifications',
          );
        }

        const headquarterValues = headquarter ? Object.values(headquarter) : [];
        let classificationsData = '';
        for (const classification of classifications || []) {
          if (classificationsData.length > 0) {
            classificationsData = classificationsData.concat('\n');
          }
          classificationsData = classificationsData.concat(classification.name);
        }
        beneficiarySheet.data.push([
          ...Object.values(beneficiary),
          ...headquarterValues,
          classificationsData,
        ]);

        // Unities
        if (unities?.length) {
          for (const unity of unities) {
            if (!unitiesSheet.columns.length) {
              const columns = Object.keys(unity);
              columns.splice(2, 0, 'beneficiary_name');
              unitiesSheet.columns = columns;
            }
            const data = Object.values(unity);
            data.splice(2, 0, beneficiary.display_name);
            unitiesSheet.data.push(data);
          }
        }
        // Partners
        if (partners?.length) {
          for (const partner of partners) {
            if (!partnersSheet.columns.length) {
              const columns = Object.keys(partner);
              columns.splice(1, 0, 'beneficiary_id');
              columns.splice(2, 0, 'beneficiary_name');
              partnersSheet.columns = columns;
            }
            const data = Object.values(partner);
            data.splice(1, 0, beneficiary.id);
            data.splice(2, 0, beneficiary.display_name);
            partnersSheet.data.push(data);
          }
        }

        // Responsibles
        if (responsibles?.length) {
          for (const { contacts, unities, ...responsible } of responsibles) {
            if (!responsiblesSheet.columns.length) {
              const columns = Object.keys(responsible);
              columns.splice(2, 0, 'beneficiary_name');
              columns.push('contacts');
              columns.push('unities');
              responsiblesSheet.columns = columns;
            }

            const data = Object.values(responsible);
            data.splice(2, 0, beneficiary.display_name);
            let contactsData = '';
            for (const contact of contacts || []) {
              if (contactsData.length > 0) {
                contactsData = contactsData.concat('\n');
              }
              contactsData = contactsData.concat(
                `${contact.contact_type}: ${contact.contact_value}`,
              );
            }
            data.push(contactsData);
            let unitiesData = '';
            for (const unity of unities || []) {
              if (unitiesData.length > 0) {
                unitiesData = unitiesData.concat('\n');
              }
              unitiesData = unitiesData.concat(unity.display_name);
            }
            data.push(unitiesData);
            responsiblesSheet.data.push(data);
          }
        }

        // Contracts
        for (const contract of contracts || []) {
          if (!contractsSheet.columns.length) {
            const columns = Object.keys(contract);
            columns.splice(2, 0, 'beneficiary_name');
            contractsSheet.columns = columns;
          }
          const data = Object.values(contract);
          data.splice(2, 0, beneficiary.display_name);
          contractsSheet.data.push(data);
        }

        // Subsites
        if (subsite) {
          if (!subsitesSheet.columns.length) {
            const columns = Object.keys(subsite);
            columns.splice(2, 0, 'beneficiary_name');
            subsitesSheet.columns = columns;
          }
          const data = Object.values(subsite);
          data.splice(2, 0, beneficiary.display_name);
          subsitesSheet.data.push(data);
        }
      }

      gerenateReport({
        sheets: [
          beneficiarySheet,
          unitiesSheet,
          partnersSheet,
          responsiblesSheet,
          contractsSheet,
          subsitesSheet,
        ],
        fileName: 'relatorio-beneficiarias.xlsx',
      });
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao gerar relatório.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Relatório de Beneficiárias</Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Form ref={formRef} onSubmit={handleSubmit} noValidate>
              <CardHeader subheader="Filtros" />

              <CardContent>
                <DatePickerOperator
                  name="created_at"
                  label="Data de Cadastro"
                />
              </CardContent>

              <CardActions style={{ justifyContent: 'flex-end' }}>
                <Button
                  startIcon={<CheckIcon />}
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                >
                  Gerar Relatório
                </Button>
              </CardActions>
            </Form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Beneficiaries;
