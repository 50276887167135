import yup from 'src/libs/yup';

export const BeneficiaryResponsibleStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  document: yup.string().label('CPF'),
  email: yup.string().label('E-mail'),
  phone: yup.string().label('Telefone'),
  department: yup.string().label('Departamento'),
  legal: yup.boolean(),
  finance: yup.boolean(),
  agreement: yup.boolean(),
  disclosure: yup.boolean(),
  confirmation: yup.boolean(),
  contacts: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      contact_type: yup.string().required().label('Tipo'),
      contact_value: yup.string().required().label('Contato'),
    }),
  ),
  unities: yup.array().of(yup.number()),
});

export const BeneficiaryResponsibleUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  document: yup.string().label('CPF'),
  email: yup.string().label('E-mail'),
  phone: yup.string().label('Telefone'),
  department: yup.string().label('Departamento'),
  legal: yup.boolean(),
  finance: yup.boolean(),
  agreement: yup.boolean(),
  disclosure: yup.boolean(),
  confirmation: yup.boolean(),
  contacts: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      contact_type: yup.string().required().label('Tipo'),
      contact_value: yup.string().required().label('Contato'),
    }),
  ),
  unities: yup.array().of(yup.number()),
});
