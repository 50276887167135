import { FC, useState, useContext, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import RestoreIcon from '@material-ui/icons/Restore';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import CheckBox from 'src/components/Form/Checkbox';
import InputFile from 'src/components/Form/InputFile';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IBeneficiary } from 'src/interfaces/models';
import IPopup from 'src/interfaces/models/IPopup';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import { objectToFormData, objectToQuery } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import {
  PopupStoreSchema,
  PopupUpdateSchema,
} from 'src/validators/Popup/save.schema';

import { Container } from './styles';

const openwhenItems: Item[] = [
  { key: 1, label: 'Sair da Página', value: 'leave' },
  { key: 2, label: 'Abrir a Página', value: 'load' },
];
const typeItems: Item[] = [
  { key: 1, label: 'Exibição Padrão', value: 'default' },
  { key: 2, label: 'Captura de Lead', value: 'lead' },
];

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [popup, setPopup] = useState<IPopup>();
  const params = useParams<IEditParams>();
  const history = useHistory();
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [beneficiaries, setBeneficiaries] = useState<IBeneficiary[]>([]);
  const [loading, setLoading] = useState(false);

  const yupSchema = params.id ? PopupUpdateSchema : PopupStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const queryParams = objectToQuery({
          has_subsite: true,
          with_subsite: true,
          order_by: [{ column: 'display_name', direction: 'asc' }],
        });
        const { data } = await api.get<IBeneficiary[]>(
          `admin/beneficiaries${queryParams}`,
        );
        setBeneficiaries(data);

        if (params.id) {
          const response = await api.get(`admin/popups/${params.id}`);
          const popup = response.data as IPopup;

          setPopup(popup);
          formRef.current?.setData({
            ...popup,
            subsites_ids: popup.subsites?.map((subsite) => subsite.id),
          });
        }
      } catch (error) {
        handleApiResponseErrors(
          error.response,
          'Erro ao buscar dados.',
          yupSchema,
          formRef,
        );
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading, yupSchema]);

  const handleOnSubmit: SubmitHandler = async (unformData) => {
    try {
      startLayoutLoading();
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        yupSchema,
        unformData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const formData = objectToFormData(data);

      if (params.id) {
        await api.put(`/admin/popups/${params.id}`, formData);
      } else {
        const createResponse = await api.post('/admin/popups', formData);
        history.replace(`/popups/${createResponse.data.id}`);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro');
    } finally {
      stopLayoutLoading();
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (popup?.status === 'active') {
        const isDelete = confirmDialogRef.current?.isDelete();
        let deleteConfig = {};
        if (isDelete) {
          deleteConfig = { data: { delete: true } };
        }

        await api.delete(`/admin/popups/${params.id}`, deleteConfig);

        toast.success(`Popup ${isDelete ? 'excluido' : 'desativado'}!`);
      } else {
        await api.post(`/admin/popups/${params.id}/restore`);
        toast.success('Popup restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao desativar popup.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Novo'} Popup
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={8} sm={4}>
                    <InputFile
                      name="image"
                      accept="image/*"
                      label="Imagem do Popup"
                    />
                  </Grid>
                  <Grid item xs={8} sm={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Select
                          name="open_when"
                          label="Exibir quando"
                          items={openwhenItems}
                          required
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="description"
                          label="Descrição"
                          required
                        />
                      </Grid>

                      <CheckBox
                        name="show_collaborators"
                        label="Exibir no IFEPAF"
                      />
                      <CheckBox
                        name="show_subscribers"
                        label="Exibir no IFBOLSAS"
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Select
                          name="type"
                          label="Tipo de Exibição"
                          items={typeItems}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="url"
                          label="Link"
                          placeholder="https://site.com.br"
                          value={null}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      name="subsites_ids"
                      label="Subsites"
                      multiple
                      options={beneficiaries.map((beneficiary) => ({
                        key: beneficiary.id,
                        label: `${beneficiary.display_name} (${beneficiary.subsite?.slug})`,
                        value: beneficiary.subsite?.id,
                      }))}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              {popup?.status === 'active' ? (
                <Button
                  type="button"
                  loading={loading}
                  // startIcon={<CloseIcon />}
                  variant="contained"
                  color="secondary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}
              <Button
                type="submit"
                loading={layoutLoading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${
          popup?.status === 'active' ? 'Desativar' : 'Restaurar'
        } popup`}
        description="Confirma esta ação?"
        confirmColor={popup?.status === 'active' ? 'secondary' : 'primary'}
        onConfirm={handleConfirm}
        canDelete={popup?.status === 'active'}
      />
    </Container>
  );
};

export default Save;
