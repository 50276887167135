import * as Beneficiaries from './Beneficiaries';
import * as BeneficiaryUsers from './BeneficiaryUsers';
import * as Benefits from './Benefits';
import * as Categories from './Categories';
import * as Classifications from './Classifications';
import * as Collaborators from './Collaborators';
import Dashboard from './Dashboard';
import * as Leads from './Leads';
import * as Notices from './Notices';
import * as Partners from './Partners';
import * as PersonBonus from './PersonBonus';
import * as Persons from './Persons';
import * as PersonScholarships from './PersonScholarships';
import * as PersonSubscriptions from './PersonSubscriptions';
import * as Popups from './Popups';
import Profile from './Profile';
import * as Reports from './Reports';
import * as Services from './Services';
import * as Settings from './Settings';
import * as Subscriptions from './Subscriptions';
import * as TeachForms from './TeachForms';
import * as Users from './Users';

export default {
  Dashboard,
  Users,
  Profile,
  Benefits,
  Categories,
  Services,
  Settings,
  TeachForms,
  Subscriptions,
  Partners,
  Beneficiaries,
  Leads,
  Persons,
  Collaborators,
  PersonSubscriptions,
  PersonScholarships,
  PersonBonus,
  Classifications,
  Notices,
  Reports,
  Popups,
  BeneficiaryUsers,
};
