import { FC } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckBox from 'src/components/Form/Checkbox';
import Select from 'src/components/Form/Select';
import TextField from 'src/components/Form/TextField';

const statusItems = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const Filters: FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography>Responsável</Typography>
      </Grid>
      <Grid item xs={12}>
        <CheckBox name="legal" label="Legal" size="small" />
        <CheckBox name="finance" label="Financeiro" size="small" />
        <CheckBox name="agreement" label="Convênio" size="small" />
        <CheckBox name="disclosure" label="Divulgação" size="small" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name="document"
          label="CPF"
          size="small"
          mask="cpf"
          returnUnmasked
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name="department" label="Departamento" size="small" />
      </Grid>

      <Grid item xs={12}>
        <TextField name="name" label="Nome" size="small" />
      </Grid>

      <Grid item xs={12}>
        <TextField name="email" label="E-mail" size="small" type="email" />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="phone"
          label="Telefone"
          type="tel"
          size="small"
          mask="phoneOrCellphone"
          returnUnmasked
        />
      </Grid>

      <Grid item xs={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
