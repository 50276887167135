import yup from 'src/libs/yup';

export const PersonBonusAdminStoreSchema = yup.object().shape({
  person_subscription_id: yup.number().required().label('Assinatura da Pessoa'),
  partner_service_id: yup.number().required().label('Serviço da Parceira'),
  name: yup.string().required().label('Nome'),
  document: yup.string().cpf().required().label('CPF'),
  // birth_date: yup
  //   .string()
  //   .required()
  //   .dateFormat('dd/MM/yyyy')
  //   .label('Nascimento'),
});

export const PersonBonusAdminUpdateSchema = yup.object().shape({
  partner_service_id: yup.number().required().label('Serviço da Parceira'),
  name: yup.string().required().label('Nome'),
  document: yup.string().cpf().required().label('CPF'),
  created_at: yup.date().label('Data Emissão'),
  // birth_date: yup
  //   .string()
  //   .required()
  //   .dateFormat('dd/MM/yyyy')
  //   .label('Nascimento'),
});
