import yup from 'src/libs/yup';

export const ServiceStoreSchema = yup.object().shape({
  category_id: yup.number().nullable().required().label('Categoria'),
  name: yup.string().required().label('Nome'),
  slug: yup.string().required().label('URL'),
  description: yup.string().label('Descrição'),
  icon_family: yup.string().label('Família do Ícone'),
  icon_name: yup.string().label('Ícone'),
});

export const ServiceUpdateSchema = yup.object().shape({
  category_id: yup.number().nullable().required().label('Categoria'),
  name: yup.string().required().label('Nome'),
  slug: yup.string().required().label('URL'),
  description: yup.string().label('Descrição'),
  icon_family: yup.string().label('Família do Ícone'),
  icon_name: yup.string().label('Ícone'),
});
