import { FC } from 'react';

import EditIcon from '@material-ui/icons/Edit';
import LinkIconButton from 'src/components/LinkIconButton';
import { LinkButtonProps } from 'src/components/LinkIconButton/interfaces';

const EditButton: FC<LinkButtonProps> = (props) => {
  return (
    <LinkIconButton size="small" color="primary" {...props}>
      <EditIcon fontSize="inherit" />
    </LinkIconButton>
  );
};

export default EditButton;
