import yup from 'src/libs/yup';

export const CategoryStoreSchema = yup.object().shape({
  image: yup.mixed().required().isFile().label('Imagem'),
  benefit_id: yup.number().nullable().required().label('Benefício'),
  name: yup.string().required().label('Nome'),
  slug: yup.string().required().label('URL'),
  description: yup.string().label('Descrição'),
  icon_family: yup.string().label('Família do Ícone'),
  icon_name: yup.string().label('Ícone'),
  fill_course: yup.boolean().required().label('Preencher Curso'),
  show_home: yup.boolean().required().label('Mostrar na Home'),
  period: yup.string().nullable().label('Período'),
  order_position: yup.string().required().label('Ordem'),
});

export const CategoryUpdateSchema = yup.object().shape({
  image: yup.mixed().isFile().label('Imagem'),
  name: yup.string().required().label('Nome'),
  slug: yup.string().required().label('URL'),
  description: yup.string().label('Descrição'),
  icon_family: yup.string().label('Família do Ícone'),
  icon_name: yup.string().label('Ícone'),
  fill_course: yup.boolean().required().label('Preencher Curso'),
  show_home: yup.boolean().required().label('Mostrar na Home'),
  period: yup.string().nullable().label('Período'),
  order_position: yup.string().required().label('Ordem'),
});
