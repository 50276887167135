import yup from 'src/libs/yup';

const CollaboratorBonusFilterSchema = yup.object().shape({
  person_id: yup.string().nullable().label('Pessoa'),
  beneficiary_id: yup.string().nullable().label('Beneficiária'),
  beneficiary_unity_id: yup.string().nullable().label('Unidade'),
  partner_id: yup.string().nullable().label('Parceira'),
  service_id: yup.string().nullable().label('Serviço'),
  status: yup.string().nullable().label('Status'),
});

export default CollaboratorBonusFilterSchema;
