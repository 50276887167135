import React, { useContext } from 'react';
import Chart from 'react-google-charts';

import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import DashboardContext from 'src/pages/Private/Dashboard/context';
import { defaultChartOptions } from 'src/utils/charts';

const BeneficiaryPersonsChart: React.FC = () => {
  const {
    chartsInfo: { personsByBeneficiary },
  } = useContext(DashboardContext);

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        style={{ color: '#01CB98' }}
        title="IFEPAF"
        subheader="PESSOAS CADASTRADAS POR BENEFICIÁRIA"
      />

      <Grid container>
        <Grid item xs={6}>
          <Chart
            chartType="PieChart"
            data={personsByBeneficiary?.previous.data}
            options={{
              ...defaultChartOptions,
              title: personsByBeneficiary?.previous.title,
              legend: 'none',
              pieSliceText: 'label',
              colors: ['#616161', '#7D7D7D', '#949494', '#A5A5A5', '#BABABA'],
            }}
          />

          <Grid container justifyContent="center" style={{ paddingBottom: 10 }}>
            <Typography variant="caption">
              <strong>Total: {personsByBeneficiary?.previous.total}</strong>
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Chart
            chartType="PieChart"
            data={personsByBeneficiary?.current.data}
            options={{
              ...defaultChartOptions,
              title: personsByBeneficiary?.current.title,
              legend: 'none',
              pieSliceText: 'label',
            }}
          />

          <Grid container justifyContent="center" style={{ paddingBottom: 10 }}>
            <Typography variant="caption">
              <strong>Total: {personsByBeneficiary?.current.total}</strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BeneficiaryPersonsChart;
