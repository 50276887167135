import { IRouteInterface } from 'src/interfaces/IRoutes';

import AuthPages from '../pages/Auth';
import PrivatePages from '../pages/Private';
import PublicPages from '../pages/Public';

export const authRoutes: IRouteInterface[] = [
  {
    exact: true,
    component: AuthPages.Login,
    label: 'Login',
    path: '/',
    type: 'auth',
  },
  {
    component: AuthPages.ForgotPassword,
    exact: true,
    label: 'Esqueci minha senha',
    path: '/esqueci-minha-senha',
    type: 'auth',
  },
  {
    component: AuthPages.RecoverPassword,
    label: 'Recuperar senha',
    path: '/recuperar-senha/:token',
    type: 'auth',
  },
];

export const privateRoutes: IRouteInterface[] = [
  {
    exact: true,
    component: PrivatePages.Dashboard,
    label: 'Início',
    path: '/dashboard',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Profile,
    label: 'Perfil',
    path: '/perfil',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Users.List,
    label: 'Usuários',
    path: '/usuarios',
    type: 'private',
  },
  {
    component: PrivatePages.Users.Save,
    label: 'Novo Usuário',
    path: '/usuarios/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Users.Save,
    label: 'Editar Usuário',
    path: '/usuarios/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Benefits.List,
    label: 'Benefícios',
    path: '/beneficios',
    type: 'private',
  },
  {
    component: PrivatePages.Benefits.Save,
    label: 'Novo Benefício',
    path: '/beneficios/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Benefits.Save,
    label: 'Editar Benefício',
    path: '/beneficios/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Categories.List,
    label: 'Categorias',
    path: '/categorias',
    type: 'private',
  },
  {
    component: PrivatePages.Categories.Save,
    label: 'Nova Categoria',
    path: '/categorias/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Categories.Save,
    label: 'Editar Categoria',
    path: '/categorias/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Services.List,
    label: 'Serviços',
    path: '/servicos',
    type: 'private',
  },
  {
    component: PrivatePages.Services.Save,
    label: 'Novo Serviço',
    path: '/servicos/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Services.Save,
    label: 'Editar Serviço',
    path: '/servicos/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.TeachForms.List,
    label: 'Formas de Ensino',
    path: '/formas-de-ensino',
    type: 'private',
  },
  {
    component: PrivatePages.TeachForms.Save,
    label: 'Nova Forma de Ensino',
    path: '/formas-de-ensino/novo',
    type: 'private',
  },
  {
    component: PrivatePages.TeachForms.Save,
    label: 'Editar Forma de Ensino',
    path: '/formas-de-ensino/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Subscriptions.List,
    label: 'Planos',
    path: '/planos',
    type: 'private',
  },
  {
    component: PrivatePages.Subscriptions.Save,
    label: 'Novo Plano',
    path: '/planos/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Subscriptions.Save,
    label: 'Editar Plano',
    path: '/planos/:id',
    type: 'private',
  },
  {
    component: PrivatePages.Settings.Ifbolsas,
    label: 'Configurações IFBolsas',
    path: '/configuracoes/ifbolsas/:selectedTab?',
    type: 'private',
  },
  {
    component: PrivatePages.Settings.Ifepaf,
    label: 'Configurações IFEPAF',
    path: '/configuracoes/ifepaf/:selectedTab?',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Partners.List,
    label: 'Parceiras',
    path: '/parceiras',
    type: 'private',
  },
  {
    component: PrivatePages.Partners.Save,
    label: 'Nova Parceira',
    path: '/parceiras/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Partners.Save,
    label: 'Editar Parceira',
    path: '/parceiras/:id/:selectedTab?',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Beneficiaries.List,
    label: 'Beneficiárias',
    path: '/beneficiarias',
    type: 'private',
  },
  {
    component: PrivatePages.Beneficiaries.Save,
    label: 'Nova Beneficiária',
    path: '/beneficiarias/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Beneficiaries.Save,
    label: 'Editar Beneficiária',
    path: '/beneficiarias/:id/:selectedTab?',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Leads.List,
    label: 'Leads',
    path: '/leads',
    type: 'private',
  },
  {
    component: PrivatePages.Leads.Save,
    label: 'Novo Lead',
    path: '/leads/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Leads.Save,
    label: 'Editar Lead',
    path: '/leads/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Persons.List,
    label: 'Pessoas',
    path: '/pessoas',
    type: 'private',
  },
  {
    component: PrivatePages.Persons.Save,
    label: 'Nova Pessoa',
    path: '/pessoas/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Persons.Save,
    label: 'Editar Pessoa',
    path: '/pessoas/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Collaborators.Scholarships.List,
    label: 'Bolsas de Colaboradores',
    path: '/colaboradores/bolsas',
    type: 'private',
  },
  {
    component: PrivatePages.Collaborators.Scholarships.Save,
    label: 'Nova Bolsa para Colaborador',
    path: '/colaboradores/bolsas/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Collaborators.Scholarships.Save,
    label: 'Editar Bolsa do Colaborador',
    path: '/colaboradores/bolsas/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Collaborators.Bonus.List,
    label: 'Bonus de Colaboradores',
    path: '/colaboradores/bonus',
    type: 'private',
  },
  {
    component: PrivatePages.Collaborators.Bonus.Save,
    label: 'Novo Bonus para Colaborador',
    path: '/colaboradores/bonus/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Collaborators.Bonus.Save,
    label: 'Editar Bonus do Colaborador',
    path: '/colaboradores/bonus/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.PersonSubscriptions.List,
    label: 'Assinaturas',
    path: '/assinaturas',
    type: 'private',
  },
  {
    component: PrivatePages.PersonSubscriptions.Save,
    label: 'Nova Assinatura',
    path: '/assinaturas/novo',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.PersonScholarships.List,
    label: 'Bolsas de Assinantes',
    path: '/assinantes/bolsas',
    type: 'private',
  },
  {
    component: PrivatePages.PersonScholarships.Save,
    label: 'Nova Bolsa para Assinante',
    path: '/assinantes/bolsas/novo',
    type: 'private',
  },
  {
    component: PrivatePages.PersonScholarships.Save,
    label: 'Editar Bolsa do Assinante',
    path: '/assinantes/bolsas/:id/:selectedTab?',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.PersonBonus.List,
    label: 'Bonus de Assinantes',
    path: '/assinantes/bonus',
    type: 'private',
  },
  {
    component: PrivatePages.PersonBonus.Save,
    label: 'Novo Bonus para Assinante',
    path: '/assinantes/bonus/novo',
    type: 'private',
  },
  {
    component: PrivatePages.PersonBonus.Save,
    label: 'Editar Bonus do Assinante',
    path: '/assinantes/bonus/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Classifications.List,
    label: 'Classificações',
    path: '/classificacoes',
    type: 'private',
  },
  {
    component: PrivatePages.Classifications.Save,
    label: 'Nova Classificação',
    path: '/classificacoes/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Classifications.Save,
    label: 'Editar Classificação',
    path: '/classificacoes/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Notices.List,
    label: 'Notícias',
    path: '/noticias',
    type: 'private',
  },
  {
    component: PrivatePages.Notices.Save,
    label: 'Nova Notícia',
    path: '/noticias/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Notices.Save,
    label: 'Editar Notícia',
    path: '/noticias/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Reports.Partners,
    label: 'Relatório de Parceiras',
    path: '/relatorios/parceiras',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Reports.Persons,
    label: 'Relatório de Pessoas',
    path: '/relatorios/pessoas',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Reports.Beneficiaries,
    label: 'Relatório de Beneficiárias',
    path: '/relatorios/beneficiarias',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Reports.ScholarshipsAndBunuses,
    label: 'Relatório de Bolsas & Bônus',
    path: '/relatorios/bolsas-bonus',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Popups.List,
    label: 'Popups',
    path: '/popups',
    type: 'private',
  },
  {
    component: PrivatePages.Popups.Save,
    label: 'Novo Popup',
    path: '/popups/novo',
    type: 'private',
  },
  {
    component: PrivatePages.Popups.Save,
    label: 'Editar Popup',
    path: '/popups/:id',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.BeneficiaryUsers.List,
    label: 'Usuários das Beneficiárias',
    path: '/usuarios-beneficiarias',
    type: 'private',
  },
  {
    component: PrivatePages.BeneficiaryUsers.Save,
    label: 'Novo Usuário da Beneficiaria',
    path: '/usuarios-beneficiarias/novo',
    type: 'private',
  },
  {
    component: PrivatePages.BeneficiaryUsers.Save,
    label: 'Editar Usuário da Beneficiaria',
    path: '/usuarios-beneficiarias/:id',
    type: 'private',
  },
];

export const publicRoutes: IRouteInterface[] = [
  {
    component: PublicPages.NotFound,
    label: 'Página não encontrada',
    path: '*',
    type: 'public',
  },
];

export default [...authRoutes, ...privateRoutes, ...publicRoutes];
