import yup from 'src/libs/yup';

export const BeneficiarySubsiteUpdateSchema = yup.object().shape({
  color1: yup.string().required().label('Cor Primária'),
  color2: yup.string().required().label('Cor Secundária'),
  contrast_color1: yup.string().required().label('Cor de Contraste Primária'),
  contrast_color2: yup.string().required().label('Cor de Contraste Secundária'),
  logo: yup.mixed().isFile().label('Logo'),
  slug: yup.string().max(255).required().label('URL'),
  cover: yup.mixed().isFile().label('Capa'),
  title: yup.string().max(255).required().label('Título'),
  subtitle: yup.string().max(255).required().label('Subtítulo'),
  discount_image: yup.mixed().isFile().label('Imagem'),
  discount_description: yup.string().max(255).label('Descrição'),
  howitworks1_enabled: yup.string().required().label('Ativo'),
  howitworks1_title: yup
    .string()
    .max(255)
    .when('howitworks1_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Título'),
  howitworks1_description: yup
    .string()
    .max(255)
    .when('howitworks1_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Descrição'),
  howitworks2_enabled: yup.string().required().label('Ativo'),
  howitworks2_title: yup
    .string()
    .max(255)
    .when('howitworks2_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Título'),
  howitworks2_description: yup
    .string()
    .max(255)
    .when('howitworks2_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Descrição'),
  howitworks3_enabled: yup.string().required().label('Ativo'),
  howitworks3_title: yup
    .string()
    .max(255)
    .when('howitworks3_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Título'),
  howitworks3_description: yup
    .string()
    .max(255)
    .when('howitworks3_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Descrição'),
  howitworks4_enabled: yup.string().required().label('Ativo'),
  howitworks4_title: yup
    .string()
    .max(255)
    .when('howitworks4_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Título'),
  howitworks4_description: yup
    .string()
    .max(255)
    .when('howitworks4_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Descrição'),
  advantage1_enabled: yup.string().required().label('Ativo'),
  advantage1_title: yup
    .string()
    .max(255)
    .when('advantage1_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Título'),
  advantage1_description: yup
    .string()
    .max(255)
    .when('advantage1_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Descrição'),
  advantage1_button_text: yup
    .string()
    .max(255)
    .when('advantage1_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Texto'),
  advantage1_button_link: yup
    .string()
    .max(255)
    .when('advantage1_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Link'),
  advantage2_enabled: yup.string().required().label('Ativo'),
  advantage2_title: yup
    .string()
    .max(255)
    .when('advantage2_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Título'),
  advantage2_description: yup
    .string()
    .max(255)
    .when('advantage2_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Descrição'),
  advantage2_button_text: yup
    .string()
    .max(255)
    .when('advantage2_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Texto'),
  advantage2_button_link: yup
    .string()
    .max(255)
    .when('advantage2_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Link'),
  advantage3_enabled: yup.string().required().label('Ativo'),
  advantage3_title: yup
    .string()
    .max(255)
    .when('advantage3_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Título'),
  advantage3_description: yup
    .string()
    .max(255)
    .when('advantage3_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Descrição'),
  advantage3_button_text: yup
    .string()
    .max(255)
    .when('advantage3_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Texto'),
  advantage3_button_link: yup
    .string()
    .max(255)
    .when('advantage3_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Link'),
  advantage4_enabled: yup.string().required().label('Ativo'),
  advantage4_title: yup
    .string()
    .max(255)
    .when('advantage4_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Título'),
  advantage4_description: yup
    .string()
    .max(255)
    .when('advantage4_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Descrição'),
  advantage4_button_text: yup
    .string()
    .max(255)
    .when('advantage4_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Texto'),
  advantage4_button_link: yup
    .string()
    .max(255)
    .when('advantage4_enabled', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Link'),
  show_website_link: yup.boolean().required().label('Ativo'),
  website_link: yup
    .string()
    .when('show_website_link', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('URL do site da beneficiária'),
  show_beneficiary_contacts: yup.boolean(),
  hide_ifepaf_contacts: yup.boolean(),
  contacts_phone1: yup.string().max(255).label('Telefone 1'),
  contacts_phone2: yup.string().max(255).label('Telefone 2'),
  contacts_email1: yup.string().max(255).email().label('E-mail 1'),
  contacts_email2: yup.string().max(255).email().label('E-mail 2'),
  contacts_icon_phone1: yup.string().max(255).label('Icone do telefone 1'),
  contacts_icon_phone2: yup.string().max(255).label('Icone do telefone 2'),
});
