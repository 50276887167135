import { FC, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import RichText from 'src/components/Form/RichText';
import TextField from 'src/components/Form/TextField';
import { ISettings } from 'src/interfaces/models';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import { SettingsUpdateSchema } from 'src/validators/Settings/save.schema';

import { Container } from './styles';

const General: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const response = await api.get(`admin/settings`, {
          params: {
            website: 'ifepaf',
          },
        });
        const settings = response.data as ISettings;

        formRef.current?.setData(settings);
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        SettingsUpdateSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      await api.put('/admin/settings', data);

      toast.success('Dados salvos com sucesso!');
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro',
        SettingsUpdateSchema,
        formRef,
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <TextField name="website" value="ifepaf" hidden />

                  <Grid item xs={12} sm={6}>
                    <TextField name="site_name" label="Nome do Site" required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="email"
                      label="E-mail"
                      type="email"
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="phone_number"
                      label="Telefone"
                      mask="phoneOrCellphone"
                      returnUnmasked
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="whatsapp_number"
                      label="Whatsapp"
                      mask="phoneOrCellphone"
                      returnUnmasked
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader subheader="Termos de Uso" />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <RichText name="terms_of_use" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader subheader="Política de Privacidade" />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <RichText name="privacy_policy" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="flex-end">
              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default General;
