import React, { useContext } from 'react';
import Chart from 'react-google-charts';

import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import DashboardContext from 'src/pages/Private/Dashboard/context';
import { defaultChartOptions } from 'src/utils/charts';

const PersonPlanSubscriptionsChart: React.FC = () => {
  const {
    chartsInfo: { personSubscriptionsIfbolsas },
  } = useContext(DashboardContext);

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        style={{ color: '#2D7EFB' }}
        title="IFBOLSAS"
        subheader="PLANOS UTILIZADOS"
      />

      <Grid container>
        <Grid item xs={6}>
          <Chart
            chartType="PieChart"
            data={personSubscriptionsIfbolsas?.previous.data}
            options={{
              ...defaultChartOptions,
              title: personSubscriptionsIfbolsas?.previous.title,
              legend: 'none',
              pieSliceText: 'label',
              colors: ['#616161', '#7D7D7D', '#949494', '#A5A5A5', '#BABABA'],
            }}
          />

          <Grid container justifyContent="center" style={{ paddingBottom: 10 }}>
            <Typography variant="caption">
              <strong>
                Total: {personSubscriptionsIfbolsas?.previous.total}
              </strong>
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Chart
            chartType="PieChart"
            data={personSubscriptionsIfbolsas?.current.data}
            options={{
              ...defaultChartOptions,
              title: personSubscriptionsIfbolsas?.current.title,
              legend: 'none',
              pieSliceText: 'label',
            }}
          />

          <Grid container justifyContent="center" style={{ paddingBottom: 10 }}>
            <Typography variant="caption">
              <strong>
                Total: {personSubscriptionsIfbolsas?.current.total}
              </strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PersonPlanSubscriptionsChart;
