import React from 'react';

import { ContainerProps } from '@material-ui/core/Container';

import { CustomContainer } from './styles';

const Container: React.FC<ContainerProps> = ({ children, ...props }) => {
  return <CustomContainer {...props}>{children}</CustomContainer>;
};

export default Container;
