import { createContext } from 'react';

import { IBeneficiary, IPartner } from 'src/interfaces/models';

export interface IDashboardContext {
  chartsInfo: {
    benefitsSolicitations?: Array<any[]>;
    benefitsSolicitationsIfbolsas?: Array<any[]>;
    benefitsSolicitationsIfepaf?: Array<any[]>;
    partnersSolicitations?: Array<any[]>;
    partnersSolicitationsIfbolsas?: Array<any[]>;
    partnersSolicitationsIfepaf?: Array<any[]>;
    personSubscriptionsIfbolsas?: {
      current: { title: string; data: Array<any[]>; total: number };
      previous: { title: string; data: Array<any[]>; total: number };
    };
    personSubscriptionsMonthIfbolsas?: Array<any[]>;
    personSubscriptionsTicketIfbolsas?: {
      price_sum: number;
      person_subscriptions_total: number;
      qty_days: number;
    };
    beneficiariesSolicitations?: Array<any[]>;
    categoriesSolicitations?: Array<any[]>;
    categoriesSolicitationsIfepaf?: Array<any[]>;
    categoriesSolicitationsIfbolsas?: Array<any[]>;
    servicesSolicitations?: Array<any[]>;
    servicesSolicitationsIfepaf?: Array<any[]>;
    servicesSolicitationsIfbolsas?: Array<any[]>;
    lastPartners?: IPartner[];
    lastBeneficiaries?: IBeneficiary[];
    registeredPersons?: {
      current: { title: string; data: Array<any[]>; total: number };
      previous: { title: string; data: Array<any[]>; total: number };
    };
    personsByBeneficiary?: {
      current: { title: string; data: Array<any[]>; total: number };
      previous: { title: string; data: Array<any[]>; total: number };
    };
    beneficiariesNotUsed?: IBeneficiary[];
  };
}

const DashboardContext = createContext<IDashboardContext>({
  chartsInfo: {},
});

export default DashboardContext;
