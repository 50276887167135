import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import Autocomplete from 'src/components/Form/Autocomplete';
import { IOption } from 'src/components/Form/Autocomplete/interfaces';
import CheckBox from 'src/components/Form/Checkbox';
import RadioGroup from 'src/components/Form/RadioGroup';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IPerson, ISubscription } from 'src/interfaces/models';
import { ISubscriptionInstallment } from 'src/interfaces/models/ISubscription';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import masks from 'src/utils/masks';
import yupValidate from 'src/utils/yupValidate';
import {
  PersonSubscriptionAdminStoreSchema,
  PersonSubscriptionAdminUpdateSchema,
} from 'src/validators/PersonSubscription/save.schema';

import { Container } from './styles';

const paymentFormOptions: IOption[] = [
  { key: 1, label: 'Gratuito', value: 'free' },
  { key: 2, label: 'Boleto', value: 'bank_slip' },
  { key: 3, label: 'Cartão de Crédito', value: 'credit_card' },
];
const paymentInOptions: IOption[] = [
  { key: 1, label: 'Á Vista', value: 'cash' },
  { key: 2, label: 'Parcelado', value: 'installments' },
];

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [persons, setPersons] = useState<IPerson[]>([]);
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [installments, setInstallments] = useState<ISubscriptionInstallment[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [disablePaymentIn, setDisablePaymentIn] = useState(false);
  const [disableInstallments, setDisableInstallments] = useState(true);
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id
    ? PersonSubscriptionAdminUpdateSchema
    : PersonSubscriptionAdminStoreSchema;

  // Get current year
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const getPersons = api.get<IPerson[]>('/admin/persons');
        const getSubscriptions = api.get<ISubscription[]>(
          '/admin/subscriptions',
          { params: { load_installments: true } },
        );

        const [personsResp, subscriptionsResp] = await Promise.all([
          getPersons,
          getSubscriptions,
        ]);
        setPersons(personsResp.data);
        setSubscriptions(subscriptionsResp.data);

        // if (params.id) {
        //   const response = await api.get<IPersonSubscription>(
        //     `admin/persons-subscriptions/${params.id}`,
        //   );
        //   const personSubscription = response.data;

        //   setStatus(personSubscription.status);
        //   formRef.current?.setData(personSubscription);
        // }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleChangeSubscription = useCallback(async () => {
    try {
      setLoading(true);

      const subscriptionId = formRef.current?.getFieldValue('subscription_id');

      if (subscriptionId) {
        const subscription = subscriptions.find(
          (subs) => subs.id === subscriptionId,
        );
        setInstallments(subscription?.installments || []);
        formRef.current?.setData({
          ...formRef.current.getData(),
          price: subscription?.price,
          qty_scholarships: subscription?.qty_scholarships,
          allow_bonus: subscription?.allow_bonus,
        });
      } else {
        throw new Error('Erro ao setar dados do plano.');
      }
    } catch (error) {
      formRef.current?.setData({
        ...formRef.current.getData(),
        price: '',
        qty_scholarships: '',
        allow_bonus: '',
      });
    } finally {
      setLoading(false);
    }
  }, [subscriptions]);

  const handleChangePaymentForm = useCallback(async () => {
    try {
      const paymentForm = formRef.current?.getFieldValue('payment_form');
      setDisablePaymentIn(false);

      if (['bank_slip', 'free'].includes(paymentForm)) {
        formRef.current?.setFieldValue('payment_in', 'cash');
        setDisablePaymentIn(true);
      }
    } catch (error) {
      formRef.current?.setFieldValue('payment_in', '');
      setDisablePaymentIn(false);
    }
  }, []);

  const handleChangePaymentIn = useCallback((value) => {
    try {
      const disable = !value || value === 'cash';
      setDisableInstallments(disable);

      if (disable) {
        formRef.current?.setFieldValue('subscription_installment_id', '');
      }
    } catch (error) {
      formRef.current?.setFieldValue('subscription_installment_id', '');
      setDisablePaymentIn(true);
    }
  }, []);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.id) {
        await api.put(`/admin/persons-subscriptions/${params.id}`, data);
      } else {
        await api.post('/admin/persons-subscriptions', data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Nova'} Assinatura
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="person_id"
                      label="Pessoa"
                      options={persons.map((person) => ({
                        key: person.id,
                        label: person.name,
                        value: person.id,
                      }))}
                      loading={layoutLoading}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="subscription_id"
                      label="Plano"
                      options={subscriptions.map((subscription) => ({
                        key: subscription.id,
                        label: subscription.name,
                        value: subscription.id,
                      }))}
                      loading={layoutLoading}
                      onChange={handleChangeSubscription}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name="price"
                      label="Preço à Vista"
                      disabled
                      mask="currency"
                      returnUnmasked
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name="qty_scholarships"
                      label="Qtde de Bolsas"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CheckBox
                      name="allow_bonus"
                      label="Permite Bonus"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      name="school_year"
                      label="Ano Letivo"
                      textFieldProps={{ required: true }}
                      options={[
                        {
                          key: 1,
                          label: String(currentYear),
                          value: String(currentYear),
                        },
                        {
                          key: 2,
                          label: String(currentYear + 1),
                          value: String(currentYear + 1),
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Autocomplete
                      name="payment_form"
                      label="Forma de Pagamento"
                      options={paymentFormOptions}
                      onChange={handleChangePaymentForm}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <RadioGroup
                      name="payment_in"
                      row
                      options={paymentInOptions}
                      disabled={disablePaymentIn}
                      onChange={handleChangePaymentIn}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="subscription_installment_id"
                      label="Parcelamento"
                      options={installments.map(({ id, qty, price }) => ({
                        key: id,
                        label: `${qty}x de ${masks.currency(
                          (price / 100 / qty).toFixed(2),
                        )} (R$ ${masks.currency(price.toString())})`,
                        value: id,
                      }))}
                      disabled={disableInstallments}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="flex-end">
              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default Save;
