import { FC, RefObject, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import Autocomplete from 'src/components/Form/Autocomplete';
import CheckBox from 'src/components/Form/Checkbox';
import TextField from 'src/components/Form/TextField';
import IBeneficiary from 'src/interfaces/models/IBeneficiary';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import { objectToQuery, queryToObject } from 'src/utils/helpers';

interface FilterProps {
  formRef?: RefObject<FormHandles>;
}

const Filters: FC<FilterProps> = ({ formRef }) => {
  const [beneficiaries, setBeneficiaries] = useState<IBeneficiary[]>([]);
  const location = useLocation();

  useEffect(() => {
    const loadData = async () => {
      try {
        const params = objectToQuery({
          has_subsite: true,
          with_subsite: true,
          order_by: [{ column: 'name', direction: 'asc' }],
        });
        const { data } = await api.get<IBeneficiary[]>(
          `admin/beneficiaries${params}`,
        );
        setBeneficiaries(data);
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar subsites.');
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (!beneficiaries.length) return;
    const query = new URLSearchParams(location.search);
    const params = queryToObject(query);
    if (params.subsites_ids) {
      formRef?.current?.setFieldValue('subsites_ids', params.subsites_ids);
    }
  }, [beneficiaries, location.search, formRef]);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField name="description" label="Descrição" size="small" />
      </Grid>

      <Grid item xs={12}>
        <TextField name="link" label="Link" size="small" />
      </Grid>

      <Grid item xs={6}>
        <CheckBox name="show_ifbolsas" label="IFBOLSAS" size="small" />
      </Grid>

      <Grid item xs={6}>
        <CheckBox name="show_ifepaf" label="IFEPAF" size="small" />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="subsites_ids"
          label="Subsites"
          size="small"
          multiple
          options={beneficiaries.map((beneficiary) => ({
            key: beneficiary.id,
            label: `${beneficiary.display_name} (${beneficiary.subsite?.slug})`,
            value: beneficiary.subsite?.id,
          }))}
        />
      </Grid>
    </>
  );
};

export default Filters;
