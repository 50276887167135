import yup from 'src/libs/yup';

const BeneficiaryResponsibleFilterSchema = yup.object().shape({
  name: yup.string().label('Nome'),
  document: yup.string().label('CPF'),
  email: yup.string().label('E-mail'),
  phone: yup.string().label('Telefone'),
  department: yup.string().label('Departamento'),
  legal: yup.boolean(),
  finance: yup.boolean(),
  agreement: yup.boolean(),
  disclosure: yup.boolean(),
  confirmation: yup.boolean(),
  status: yup.string().nullable().label('Status'),
});

export default BeneficiaryResponsibleFilterSchema;
