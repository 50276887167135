import { FC, useEffect, useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import { ICategory } from 'src/interfaces/models';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import { objectToQuery } from 'src/utils/helpers';

const statusItems = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const Filters: FC = () => {
  const [categoriesItems, setCategoriesItems] = useState<Item[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const categoriesParams = objectToQuery({
          order_by: [{ column: 'name', direction: 'asc' }],
        });
        const categoryResponse = await api.get(
          `admin/categories${categoriesParams}`,
        );
        const categories = categoryResponse.data as ICategory[];
        setCategoriesItems(
          categories.map((benefit) => ({
            key: benefit.id,
            label: benefit.name,
            value: benefit.id,
          })),
        );
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar categorias.');
      }
    };

    loadData();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Select
          name="category_id"
          label="Categoria"
          items={categoriesItems}
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField name="name" label="Nome" size="small" />
      </Grid>

      <Grid item xs={12}>
        <TextField name="description" label="Descrição" size="small" />
      </Grid>

      <Grid item xs={6}>
        <TextField name="slug" label="URL" size="small" />
      </Grid>

      <Grid item xs={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
