import yup from 'src/libs/yup';

const PersonScholarshipFilterSchema = yup.object().shape({
  person_id: yup.string().nullable().label('Pessoa'),
  name: yup.string().label('Nome'),
  partner_id: yup.string().nullable().label('Parceira'),
  service_id: yup.string().nullable().label('Serviço'),
  teach_form_id: yup.string().nullable().label('Forma de Ensino'),
  status: yup.string().nullable().label('Status'),
});

export default PersonScholarshipFilterSchema;
