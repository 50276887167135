export const translateValues: Record<any, Record<any, string>> = {
  status: {
    pending: 'pendente',
    active: 'ativo',
    inactive: 'desativado',
    rejected: 'rejeitado',
  },
  solicitation: {
    voucher: 'Voucher',
    budget: 'Orçamento',
  },
  show_in_beneficiaries: {
    '-1': 'Todos',
    0: 'Nenhuma',
    1: 'Alguns Subsites',
  },
  payment_form: {
    free: 'Gratuito',
    credit_card: 'Cartão de Crédito',
    bank_slip: 'Boleto',
  },
  payment_in: {
    cash: 'A vista',
    installments: 'Parcelado',
  },
  // geral: {
  //   status: {
  //     pending: 'pendente',
  //     active: 'ativo',
  //     inactive: 'desativado',
  //     rejected: 'rejeitado',
  //   },
  // },
  // Parceiras: {
  //   solicitation: {
  //     voucher: 'Voucher',
  //     budget: 'Orçamento',
  //   },
  //   show_in_beneficiaries: {
  //     '-1': 'Todos',
  //     0: 'Nenhuma',
  //     1: 'Alguns Subsites',
  //   },
  // },
  // 'PF Assinaturas': {
  //   payment_form: {
  //     free: 'Gratuito',
  //     credit_card: 'Cartão de Crédito',
  //     bank_slip: 'Boleto',
  //   },
  //   payment_in: {
  //     cash: 'A vista',
  //     installments: 'Parcelado',
  //   },
  // },
};

export const monetaryColumns: Record<string, string[]> = {
  Serviços: ['discount'],
  'PF Assinaturas': ['price'],
  'PF Bolsas': ['discount'],
  'PF Bônus': ['discount'],
  'PJ Bolsas': ['discount'],
  'PJ Bônus': ['discount'],
};
