import yup from 'src/libs/yup';

const TeachFormFilterSchema = yup.object().shape({
  name: yup.string().label('Nome'),
  description: yup.string().label('Descrição'),
  slug: yup.string().label('URL'),
  status: yup.string().nullable().label('Status'),
});

export default TeachFormFilterSchema;
