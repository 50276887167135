import yup from 'src/libs/yup';

export const CollaboratorBonusAdminStoreSchema = yup.object().shape({
  person_id: yup.number().required().label('Pessoa'),
  beneficiary_id: yup.number().required().label('Beneficiária'),
  beneficiary_unity_id: yup.number().required().label('Unidade'),
  partner_service_id: yup.number().required().label('Serviço da Parceira'),
  name: yup.string().required().label('Nome'),
  document: yup.string().cpf().required().label('CPF'),
  national_identity: yup.string().label('RG'),
  birth_date: yup.string().dateFormat('dd/MM/yyyy').label('Nascimento'),
});

export const CollaboratorBonusAdminUpdateSchema = yup.object().shape({
  person_id: yup.number().required().label('Pessoa'),
  beneficiary_id: yup.number().required().label('Beneficiária'),
  beneficiary_unity_id: yup.number().required().label('Unidade'),
  partner_service_id: yup.number().required().label('Serviço da Parceira'),
  name: yup.string().required().label('Nome'),
  document: yup.string().cpf().required().label('CPF'),
  national_identity: yup.string().label('RG'),
  birth_date: yup.string().dateFormat('dd/MM/yyyy').label('Nascimento'),
  created_at: yup.date().label('Data Emissão'),
});
