import yup from 'src/libs/yup';

export const SettingsUpdateSchema = yup.object().shape({
  website: yup.string().required().label('Website'),
  site_name: yup.string().required().label('Nome do Site'),
  email: yup.string().required().label('E-mail'),
  phone_number: yup.string().required().label('Telefone'),
  whatsapp_number: yup.string().required().label('Whatsapp'),
  terms_of_use: yup.string().required().label('Termos de Uso'),
  privacy_policy: yup.string().required().label('Política de Privacidade'),
});
