import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import { SubmitHandler, FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import CheckBox from 'src/components/Form/Checkbox';
import DatePicker from 'src/components/Form/DatePicker';
import RichText from 'src/components/Form/RichText';
import TextField from 'src/components/Form/TextField';
import { ISaveSubscription } from 'src/interfaces/forms/ISaveSubscription';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IService, ITeachForm } from 'src/interfaces/models';
import ISubscription from 'src/interfaces/models/ISubscription';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import { objectToQuery } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import {
  SubscriptionStoreSchema,
  SubscriptionUpdateSchema,
} from 'src/validators/Subscription/save.schema';

import { Container } from './styles';

interface IInstallmentInput {
  key: number;
  id?: number;
  qty?: number;
  price?: number;
}

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [teachForms, setTeachForms] = useState<ITeachForm[]>([]);
  const [services, setServices] = useState<IService[]>([]);
  const [installments, setInstallments] = useState<IInstallmentInput[]>([]);
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id
    ? SubscriptionUpdateSchema
    : SubscriptionStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const orderParams = objectToQuery({
          order_by: [{ column: 'name', direction: 'asc' }],
        });
        const getTeachForms = api.get<ITeachForm[]>(
          `admin/teach-forms${orderParams}`,
        );
        const getServices = api.get<IService[]>(
          `admin/services${orderParams}&benefit_type=scholarship&load_category=true`,
        );
        const [teachFormsResp, servicesResp] = await Promise.all([
          getTeachForms,
          getServices,
        ]);
        setTeachForms(teachFormsResp.data);
        setServices(servicesResp.data);

        if (params.id) {
          const response = await api.get<ISubscription>(
            `admin/subscriptions/${params.id}`,
          );
          const {
            teach_forms: teachForms,
            services,
            installments,
            ...subscription
          } = response.data;

          setStatus(subscription.status);
          const currentInstallments =
            installments?.map((installment) => ({
              key: installment.id,
              id: installment.id,
              qty: installment.qty,
              price: installment.price,
            })) || [];
          setInstallments(currentInstallments);

          setTimeout(() => {
            formRef.current?.setData({
              ...subscription,
              services: services?.map((service) => service.id) || [],
              teach_forms: teachForms?.map((service) => service.id) || [],
              installments: installments || [],
            });
          });
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler<ISaveSubscription> = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.id) {
        await api.put(`/admin/subscriptions/${params.id}`, data);
      } else {
        await api.post('/admin/subscriptions', data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (status === 'active') {
        await api.delete(`/admin/subscriptions/${params.id}`);
        toast.success('Plano desativado!');
      } else {
        await api.post(`/admin/subscriptions/${params.id}/restore`);
        toast.success('Plano restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar plano.');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveInstallment = (index: number) => {
    setInstallments((state) =>
      state.filter((_, stateIndex) => stateIndex !== index),
    );
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Novo'} Plano
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="vindi_product_id"
                      label="ID do Produto Vindi"
                      type="number"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name="name" label="Nome" required />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField name="slug" label="URL" required />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      name="order_position"
                      label="Ordem"
                      type="number"
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField name="subtitle" label="Subtítulo" />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="message"
                      label="Mensagem"
                      multiline
                      rows={3}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RichText name="description" />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="qty_months"
                      label="Validade da Assinatura (meses)"
                      type="number"
                      inputProps={{ min: 1 }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="qty_scholarships"
                      label="Qtde de Bolsas"
                      type="number"
                      inputProps={{ min: -1 }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Grid
                      container
                      style={{ height: '100%' }}
                      alignItems="center"
                    >
                      <CheckBox
                        name="limit_one_scholarship"
                        label="1 Bolsa por CPF"
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      container
                      style={{ height: '100%' }}
                      alignItems="center"
                    >
                      <CheckBox name="allow_bonus" label="Permite Bonus" />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      name="teach_forms"
                      label="Formas de Ensino"
                      options={teachForms.map((teachForm) => ({
                        key: teachForm.id,
                        label: teachForm.name,
                        value: teachForm.id,
                      }))}
                      multiple
                      disableCloseOnSelect
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      name="services"
                      label="Serviços"
                      options={services.map((service) => ({
                        key: service.id,
                        label: `${service.name} - ${service.category?.name}`,
                        value: service.id,
                      }))}
                      multiple
                      disableCloseOnSelect
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader subheader="Visibilidade" />

              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      name="person_visibility_since"
                      label="Pessoas Cadastradas desde"
                      valueAsDate
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      name="person_visibility_until"
                      label="Pessoas Cadastradas até"
                      valueAsDate
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="price"
                      label="Preço à Vista"
                      mask="currency"
                      returnUnmasked
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Grid
                      container
                      style={{ height: '100%' }}
                      alignItems="center"
                    >
                      <CheckBox name="is_free" label="Plano Gratuito" />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" justify="space-between">
                      <Typography color="textSecondary">Parcelas</Typography>

                      <Button
                        startIcon={<AddIcon />}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() =>
                          setInstallments((state) =>
                            state.concat({ key: Math.random() }),
                          )
                        }
                      >
                        Parcela
                      </Button>
                    </Grid>
                  </Grid>

                  {installments.map((installment, index) => (
                    <Scope
                      key={installment.key}
                      path={`installments[${index}]`}
                    >
                      <TextField name="id" hidden value={installment.id} />
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="qty"
                          label="Nº de Parcelas"
                          type="number"
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="price"
                          label="Preço (Soma de todas as parcelas)"
                          mask="currency"
                          returnUnmasked
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={2} style={{ textAlign: 'center' }}>
                        <IconButton
                          tabIndex="-1"
                          color="secondary"
                          onClick={() => handleRemoveInstallment(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Scope>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              {status === 'active' ? (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="secondary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${status === 'active' ? 'Desativar' : 'Restaurar'} Plano`}
        description="Confirma esta ação?"
        confirmColor={status === 'active' ? 'secondary' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
