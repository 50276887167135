import yup from 'src/libs/yup';

const NoticeFilterSchema = yup.object().shape({
  description: yup.string().label('Descrição'),
  link: yup.string().label('Link'),
  show_ifbolsas: yup.boolean().label('IFBOLSAS'),
  show_ifepaf: yup.boolean().label('IFEPAF'),
  subsites_ids: yup.array().of(yup.number()).label('Subsites'),
});

export default NoticeFilterSchema;
