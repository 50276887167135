import { FC, RefObject, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import Autocomplete from 'src/components/Form/Autocomplete';
import { IOption } from 'src/components/Form/Autocomplete/interfaces';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import { IPerson, ISubscription } from 'src/interfaces/models';
import api from 'src/services/api';

const statusItems: Item[] = [
  { key: 0, label: 'Pendente', value: 'pending' },
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

interface FilterProps {
  formRef?: RefObject<FormHandles>;
}

const Filters: FC<FilterProps> = ({ formRef }) => {
  const [loading, setLoading] = useState(false);
  const [personOptions, setPersonOptions] = useState<IOption[]>([]);
  const [subscriptionOptions, setsubscriptionOptions] = useState<IOption[]>([]);
  const location = useLocation();

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const getPersons = api.get<IPerson[]>('/admin/persons');
        const getSubscriptions = api.get<ISubscription[]>(
          '/admin/subscriptions',
        );

        const [personsResp, subscriptionsResp] = await Promise.all([
          getPersons,
          getSubscriptions,
        ]);

        const persons = personsResp.data.map((person) => ({
          key: person.id,
          label: person.name,
          value: person.id,
        }));
        setPersonOptions(persons);

        const subscriptions = subscriptionsResp.data.map((subscription) => ({
          key: subscription.id,
          label: subscription.name,
          value: subscription.id,
        }));
        setsubscriptionOptions(subscriptions);
      } catch (error) {
        setPersonOptions([]);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const personId = new URLSearchParams(location.search).get('person_id');
    if (personId && formRef) {
      formRef.current?.setFieldValue(
        'person_id',
        personOptions.find((option) => option.value == personId),
      );
    }
  }, [formRef, personOptions, location.search]);

  useEffect(() => {
    const subscriptionId = new URLSearchParams(location.search).get(
      'subscription_id',
    );
    if (subscriptionId && formRef) {
      formRef.current?.setFieldValue(
        'subscription_id',
        subscriptionOptions.find((option) => option.value == subscriptionId),
      );
    }
  }, [formRef, subscriptionOptions, location.search]);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="person_id"
          label="Pessoa"
          size="small"
          options={personOptions}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="subscription_id"
          label="Plano"
          size="small"
          options={subscriptionOptions}
          loading={loading}
        />
      </Grid>

      <Grid item xs={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
