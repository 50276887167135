import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import Container from 'src/components/Container';
import DatePickerOperator from 'src/components/Form/DatePickerOperator';
import {
  ICollaboratorBonus,
  ICollaboratorScholarship,
  IPersonBonusVoucher,
  IPersonScholarship,
} from 'src/interfaces/models/IPerson';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import { objectToQuery } from 'src/utils/helpers';
import { gerenateReport, ReportSheetInfo } from 'src/utils/reports';

type ReportResponse = {
  persons: {
    scholarships: IPersonScholarship[];
    bonuses: IPersonBonusVoucher[];
  };
  collaborators: {
    scholarships: ICollaboratorScholarship[];
    bonuses: ICollaboratorBonus[];
  };
};

const ScholarshipsAndBunuses: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);

      const response = await api.get<ReportResponse>(
        `admin/reports/scholarships-bonuses${objectToQuery(formData)}`,
      );
      const { persons, collaborators } = response.data;

      if (
        !persons.scholarships.length &&
        !persons.bonuses.length &&
        !collaborators.scholarships.length &&
        !collaborators.bonuses.length
      ) {
        return toast.warning('Nenhum dado a ser exportado.');
      }

      const personScholarshipsSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'PF Bolsas',
      };
      const personBonusesSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'PF Bônus',
      };
      const collaboratorScholarshipsSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'PJ Bolsas',
      };
      const collaboratorBonusesSheet: ReportSheetInfo = {
        columns: [],
        data: [],
        sheetName: 'PJ Bônus',
      };

      // Person Scholarhsips
      if (persons.scholarships?.length) {
        for (const {
          personSubscription,
          benefit,
          category,
          service,
          teachForm,
          partner,
          vouchers,
          ...scholarship
        } of persons.scholarships) {
          if (!personScholarshipsSheet.columns.length) {
            const columns = Object.keys(scholarship);
            columns.splice(2, 0, 'subscriber_name');
            columns.splice(3, 0, 'subscriber_document');
            columns.splice(9, 0, 'benefit_name');
            columns.splice(11, 0, 'category_name');
            columns.splice(13, 0, 'partner_name');
            columns.splice(15, 0, 'service_name');
            columns.splice(17, 0, 'teach_form_name');
            vouchers?.forEach((voucher, vIndex) => {
              const keys = Object.keys(voucher);
              keys.forEach((key, index, arr) => {
                arr[index] = `voucher_${vIndex + 1}_${key}`;
              });

              columns.push(...keys);
            });
            personScholarshipsSheet.columns = columns;
          }
          const data = Object.values(scholarship);
          data.splice(2, 0, personSubscription?.person?.name);
          data.splice(3, 0, personSubscription?.person?.document);
          data.splice(9, 0, benefit?.name);
          data.splice(11, 0, category?.name);
          data.splice(13, 0, partner?.display_name);
          data.splice(15, 0, service?.name);
          data.splice(17, 0, teachForm?.name);
          vouchers?.forEach((voucher) => {
            const values = Object.values(voucher);
            data.push(...values);
          });
          personScholarshipsSheet.data.push(data);
        }
      }

      // Person Bonuses
      if (persons.bonuses?.length) {
        for (const {
          personSubscription,
          benefit,
          category,
          service,
          partner,
          ...bonus
        } of persons.bonuses) {
          if (!personBonusesSheet.columns.length) {
            const columns = Object.keys(bonus);
            columns.splice(2, 0, 'subscriber_name');
            columns.splice(3, 0, 'subscriber_document');
            columns.splice(9, 0, 'benefit_name');
            columns.splice(11, 0, 'category_name');
            columns.splice(13, 0, 'partner_name');
            columns.splice(15, 0, 'service_name');
            personBonusesSheet.columns = columns;
          }
          const data = Object.values(bonus);
          data.splice(2, 0, personSubscription?.person?.name);
          data.splice(3, 0, personSubscription?.person?.document);
          data.splice(9, 0, benefit?.name);
          data.splice(11, 0, category?.name);
          data.splice(13, 0, partner?.display_name);
          data.splice(15, 0, service?.name);
          personBonusesSheet.data.push(data);
        }
      }

      // Collaborator Scholarships
      if (collaborators.scholarships.length) {
        for (const {
          beneficiary,
          beneficiary_unity_id,
          person,
          unity,
          benefit,
          category,
          service,
          partner,
          teachForm,
          ...scholarship
        } of collaborators.scholarships) {
          if (!collaboratorScholarshipsSheet.columns.length) {
            const columns = Object.keys(scholarship);
            columns.splice(2, 0, 'person_name');
            columns.splice(3, 0, 'person_document');
            columns.splice(5, 0, 'beneficiary_name');
            columns.splice(6, 0, 'beneficiary_unity_id');
            columns.splice(7, 0, 'beneficiary_unity_name');
            columns.splice(9, 0, 'partner_name');
            columns.splice(11, 0, 'benefit_name');
            columns.splice(13, 0, 'category_name');
            columns.splice(15, 0, 'service_name');
            columns.splice(17, 0, 'teach_form_name');
            collaboratorScholarshipsSheet.columns = columns;
          }
          const data = Object.values(scholarship);
          data.splice(2, 0, person?.name);
          data.splice(3, 0, person?.document);
          data.splice(5, 0, beneficiary?.display_name);
          data.splice(6, 0, beneficiary_unity_id);
          data.splice(7, 0, unity?.display_name);
          data.splice(9, 0, partner?.display_name);
          data.splice(11, 0, benefit?.name);
          data.splice(13, 0, category?.name);
          data.splice(15, 0, service?.name);
          data.splice(17, 0, teachForm?.name);
          collaboratorScholarshipsSheet.data.push(data);
        }
      }

      // Collaborator Bonuses
      if (collaborators.bonuses.length) {
        for (const {
          beneficiary,
          beneficiary_unity_id,
          person,
          unity,
          benefit,
          category,
          service,
          partner,
          ...scholarship
        } of collaborators.bonuses) {
          if (!collaboratorBonusesSheet.columns.length) {
            const columns = Object.keys(scholarship);
            columns.splice(2, 0, 'person_name');
            columns.splice(3, 0, 'person_document');
            columns.splice(5, 0, 'beneficiary_name');
            columns.splice(6, 0, 'beneficiary_unity_id');
            columns.splice(7, 0, 'beneficiary_unity_name');
            columns.splice(9, 0, 'partner_name');
            columns.splice(11, 0, 'benefit_name');
            columns.splice(13, 0, 'category_name');
            columns.splice(15, 0, 'service_name');
            collaboratorBonusesSheet.columns = columns;
          }
          const data = Object.values(scholarship);
          data.splice(2, 0, person?.name);
          data.splice(3, 0, person?.document);
          data.splice(5, 0, beneficiary?.display_name);
          data.splice(6, 0, beneficiary_unity_id);
          data.splice(7, 0, unity?.display_name);
          data.splice(9, 0, partner?.display_name);
          data.splice(11, 0, benefit?.name);
          data.splice(13, 0, category?.name);
          data.splice(15, 0, service?.name);
          collaboratorBonusesSheet.data.push(data);
        }
      }

      gerenateReport({
        sheets: [
          personScholarshipsSheet,
          personBonusesSheet,
          collaboratorScholarshipsSheet,
          collaboratorBonusesSheet,
        ],
        fileName: 'relatorio-bolsas-bonus.xlsx',
      });
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao gerar relatório.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Relatório de Bolsas & Bônus</Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Form ref={formRef} onSubmit={handleSubmit} noValidate>
              <CardHeader subheader="Filtros" />

              <CardContent>
                <DatePickerOperator name="created_at" label="Data de Emissão" />
              </CardContent>

              <CardActions style={{ justifyContent: 'flex-end' }}>
                <Button
                  startIcon={<CheckIcon />}
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                >
                  Gerar Relatório
                </Button>
              </CardActions>
            </Form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ScholarshipsAndBunuses;
