import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RestoreIcon from '@material-ui/icons/Restore';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import TextField from 'src/components/Form/TextField';
import { ISaveUser } from 'src/interfaces/forms/ISaveUser';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IBeneficiary, IBeneficiaryUser } from 'src/interfaces/models';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  BeneficiaryUserStoreSchema,
  BeneficiaryUserUpdateSchema,
} from 'src/validators/BeneficiaryUser/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [userStatus, setUserStatus] = useState<string>('');
  const [beneficiaries, setBeneficiaries] = useState<IBeneficiary[]>([]);
  const history = useHistory();
  const params = useParams<IEditParams>();

  const userSchema = params.id
    ? BeneficiaryUserUpdateSchema
    : BeneficiaryUserStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const { data } = await api.get<IBeneficiary[]>(`admin/beneficiaries`);
        setBeneficiaries(data);

        if (params.id) {
          const userResponse = await api.get(
            `admin/beneficiary-users/${params.id}`,
          );
          const user = userResponse.data as IBeneficiaryUser;

          setUserStatus(user.status);

          formRef.current?.setData(user);
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler<ISaveUser> = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(userSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.id) {
        await api.put(`/admin/beneficiary-users/${params.id}`, data);
      } else {
        await api.post('/admin/beneficiary-users', data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', userSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (userStatus === 'active') {
        await api.delete(`/admin/beneficiary-users/${params.id}`);
        toast.success('Usuário desativado!');
      } else {
        await api.post(`/admin/beneficiary-users/${params.id}/restore`);
        toast.success('Usuário restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar usuário.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Novo'} Usuário da Beneficiária
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField name="name" label="Nome Completo" required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="email"
                      label="E-mail"
                      type="email"
                      required
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      name="password"
                      label="Senha"
                      required
                      variant="outlined"
                      fullWidth
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      name="password_confirmation"
                      label="Confirmar Senha"
                      required
                      variant="outlined"
                      fullWidth
                      type="password"
                    />
                  </Grid>

                  <Grid item xs={3} sm={6}>
                    <Autocomplete
                      name="beneficiary_id"
                      label="Beneficiária"
                      options={beneficiaries.map((beneficiary) => ({
                        key: beneficiary.id,
                        label: `${beneficiary.display_name}`,
                        value: beneficiary.id,
                      }))}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              {userStatus === 'active' ? (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="secondary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${userStatus === 'active' ? 'Desativar' : 'Restaurar'} usuário`}
        description="Confirma esta ação?"
        confirmColor={userStatus === 'active' ? 'secondary' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
