import styled from 'styled-components';

import MuiCardActions from '@material-ui/core/CardActions';
import MuiContainer from '@material-ui/core/Container';

export const Container = styled(MuiContainer)`
  padding: ${({ theme }) => theme.spacing(3)}px;
`;

export const Actions = styled(MuiCardActions)`
  justify-content: flex-end;
`;
