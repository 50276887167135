import { FC, useState } from 'react';

import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Scope } from '@unform/core';
import CheckBox from 'src/components/Form/Checkbox';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import { operators } from 'src/utils/helpers';

const statusItems: Item[] = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const Filters: FC = () => {
  const [priceBetween, setPriceBetween] = useState(false);
  const [cashPriceBetween, setCashPriceBetween] = useState(false);
  const [qtyScholarshipsBetween, setQtyScholarshipsBetween] = useState(false);
  const [qtyMonthsBetween, setQtyMonthsBetween] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="vindi_product_id"
          label="ID do Produto Vindi"
          type="number"
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField name="name" label="Nome" size="small" />
      </Grid>

      <Grid item xs={12}>
        <TextField name="description" label="Descrição" size="small" />
      </Grid>

      <Grid item xs={12}>
        <TextField name="slug" label="URL" size="small" />
      </Grid>

      <Scope path="price">
        <Grid item xs={12}>
          <Typography variant="body1">Preço</Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            name="operator"
            label="Operador"
            items={operators}
            onChange={(value: any) => setPriceBetween(value === 'between')}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={priceBetween ? 4 : 8}>
          <TextField
            label={priceBetween ? 'De' : 'Valor'}
            mask="currency"
            returnUnmasked
            name="value"
            size="small"
          />
        </Grid>

        {priceBetween && (
          <Grid item xs={4}>
            <TextField
              label="Até"
              name="value_end"
              mask="currency"
              returnUnmasked
              size="small"
            />
          </Grid>
        )}
      </Scope>

      <Scope path="cash_price">
        <Grid item xs={12}>
          <Typography variant="body1">Preço à Vista</Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            name="operator"
            label="Operador"
            items={operators}
            onChange={(value: any) => setCashPriceBetween(value === 'between')}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={cashPriceBetween ? 4 : 8}>
          <TextField
            label={cashPriceBetween ? 'De' : 'Valor'}
            mask="currency"
            returnUnmasked
            name="value"
            size="small"
          />
        </Grid>

        {cashPriceBetween && (
          <Grid item xs={4}>
            <TextField
              label="Até"
              name="value_end"
              mask="currency"
              returnUnmasked
              size="small"
            />
          </Grid>
        )}
      </Scope>

      <Scope path="qty_scholarships">
        <Grid item xs={12}>
          <Typography variant="body1">Qtde. de Bolsas</Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            name="operator"
            label="Operador"
            items={operators}
            onChange={(value: any) =>
              setQtyScholarshipsBetween(value === 'between')
            }
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={qtyScholarshipsBetween ? 4 : 8}>
          <TextField
            label={qtyScholarshipsBetween ? 'De' : 'Valor'}
            mask="currency"
            returnUnmasked
            name="value"
            size="small"
          />
        </Grid>

        {qtyScholarshipsBetween && (
          <Grid item xs={4}>
            <TextField
              label="Até"
              name="value_end"
              mask="currency"
              returnUnmasked
              size="small"
            />
          </Grid>
        )}
      </Scope>

      <Scope path="qty_months">
        <Grid item xs={12}>
          <Typography variant="body1">Qtde. de Meses</Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            name="operator"
            label="Operador"
            items={operators}
            onChange={(value: any) => setQtyMonthsBetween(value === 'between')}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={qtyMonthsBetween ? 4 : 8}>
          <TextField
            label={qtyMonthsBetween ? 'De' : 'Valor'}
            mask="currency"
            returnUnmasked
            name="value"
            size="small"
          />
        </Grid>

        {qtyMonthsBetween && (
          <Grid item xs={4}>
            <TextField
              label="Até"
              name="value_end"
              mask="currency"
              returnUnmasked
              size="small"
            />
          </Grid>
        )}
      </Scope>

      <Grid item xs={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
      <Grid item xs={6}>
        <CheckBox name="allow_bonus" label="Permite Bonus" size="small" />
      </Grid>
    </>
  );
};

export default Filters;
