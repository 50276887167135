import yup from 'src/libs/yup';

export const NoticeStoreSchema = yup.object().shape({
  image: yup.mixed().required().isFile().label('Imagem'),
  description: yup.string().max(255).required().label('Descrição'),
  link: yup.string().max(255).required().label('Link'),
  show_ifbolsas: yup.boolean().default(false).label('IFBOLSAS'),
  show_ifepaf: yup.boolean().default(false).label('IFEPAF'),
  subsites_ids: yup.array().of(yup.number()).label('Subsites'),
});

export const NoticeUpdateSchema = yup.object().shape({
  image: yup.mixed().isFile().label('Imagem'),
  description: yup.string().max(255).required().label('Descrição'),
  link: yup.string().max(255).required().label('Link'),
  show_ifbolsas: yup.boolean().default(false).label('IFBOLSAS'),
  show_ifepaf: yup.boolean().default(false).label('IFEPAF'),
  subsites_ids: yup.array().of(yup.number()).label('Subsites'),
});
