import yup from 'src/libs/yup';

const CollaboratorScholarshipFilterSchema = yup.object().shape({
  person_id: yup.string().nullable().label('Pessoa'),
  beneficiary_id: yup.string().nullable().label('Beneficiária'),
  partner_id: yup.string().nullable().label('Parceira'),
  service_id: yup.string().nullable().label('Serviço'),
  teach_form_id: yup.string().nullable().label('Forma de Ensino'),
  status: yup.string().nullable().label('Status'),
});

export default CollaboratorScholarshipFilterSchema;
