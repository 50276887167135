import { FC, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { GridColDef } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import CustomMenuItem from 'src/components/Table/CustomMenuItem';
import StatusIcon from 'src/components/Table/StatusIcon';
import { columnDateTime, columnPhone } from 'src/components/Table/types';
import usePagination from 'src/hooks/usePagination';
import { IPerson } from 'src/interfaces/models';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import PersonFilterSchema from 'src/validators/Person/filter.schema';

import Filters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const filterRef = useRef<FilterDrawerRef>(null);
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading: paginationLoading,
    info,
    loadData,
  } = usePagination<IPerson>('admin/persons');
  const approveDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<IPerson>();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nome', flex: 1 },
    { field: 'email', headerName: 'E-mail', flex: 1 },
    { field: 'phone', headerName: 'Telefone', width: 140, ...columnPhone },
    {
      field: 'origin',
      headerName: 'Origem',
      valueGetter({ value }) {
        return value?.toString().toUpperCase();
      },
    },
    {
      field: 'created_at',
      headerName: 'Criado Em',
      ...columnDateTime,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      renderCell({ id, row }) {
        return (
          <TableActions
            resource="Pessoa"
            resourceStatus={row.status}
            editLink={`/pessoas/${id}`}
            deleteApiUrl={`/admin/persons/${id}`}
            restoreApiUrl={`/admin/persons/${id}/restore`}
            restoreText="Restaurar (Pendente)"
            descriptionPrefix={`A conta do(a) ${row.name}`}
            onFinish={loadData}
            startItems={
              row.status === 'pending' && (
                <>
                  <CustomMenuItem
                    Icon={CheckIcon}
                    iconProps={{ color: 'primary' }}
                    text="Aprovar"
                    onClick={() => {
                      setSelectedPerson(row as IPerson);
                      approveDialogRef.current?.show();
                    }}
                  />
                </>
              )
            }
          />
        );
      },
    },
  ];

  const handleApproveConfirm = async () => {
    try {
      setLoading(true);

      await api.post(`/admin/persons/${selectedPerson?.id}/approve`);
      toast.success(`Pessoa aprovada!`);
      approveDialogRef.current?.hide();
      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao aprovar pessoa.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <FilterDrawer
        ref={filterRef}
        columns={columns.filter((column) => !column.hide && column.field)}
        yupSchema={PersonFilterSchema}
      >
        <Filters />
      </FilterDrawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">Pessoas</Typography>

            <LinkButton
              to="/pessoas/novo"
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Novo
            </LinkButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                page={info.current_page - 1}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={paginationLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onFilterClick={filterRef.current?.open}
                canSearch
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <ConfirmDialog
        ref={approveDialogRef}
        title="Aprovar pessoa?"
        description="O cadastrado da pessoa será marcado como ativo."
        confirmColor="primary"
        onConfirm={handleApproveConfirm}
        loading={loading}
      />
    </Container>
  );
};

export default List;
