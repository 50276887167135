import yup from 'src/libs/yup';

export default yup.object().shape({
  name: yup.string().required().label('Nome'),
  email: yup.string().email().label('E-mail'),
  password: yup.string().label('Senha'),
  password_confirmation: yup
    .string()
    .equalTo(yup.ref('password'), 'senha')
    .label('Confirmação'),
});
