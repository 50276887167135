import yup from 'src/libs/yup';

export const PersonStoreSchema = yup.object().shape({
  origin: yup.string().required().label('Origem'),
  name: yup.string().required().label('Nome'),
  document: yup.string().required().label('CPF'),
  birth_date: yup.string().dateFormat('dd/MM/yyyy').label('Data de Nascimento'),
  email: yup.string().required().label('E-mail'),
  phone: yup.string().required().label('Telefone'),
  vindi_customer_id: yup.string().label('ID Cliente Vindi'),
  beneficiary_id: yup.string().label('Beneficiária'),
  beneficiary_unity_id: yup.string().label('Unidade'),
});

export const PersonUpdateSchema = yup.object().shape({
  origin: yup.string().required().label('Origem'),
  name: yup.string().required().label('Nome'),
  document: yup.string().required().label('CPF'),
  birth_date: yup.string().dateFormat('dd/MM/yyyy').label('Data de Nascimento'),
  email: yup.string().required().label('E-mail'),
  phone: yup.string().required().label('Telefone'),
  vindi_customer_id: yup.string().label('ID Cliente Vindi'),
  beneficiary_id: yup.string().label('Beneficiária'),
  beneficiary_unity_id: yup.string().label('Unidade'),
});
