import { isValid, parseISO, format } from 'date-fns';
import columnsNames from 'src/utils/reports/columnsNames';
import { monetaryColumns, translateValues } from 'src/utils/reports/dataValues';
import * as XLSX from 'xlsx';

export type ReportSheetInfo = {
  columns: string[];
  data: any[][];
  sheetName: string;
};

export type GenerateReportData = {
  sheets: ReportSheetInfo[];
  fileName: string;
};

export function gerenateReport({ sheets, fileName }: GenerateReportData): void {
  const workbook = XLSX.utils.book_new();

  for (const sheet of sheets) {
    formatData(sheet);
    const { columns, data, sheetName } = sheet;
    const tColumns = translateColumns(columns);
    const worksheet = XLSX.utils.aoa_to_sheet([tColumns, ...data]);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  }

  XLSX.writeFile(workbook, fileName);
}

function translateColumns(columns: ReportSheetInfo['columns']) {
  const translatedColumns: ReportSheetInfo['columns'] = [];

  for (const column of columns) {
    try {
      const name = columnsNames[column];
      if (!name) {
        throw new Error('Coluna não traduzida');
      }
      translatedColumns.push(name);
    } catch (error) {
      translatedColumns.push(column);
    }
  }

  return translatedColumns;
}

function formatData({ columns, data, sheetName }: ReportSheetInfo) {
  for (const row of data) {
    for (let i = 0; i < row.length; i++) {
      try {
        const column = columns[i];
        const value = row[i];

        if (column.endsWith('_at')) {
          const asDate = parseISO(value);
          row[i] = isValid(asDate)
            ? format(asDate, 'dd/MM/yyyy HH:mm:ss')
            : value;
        } else if (monetaryColumns[sheetName]?.includes(column)) {
          row[i] = row[i] / 100;
        } else if (translateValues[column]?.[value]) {
          row[i] = translateValues[column][value];
        }
      } catch (error) {} // ignora erros para não bloquear o relatório
    }
  }
}
