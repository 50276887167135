import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { AppBar, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import TabPanel from 'src/components/TabPanel';
import { ISettingsIfbolsasParams } from 'src/interfaces/forms/ISettingsIfbolsas';

import CommonQuestionsTab from './CommonQuestions';
import GeneralTab from './General';
import { Container } from './styles';

const tabs = [
  { name: 'Geral', slug: '', component: <GeneralTab /> },
  {
    name: 'Perguntas Frequentes',
    slug: 'perguntas',
    component: <CommonQuestionsTab />,
  },
];

const SettingsIfbolsas: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const params = useParams<ISettingsIfbolsasParams>();
  const history = useHistory();

  const handleTabChange = (e: ChangeEvent<any>, newValue: number) => {
    let goToUrl = '/configuracoes/ifbolsas';
    if (newValue > 0) {
      goToUrl = goToUrl.concat(`/${tabs[newValue].slug}`);
    }

    history.replace(goToUrl);
  };

  useEffect(() => {
    if (params.selectedTab) {
      setCurrentTab(tabs.findIndex((tab) => tab.slug === params.selectedTab));
    } else {
      setCurrentTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.selectedTab]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Typography variant="h5">Configurações IFbolsas</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <AppBar color="transparent" position="relative">
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map(({ name }) => (
                <Tab key={name} label={name} />
              ))}
            </Tabs>
          </AppBar>
        </Grid>

        <Grid item xs={12}>
          {tabs.map(({ name, component }, index) => (
            <TabPanel key={name} value={currentTab} index={index}>
              {component}
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SettingsIfbolsas;
