import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RestoreIcon from '@material-ui/icons/Restore';
import { SubmitHandler, FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
// import DatePicker from 'src/components/Form/DatePicker';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IBenefit, ICategory, IPartner, IPerson } from 'src/interfaces/models';
import { IPartnerService } from 'src/interfaces/models/IPartner';
import {
  IPersonScholarship,
  IPersonSubscription,
} from 'src/interfaces/models/IPerson';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import masks from 'src/utils/masks';
import yupValidate from 'src/utils/yupValidate';
import {
  PersonScholarshipAdminStoreSchema,
  PersonScholarshipAdminUpdateSchema,
} from 'src/validators/PersonScholarship/save.schema';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [
    activeScholarship,
    setActiveScholarship,
  ] = useState<IPersonScholarship | null>(null);
  const [persons, setPersons] = useState<IPerson[]>([]);
  const [personSubscriptions, setPersonSubscriptions] = useState<
    IPersonSubscription[]
  >([]);
  const [benefits, setBenefits] = useState<IBenefit[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [partnerServices, setPartnerServices] = useState<IPartnerService[]>([]);
  const [loading, setLoading] = useState(false);
  const [disableBenefit, setDisableBenefit] = useState(true);
  const [status, setStatus] = useState('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id
    ? PersonScholarshipAdminUpdateSchema
    : PersonScholarshipAdminStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const getPersons = api.get<IPerson[]>('/admin/persons');
        const getBenefits = api.get<IBenefit[]>('/admin/benefits', {
          params: {
            type: 'scholarship',
          },
        });

        const [personsResp, benefitsResp] = await Promise.all([
          getPersons,
          getBenefits,
        ]);
        setPersons(personsResp.data);
        setBenefits(benefitsResp.data);

        if (params.id) {
          const response = await api.get<IPersonScholarship>(
            `admin/persons-scholarships/${params.id}`,
          );
          const scholarship = response.data;
          setActiveScholarship(scholarship);

          setStatus(scholarship.status);
          formRef.current?.setData({
            ...scholarship,
            person_id: scholarship.personSubscription?.person_id,
          });
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleChangePerson = useCallback(async () => {
    try {
      setLoading(true);

      const personId = formRef.current?.getFieldValue('person_id');

      if (personId) {
        const personSubsResp = await api.get<IPersonSubscription[]>(
          '/admin/persons-subscriptions',
          {
            params: {
              person_id: personId,
              expired: false,
              statuses: ['pending', 'active'],
            },
          },
        );
        setPersonSubscriptions(personSubsResp.data);

        if (personSubsResp.data.length === 0) {
          formRef.current?.setFieldValue('person_subscription_id', null);
        } else if (activeScholarship?.person_subscription_id) {
          setTimeout(() =>
            formRef.current?.setFieldValue(
              'person_subscription_id',
              activeScholarship?.person_subscription_id,
            ),
          );
        }

        // if (!activeScholarship) {
        //   const selectedPerson = persons.find(
        //     (person) => person.id == personId,
        //   );
        //   formRef.current?.setFieldValue('name', selectedPerson?.name);
        //   formRef.current?.setFieldValue('document', selectedPerson?.document);
        //   formRef.current?.setFieldValue(
        //     'birth_date',
        //     selectedPerson?.birth_date,
        //   );
        // }
      } else {
        throw new Error('Erro ao buscar assinaturas .');
      }
    } catch (error) {
      setPersonSubscriptions([]);
      formRef.current?.setFieldValue('person_subscription_id', null);
      formRef.current?.setFieldValue('name', '');
      formRef.current?.setFieldValue('document', '');
      formRef.current?.setFieldValue('birth_date', '');
    } finally {
      setLoading(false);
    }
  }, [activeScholarship]);

  const handleChangePersonSubscription = useCallback(async () => {
    const personSubId = formRef.current?.getFieldValue(
      'person_subscription_id',
    );

    if (personSubId) {
      setDisableBenefit(false);
    } else {
      setDisableBenefit(true);
      formRef.current?.setFieldValue('benefit_id', null);
    }
  }, []);

  const handleBenefitChange = useCallback(async () => {
    try {
      setLoading(true);

      const benefitId = formRef.current?.getFieldValue('benefit_id');

      if (!benefitId) {
        setCategories([]);
        formRef.current?.setFieldValue('category_id', null);
        return;
      }

      const response = await api.get<ICategory[]>(`/admin/categories`, {
        params: { benefit_id: benefitId },
      });
      setCategories(response.data);

      if (activeScholarship?.category_id) {
        setTimeout(() =>
          formRef.current?.setFieldValue(
            'category_id',
            activeScholarship?.category_id,
          ),
        );
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar categorias.');
    } finally {
      setLoading(false);
    }
  }, [activeScholarship]);

  const handleCategoryChange = useCallback(async () => {
    try {
      setLoading(true);

      const categoryId = formRef.current?.getFieldValue('category_id');

      if (!categoryId) {
        setPartners([]);
        formRef.current?.setFieldValue('partner_id', null);
        return;
      }

      const response = await api.get<IPartner[]>(`/admin/partners`, {
        params: { category_id: categoryId },
      });
      setPartners(response.data);

      if (activeScholarship?.partner_id) {
        setTimeout(() =>
          formRef.current?.setFieldValue(
            'partner_id',
            activeScholarship?.partner_id,
          ),
        );
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar parceiras.');
    } finally {
      setLoading(false);
    }
  }, [activeScholarship]);

  const handlePartnerChange = useCallback(async () => {
    try {
      setLoading(true);

      const personSubId = formRef.current?.getFieldValue(
        'person_subscription_id',
      );
      const categoryId = formRef.current?.getFieldValue('category_id');
      const partnerId = formRef.current?.getFieldValue('partner_id');

      if (!personSubId || !partnerId || !categoryId) {
        setPartnerServices([]);
        formRef.current?.setFieldValue('partner_service_id', null);
        return;
      }

      // get teachForms that personSub can consume
      const teachFormIds = personSubscriptions
        .find((personSub) => personSub.id == personSubId)
        ?.teach_forms?.map((teachForm) => teachForm.id);

      const response = await api.get<IPartnerService[]>(
        `/admin/partners/${partnerId}/services`,
        {
          params: { category_id: categoryId, teach_forms: teachFormIds },
        },
      );
      setPartnerServices(response.data);

      const selected = response.data.find(
        (partnerService) =>
          partnerService.service_id === activeScholarship?.service_id &&
          partnerService.teach_form_id === activeScholarship.teach_form_id,
      );

      if (selected?.id) {
        setTimeout(() =>
          formRef.current?.setFieldValue('partner_service_id', selected?.id),
        );
      }
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao buscar serviços da parceira.',
      );
    } finally {
      setLoading(false);
    }
  }, [personSubscriptions, activeScholarship]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.id) {
        await api.put(`/admin/persons-scholarships/${params.id}`, data);
      } else {
        const response = await api.post<IPersonScholarship>(
          '/admin/persons-scholarships',
          data,
        );
        history.replace(`/assinantes/bolsas/${response.data.id}`);
      }

      toast.success('Dados salvos com sucesso!');
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (status === 'active') {
        const isDelete = confirmDialogRef.current?.isDelete();
        let deleteConfig = {};
        if (isDelete) {
          deleteConfig = { data: { delete: true } };
        }

        await api.delete(
          `/admin/persons-scholarships/${params.id}`,
          deleteConfig,
        );

        toast.success(`Bolsa ${isDelete ? 'excluida' : 'desativada'}!`);
      } else {
        await api.post(`/admin/persons-scholarships/${params.id}/restore`);
        toast.success('Bolsa restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar bolsa.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="person_id"
                      label="Pessoa"
                      options={persons.map((person) => ({
                        key: person.id,
                        label: person.name,
                        value: person.id,
                      }))}
                      onChange={handleChangePerson}
                      loading={layoutLoading}
                      textFieldProps={{ required: true }}
                      disabled={!!params.id}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="person_subscription_id"
                      label="Assinatura (ano letivo)"
                      options={personSubscriptions.map((personSub) => ({
                        key: personSub.id,
                        label: `${personSub.id} - ${personSub.subscription?.name} (${personSub.school_year})`,
                        value: personSub.id,
                      }))}
                      onChange={handleChangePersonSubscription}
                      loading={loading}
                      textFieldProps={{ required: true }}
                      noOptionsText="Selecione uma Pessoa"
                      disabled={!!params.id}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="benefit_id"
                      label="Benefício"
                      options={benefits.map((benefit) => ({
                        key: benefit.id,
                        label: benefit.name,
                        value: benefit.id,
                      }))}
                      loading={layoutLoading}
                      onChange={handleBenefitChange}
                      textFieldProps={{ required: true }}
                      disabled={disableBenefit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="category_id"
                      label="Categoria"
                      noOptionsText="Selecione um Benefício"
                      options={categories.map((category) => ({
                        key: category.id,
                        label: category.name,
                        value: category.id,
                      }))}
                      loading={loading}
                      onChange={handleCategoryChange}
                      textFieldProps={{ required: true }}
                      disabled={disableBenefit}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      name="partner_id"
                      label="Parceira"
                      noOptionsText="Selecione uma Categoria"
                      options={partners.map((partner) => ({
                        key: partner.id,
                        label: partner.display_name,
                        value: partner.id,
                      }))}
                      loading={loading}
                      onChange={handlePartnerChange}
                      textFieldProps={{ required: true }}
                      disabled={disableBenefit}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      name="partner_service_id"
                      label="Serviço da Parceira"
                      noOptionsText="Selecione uma Parceira"
                      options={partnerServices.map((partnerService) => ({
                        key: partnerService.id,
                        label: `${partnerService.service?.name} (${
                          partnerService.teach_form?.name
                        }) - ${masks.percent(
                          partnerService.discount.toString(),
                        )} %`,
                        value: partnerService.id,
                      }))}
                      loading={loading}
                      textFieldProps={{ required: true }}
                      disabled={disableBenefit}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8}>
                    <TextField name="name" label="Nome do Aluno" required />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="document"
                      label="CPF do Aluno"
                      required
                      mask="cpf"
                      returnUnmasked
                    />
                  </Grid>
                  {/* <Grid item xs={6} sm={4}>
                    <DatePicker
                      name="birth_date"
                      label="Nascimento"
                      required
                      clearable={false}
                      disableToolbar={false}
                    />
                  </Grid> */}

                  {!params.id && (
                    <Scope path="voucher">
                      <Grid item xs={12} sm={6}>
                        <TextField name="course" label="Curso" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="campus" label="Polo/Campus" required />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="year"
                          label="Ano"
                          type="number"
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="semester"
                          label="Semestre"
                          type="number"
                        />
                      </Grid>
                    </Scope>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              {status === 'active' ? (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="secondary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${status === 'active' ? 'Desativar' : 'Restaurar'} bolsa`}
        description="Confirma esta ação?"
        confirmColor={status === 'active' ? 'secondary' : 'primary'}
        onConfirm={handleConfirm}
        canDelete={status === 'active'}
      />
    </>
  );
};

export default Save;
