import yup from 'src/libs/yup';

export const BenefitStoreSchema = yup.object().shape({
  image: yup.mixed().required().isFile().label('Imagem'),
  name: yup.string().required().label('Nome'),
  slug: yup.string().required().label('URL'),
  type: yup.string().required().label('Tipo'),
  description: yup.string().label('Descrição'),
});

export const BenefitUpdateSchema = yup.object().shape({
  image: yup.mixed().required().isFile().label('Imagem'),
  name: yup.string().required().label('Nome'),
  slug: yup.string().required().label('URL'),
  type: yup.string().required().label('Tipo'),
  description: yup.string().label('Descrição'),
});
