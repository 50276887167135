import { FC } from 'react';

import IconButton from '@material-ui/core/IconButton';

import { CustomIconProps } from './interfaces';

const CustomIconButton: FC<CustomIconProps> = ({ Icon, ...props }) => {
  return (
    <IconButton size="small" color="primary" {...props}>
      <Icon fontSize="inherit" />
    </IconButton>
  );
};

export default CustomIconButton;
