import yup from 'src/libs/yup';
import { operators } from 'src/utils/helpers';

const oneOfOpearators = operators
  .filter((op) => !!op.value)
  .map((op) => op.value);

const SubscriptionFilterSchema = yup.object().shape({
  vindi_product_id: yup.string().nullable().label('ID do Produto Vindi'),
  name: yup.string().label('Nome'),
  description: yup.string().label('Descrição'),
  slug: yup.string().label('URL'),
  price: yup
    .object()
    .optional()
    .shape({
      operator: yup
        .string()
        .nullable()
        .oneOf(['', ...oneOfOpearators])
        .requiredWhen(yup.ref('value'), 'Valor')
        .label('Operador'),
      value: yup
        .string()
        .requiredWhen(yup.ref('operator'), 'Operador')
        .when('operator', {
          is: 'between',
          then: yup.string().label('De'),
          otherwise: yup.string().label('Valor'),
        }),
      value_end: yup
        .string()
        .when('operator', {
          is: 'between',
          then: yup.string().required(),
          otherwise: yup.string().optional(),
        })
        .label('Até'),
    })
    .label('Preço'),
  cash_price: yup
    .object()
    .optional()
    .shape({
      operator: yup
        .string()
        .nullable()
        .oneOf(['', ...oneOfOpearators])
        .requiredWhen(yup.ref('value'), 'Valor')
        .label('Operador'),
      value: yup
        .string()
        .requiredWhen(yup.ref('operator'), 'Operador')
        .when('operator', {
          is: 'between',
          then: yup.string().label('De'),
          otherwise: yup.string().label('Valor'),
        }),
      value_end: yup
        .string()
        .when('operator', {
          is: 'between',
          then: yup.string().required(),
          otherwise: yup.string().optional(),
        })
        .label('Até'),
    })
    .label('Preço à Vista'),
  qty_scholarships: yup
    .object()
    .optional()
    .shape({
      operator: yup
        .string()
        .nullable()
        .oneOf(['', ...oneOfOpearators])
        .requiredWhen(yup.ref('value'), 'Valor')
        .label('Operador'),
      value: yup
        .string()
        .requiredWhen(yup.ref('operator'), 'Operador')
        .when('operator', {
          is: 'between',
          then: yup.string().label('De'),
          otherwise: yup.string().label('Valor'),
        }),
      value_end: yup
        .string()
        .when('operator', {
          is: 'between',
          then: yup.string().required(),
          otherwise: yup.string().optional(),
        })
        .label('Até'),
    })
    .label('Qtde. de Bolsas'),
  qty_months: yup
    .object()
    .optional()
    .shape({
      operator: yup
        .string()
        .nullable()
        .oneOf(['', ...oneOfOpearators])
        .requiredWhen(yup.ref('value'), 'Valor')
        .label('Operador'),
      value: yup
        .string()
        .requiredWhen(yup.ref('operator'), 'Operador')
        .when('operator', {
          is: 'between',
          then: yup.string().label('De'),
          otherwise: yup.string().label('Valor'),
        }),
      value_end: yup
        .string()
        .when('operator', {
          is: 'between',
          then: yup.string().required(),
          otherwise: yup.string().optional(),
        })
        .label('Até'),
    })
    .label('Qtde. de Meses'),
  status: yup.string().nullable().label('Status'),
});

export default SubscriptionFilterSchema;
