import yup from 'src/libs/yup';

const ServiceFilterSchema = yup.object().shape({
  category_id: yup.string().nullable().label('Categoria'),
  name: yup.string().label('Nome'),
  description: yup.string().label('Descrição'),
  slug: yup.string().label('URL'),
  status: yup.string().nullable().label('Status'),
});

export default ServiceFilterSchema;
