import yup from 'src/libs/yup';

export const PersonScholarshipAdminStoreSchema = yup.object().shape({
  person_subscription_id: yup.number().required().label('Assinatura da Pessoa'),
  category_id: yup.number().required().label('Categoria'),
  partner_service_id: yup.number().required().label('Serviço da Parceira'),
  name: yup.string().required().label('Nome'),
  document: yup.string().cpf().required().label('CPF'),
  // birth_date: yup
  //   .string()
  //   .required()
  //   .dateFormat('dd/MM/yyyy')
  //   .label('Nascimento'),
  voucher: yup.object().shape({
    course: yup.string().required().label('Curso'),
    campus: yup.string().required().label('Campus'),
    year: yup.string().required().label('Ano'),
    semester: yup.string().label('Semestre'),
  }),
});

export const PersonScholarshipAdminUpdateSchema = yup.object().shape({
  partner_service_id: yup.number().required().label('Serviço da Parceira'),
  name: yup.string().required().label('Nome'),
  document: yup.string().cpf().required().label('CPF'),
  // birth_date: yup
  //   .string()
  //   .required()
  //   .dateFormat('dd/MM/yyyy')
  //   .label('Nascimento'),
});
